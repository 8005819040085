/*-----------------------------------------
 [MASTER STYLE SHEET]
 * THEME NAME - Wedding Matrimony HTML5 Template
 * Author: RN53 Themes
 * Descriptios: Wedding Matrimony HTML5 Template. Can be Used For Various Perposes.
 * Version: v1
 -----------------------------------------------*/
/*-------------------------------------------------
 =  TABLE OF CSS
1. GOOGLE FONT IMPORT
2. COMMON CSS 
3. PRELOAD
4. HOME BANNER SLIDER
5. HOME BANNER
6. INNER PAGE BANNER
7. MENU
8. MENU POPUP
9. MENU EXPERT 
10. HOME BANNER TITLE
11. BANNER SEARCH
12. POPUP SEARCH
13. ABOUT HOME
14. HOME QUICK ACCESS
15. LOGIN, REGISTER PAGE
16. COMMON FORM
17. FOOTER
18. ALL PROFILE
19. MOBILE MENU
20. CHOOSEN SELECT
21. ABOUT TESTIMONIALS
22. CHECK BOX & RADIO BUTTON
23. USER PROFILE
24. PRICING PLAN
25. ABOUT
26. TEAM PROFILES
27. FAQ
28. CHAT BOX
29. COMMON TITLE
30. CUSTOMER REVIEWS
31. CTA & BUTTONS
32. ABOUT
33. WEDDING VIDEO
34. WEDDING GALLERY
35. FOOTER ADDRESS
36. WEDDING TIMELINE
37. BLOG BOX
38. BLOG SHARE
39. BLOG
40. PROFILE - RELATED PROFILE
41. CONTACT US
42. COMMING SOON
43. SERVICES PAGE
44. IMAGE GALLERY
45. 404 PAGE

/*-------------------------------------------------------*/
/* GOOGLE FONT IMPORT
/*-------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap');

/*-------------------------------------------------------*/
/* COMMON CSS 
/*-------------------------------------------------------*/
html {
    overflow-x: hidden;
}

body {
    background: rgb(251, 248, 237);
    overflow-x: hidden !important;
    background-image: linear-gradient(135deg, #fdfcfb 0%, rgb(255 251 238) 100%);
    background-attachment: fixed;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    border: 0px;
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    font-size: inherit;
    line-height: inherit;
    vertical-align: baseline;
    font-family: Poppins, sans-serif;
    font-weight: 400;
}

:root {
    --mtop: 75px;
    --cta-dark: #000000;
    --cta-pink1: #607d8b;
    --cta-pink1h: #a16304;
    --tit-font:
        "Playfair Display", serif;
}

@media screen and (max-width: 1100px) {
    :root {
        --mtop: 70px;
    }
}

@media screen and (max-width: 992px) {
    :root {
        --mtop: 61px;
    }
}

@media screen and (max-width: 769px) {
    :root {
        --mtop: 50px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0px;
    color: #66451c;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
li,
label,
tr,
td,
a,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
summary,
input,
textarea,
select {
    font-family: Poppins, sans-serif;
    color: #66451c;
}

ul {
    padding: 0px;
    margin: 0px;
}

li {
    list-style-type: none;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0px;
}

h1,
h1 b,
h2,
h2 span {
    font-family: var(--tit-font);
}

a {
    transition: all 0.5s ease 0s;
    text-decoration: none;
}

a:hover {
    transition: all 0.5s ease 0s;
    text-decoration: none;
}
img{
    max-width: 100%;
    object-fit: cover;
}
.form-group {
    margin-bottom: 1rem;
}
/*-------------------------------------------------------*/
/* PRELOAD
/*-------------------------------------------------------*/
#preloader {
    position: fixed;
    inset: 0px;
    background-color: rgb(255, 255, 255);
    z-index: 99999;
}

.plod {
    width: 137px;
    height: 122px;
    padding: 0px;
    margin: 8% auto 0px;
    position: relative;
}

.plod span {
    position: absolute;
    transition: all 0.4s ease-in-out 0s;
}

.plod span img {}

.plod .lod1 img {
    width: 80px;
}

.plod .lod2 img {
    width: 40px;
}

.plod .lod3 img {
    width: 24px;
}

.plod .lod1 {
    left: 0px;
    top: 26px;
    animation: 1s ease-in-out 0s 1 normal both running loadltor;
}

.plod .lod2 {
    right: 0px;
    animation: 1s ease-in-out 0s 1 normal both running loadtol;
    top: 0px;
}

.plod .lod3 {
    position: absolute;
    top: 0px;
    left: 75px;
    animation: 0.4s ease-in-out 0.5s 1 normal both running loadheart;
}

@keyframes loadltor {
    0% {
        left: -50px;
    }

    100% {
        left: 0px;
    }
}

@keyframes loadtol {
    0% {
        right: -50px;
    }

    100% {
        right: 0px;
    }
}

@keyframes loadheart {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

/*-------------------------------------------------------*/
/* HOME BANNER SLIDER
/*-------------------------------------------------------*/
.hom-head {}

.hom-ban-sli {
    float: left;
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: -1;
    /* height: 100vh; */
}

.hom-ban-sli:before,
.home-acces-main:after,
.home-acces-main:before {
    content: "";
    position: absolute;
}

.hom-ban-sli:before {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-image: linear-gradient(358deg, black, #0000005e);
}

.home-acces-main:after,
.home-acces-main:before {
    width: 20%;
    height: 100%;
    top: 0;
    bottom: 0;
    z-index: 1;
    max-width: 300px;
}

.home-acces-main:after {
    right: 0;
    background: #000;
    background: -webkit-linear-gradient(to left, #000000, #00000005);
    background: linear-gradient(to left, #000000, #00000005);
}

.home-acces-main:before {
    left: 0;
    background: #000;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #000000, #00000005);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #000000, #00000005);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    top: 0;
}

.ban-sli {
    float: left;
    width: 100%;
}

.ban-sli li {
    float: left;
    width: 100%;
}

.ban-sli li div {
    float: left;
    width: 100%;
}

.ban-sli li div img {
    width: 100%;
    height: calc(100vh + 100px);
    object-fit: cover;
    transition: all 0.5s ease;
}

.ban-sli li.slick-active div img {
    animation: slizoom 10s infinite linear both;
}

@keyframes slizoom {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.3);
    }
}

/*-------------------------------------------------------*/
/* HOME BANNER
/*-------------------------------------------------------*/
.hom-head {
    /* padding: 8% 0 50px 0; */
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    float: left;
    height:
        calc(100vh - 100px);
    margin-top: var(--mtop);z-index: 2;
    background-color: #F7E7CE;
}

@keyframes bannscrl {
    0% {
        background-position: 0px center;
    }

    100% {
        background-position: -5000px center;
    }
}

.hom-top .container {
    position: relative;
    background-color: #6D0B32;
}

.hom-head::after {
    content: "";
    position: absolute;
}

.head-top {
    background: linear-gradient(to right, rgb(118, 69, 8), rgb(64, 52, 6));
}

.head-top .rhs ul {
    float: right;
}

.head-top ul li {
    float: left;
}

.head-top ul li a {
    display: inline-block;
    color: rgb(234, 208, 176);
    font-size: 11px;
    font-weight: 500;
    border-right: 1px solid rgba(255, 255, 255, 0.19);
    padding: 10px 15px;
    text-transform: uppercase;
}

.head-top ul li a:hover {
    background: rgb(238, 231, 213);
    color: rgb(90, 60, 7);
}

.head-top ul li a i {
    font-size: 14px;
}

.head-top ul li a i:hover {
    background: none;
}

.head-top .lhs {
    float: left;
    width: 50%;
}
.head-top li:last-child a {
    border-right: 0px;
}

.head-top .rhs {
    float: left;
    width: 50%;
}

.hom-ban {
    float: left;
    width: 100%;
    position: relative;
    z-index: 1;
}

.hom-top {
    /* padding: 15px 0px; */
    width: 100%;
    background: #6D0B32;
    box-shadow: rgba(51, 51, 51, 0.14) 0px 11px 11px -11px;
    float: left;
    transition: all 0.4s ease;
    position: absolute;
    z-index: 3;
}

.hom-top.head-trans {
    background: no-repeat;
    box-shadow: none;
}

.hom-top.act {
    box-shadow: rgba(51, 51, 51, 0.12) 0px 11px 11px -11px;
    position: fixed;
    top: -150px;
    z-index: 3;
    animation: menuact 0.5s linear both;
}

@keyframes menuact {
    from {
        top: -150px;
    }

    to {
        top: 0px;
    }
}

.hom-nav {
    width: 100%;
}

.logo {
    float: left;
    display: inline-block;
    width: 300px;
}

.logo-brand {
    display: inline-block;
    margin-left: 8px;
}

/*-------------------------------------------------------*/
/* INNER PAGE BANNER
/*-------------------------------------------------------*/
.inn-ban {
    padding: 60px 30px 80px;
    background: url(../images/banner.jpg) 0px -105px / cover no-repeat;
    position: relative;
    width: 100%;
    float: left;
    text-align: center;
    margin-top: var(--mtop);
}

.inn-ban::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0px;
    background: #ffeebf;
    background: linear-gradient(90deg, rgb(255 154 154 / 89%) 5%, rgb(218 223 255) 80%);
}

.inn-ban::after {
    content: '';
    position: absolute;
    background: url(../images/login-bg.png);
    width: 100%;
    height: 53px;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: 260px;
    animation: 10000s linear 0s infinite normal both running movehor;
    opacity: 0.8;
}

.inn-ban h1 {
    font-size: 42px;
    font-weight: 600;
    line-height: 50px;
    display: block;
    width: 100%;
    color: #000;
    position: relative;
    padding: 0 12% 15px 12%;
}

.inn-ban p {
    position: relative;
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin: 0;
    font-weight: 400;
}

.inn-ban .breadcrumb {
    position: relative;
    margin: 0 auto;
    background: none;
    padding: 0;
}

.inn-ban .breadcrumb li {
    color: #db0000;
    font-weight: 500;
    font-family: var(--tit-font);
    font-size: 16px;
}

.inn-ban .breadcrumb li a {
    font-weight: 500;
    font-size: 16px;
    font-family: var(--tit-font);
}

.inn-ban .breadcrumb li a:hover {
    color: #db0000;
}

.inn-ban .breadcrumb li.active a {}

.breadcrumb-item+.breadcrumb-item::before {
    font-family: FontAwesome;
    content: "\f105";
    color: #66451c;
}

/*-------------------------------------------------------*/
/* MENU
/*-------------------------------------------------------*/
.menu {
    display: block;
    position: relative;
    font-size: 25px;
    text-align: center;
    border-radius: 50px;
    z-index: 2;
    font-weight: 300;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: rgb(246, 175, 4);
    padding: 5px;
    float: left;
    box-shadow: rgb(246, 175, 4) 0px 0px 0px;
    animation: 1.5s ease 0s infinite normal none running pulse;
}

.menu i {
    width: 20px;
    height: 2px;
    background: rgb(255, 255, 255);
    display: inline-block;
    float: left;
    top: 11px;
    left: 10px;
    transition: all 0.4s ease-in-out 0s;
    position: absolute;
    cursor: pointer;
}

.menu i:nth-child(2) {
    top: 18px;
    transform: scale(1);
}

.menu i:nth-child(3) {
    top: 25px;
}

.menu.act i {
    transform-origin: center center;
}

.menu.act i:nth-child(1) {
    transform: rotate(45deg);
    top: 10px;
}

.menu.act i:nth-child(2) {
    transform: scale(0);
}

.menu.act i:nth-child(3) {
    transform: rotate(-45deg);
    top: 10px;
}

.mob-sec {
    display: none;
}

.hom-nav .ic-logo {
    width: 230px;
    padding-top: 2px;
}

.hom-nav .bl {
    margin-top: 18px;
    width: calc(100% - 450px);
    float: left;
    /* padding: 0px 0px 40px; */
}

.hom-nav .bl ul {
float:right;
}
.custom-button {
    background-color: #F68C1E;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    width:100px;
    height:40px;
    cursor: pointer;
    margin-left: 30px;
    align-items: "center";
}

.hom-nav .bl li {
    float: left;
    margin-left: 25px;
    font-weight: 500;
    position: relative;
    /* display: block; */
    /* width: 100%; */
}

.hom-nav .bl li a,
.smenu-pare span {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
    padding: 15px 3px;
    text-transform: uppercase;
    color:#FFF;
    margin-top: 30px;
}

.smenu-open {
    position: absolute;
    left: -14px;
    top: 30px;
    background: #fff;
    padding: 10px 0;
    box-shadow: 0 14px 11px 1px #0000000f;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    transform: translateY(20px);
    animation: growOut 0.34s ease forwards;
    transform-origin: top center;
    display: none;
}

.smenu-pare:hover .smenu-open {
    transform: translateY(0px);
    display: block;
}

@keyframes growOut {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }

    80% {
        transform: scale(1.01);
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.smenu-single {
    width: 200px;
}

.smenu:after {
    content: "\f105";
    font: normal normal normal 14px/1 FontAwesome;
    transform: rotate(90deg);
    transition: all .5s ease;
    display: inline-block;
    font-size: 18px;
    position: relative;
    top: 2px;
    left: 8px;
}

.smenu-single ul {}

.smenu-single ul li {
    display: block;
    width: 100%;
    margin: 0 !IMPORTANT;
}

.hom-nav .smenu-single ul li a {
    display: block;
    padding: 2px 20px !important;
    border-bottom: 1px solid #f7f7f7;
    font-weight: 400;
}

.bl .smenu-single ul li a:hover {
    background: #f9f4eb;
    color: #66451c;
}

.smenu-box,
.smenu-multi {
    position: fixed;
    width: 100%;
    left: 0;
    top: 90px;
    padding: 30px;
}

.hom-top.act .smenu-box,
.hom-top.act .smenu-multi {
    top: 45px;
}

.smenu-box h4.tit {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 0 5px 10px;
}

.bl .smenu-box ul {
    float: left;
    width: 100%;
}

.bl .smenu-box ul li {
    width: 25%;
    margin: 0;
    padding: 10px;
}

.menu-box {
    position: relative;
    background-repeat: no-repeat;
    border-radius: 5px;
    padding: 30px;
    background-size: 125%;
    min-height: 200px;
    transition: all 0.5s ease;
    background-position: 0px 0px;
}

.menu-box:hover {
    background-size: 140%;
}

.menu-box h5 {
    font-size: 19px;
    position: relative;
    color: #fff;
    margin: 60px 0 0 0;
}

.menu-box h5 span {
    display: block;
    text-transform: uppercase;
    font-size: 11px;
    padding: 0;
    color: #fff;
    line-height: 30px;
}

.menu-box:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
}

.menu-box .explor-cta, .explor-cta {
    background: #000;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    padding: 3px 12px;
    line-height: 25px;
    margin-top: 8px;
}
.cta-5{
    background: #fff;
    color: #66451c;
    font-size: 13px;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    padding: 2px 10px;
    line-height: 25px;
    margin-top: 8px;
    border: 1px solid #a59c91;
    font-weight: 500;
}
.cta-5:hover{background: #000;
    color: #fff;}
.menu-box-1 {
    background-image: url('../images/gallery/2.jpg');
}

.menu-box-2 {
    background-image: url('../images/profiles/2.jpg');
}

.menu-box-3 {
    background-image: url('../images/profiles/3.jpg');
}

.menu-box-4 {
    background-image: url('../images/profiles/4.jpg');
}

.menu-box-1:before {
    background: #742dfffa;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #742dfffa, #a171ffa6);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #742dfffa, #a171ffa6);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.menu-box-2:before {
    background: #742dfffa;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ff2deffa, #ff2def82);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ff2deffa, #ff2def82);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.menu-box-3:before {
    background: #742dfffa;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0dcd98fa, #17dba538);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0dcd98fa, #17dba538);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.menu-box-4:before {
    background: #742dfffa;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #d39b00fa, #f7b600a6);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #d39b00fa, #f7b600a6);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.smenu-multi {}

.smenu-multi .multi-col {
    float: left;
    width: 25%;
}
.smenu-multi .multi-col.full{width: 100%;border-top: 1px solid #d3d3d3;margin-top: 25px;padding-top: 15px;}
.smenu-multi .multi-col.full ul li{width: 25%;}
.smenu-multi .inn {}

.smenu-multi h4 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 0 5px 5px;
}

.smenu-multi ul {
    float: left;
    width: 100%;
}

.hom-nav .smenu-multi ul li {
    margin-left: 0;
    display: block;
    width: 100%;
}

.hom-nav .smenu-multi ul li a {
    font-weight: 400;
    position: relative;
}

.hom-nav .smenu-multi ul li a:hover {
    color: #15aec7;
}

.hom-nav .smenu-multi ul li a:before {
    content: '';
    position: absolute;
    width: 0%;
    height: 2px;
    left: 4px;
    bottom: 4px;
    background: #15aec7;
    transition: all 0.4s ease;
}

.hom-nav .smenu-multi ul li a:hover:before {
    width: 90%;
}

/*-------------------------------------------------------*/
/* MENU POPUP
/*-------------------------------------------------------*/
.pop-bg {
    position: fixed;
    background: rgb(4 6 10 / 60%);
    width: 100%;
    height: 100%;
    z-index: 4;
    transition: all 0.3s ease-in-out 0s;
    transform: scale(0);
    opacity: 0;
    right: -150%;
}

.pop-bg.act {
    transform: scale(1);
    opacity: 1;
    right: 0;
}

.menu-pop {
    position: fixed;
    top: 0px;
    bottom: 0px;
    max-width: 400px;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: rgb(255, 255, 255);
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.28) 6px 0px 26px -9px;
    overflow: hidden auto;
    transition: all 0.5s ease-in-out 0s;
}

.menu-pop1 {
    left: -500px;
}

.menu-pop1.act {
    left: 0px;
}

.menu-pop2 {
    right: -500px;
}

.menu-pop2.act {
    right: -18px;
}

.menu-pop-clo {
    right: 20px;
    top: 20px;
    position: absolute;
}

.menu-pop-clo i {
    font-size: 30px;
    font-weight: 300;
    cursor: pointer;
    color: rgb(203, 43, 94);
    transition: all 0.4s ease-in-out 0s;
}

.menu-pop-clo i:hover {
    transform: rotate(90deg);
}

.menu-pop .inn {
    float: left;
    width: 100%;
}

.logo-brand-only {
    width: 210px;
    margin-bottom: 25px;
}

.menu-pop p {
    font-size: 15px;
    padding-bottom: 15px;
}

.menu-pop-info li {
    margin-bottom: 23px;
    position: relative;
}

.menu-pop-info li a {
    font-size: 14px;
    color: rgb(32, 43, 58);
    padding: 4px 0px 0px 55px;
    display: inline-block;
    font-weight: 500;
}

.menu-pop-info li a i {
    width: 38px;
    height: 38px;
    background: rgb(75, 62, 126);
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding: 9px;
    margin-right: 10px;
    position: absolute;
    left: 0px;
    top: -4px;
}

.menu-pop-help {
    border-top: 1px solid rgb(231, 231, 231);
    margin-top: 15px;
    padding-top: 35px;
    float: left;
    width: 100%;
}

.menu-pop-help h4 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 15px;
}

.menu-pop-help .user-pro {
    float: left;
    width: 72px;
}

.menu-pop-help .user-pro img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
    float: left;
}

.menu-pop-help .user-bio {
    float: left;
    width: calc(100% - 72px);
    padding: 0px 0px 0px 20px;
}

.menu-pop-help .user-bio h5 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1px;
}

.menu-pop-help .user-bio span {
    font-size: 13px;
    padding-bottom: 10px;
    display: inline-block;
}

.menu-pop-help .user-bio a {
    font-size: 16px;
    color: rgb(255, 255, 255);
    background: var(--cta-pink1);
    border: 0px;
    font-family: 'Josefin Sans', sans-serif;
    /* display: table; */
    /* margin: 0 auto; */
    padding: 7px 12px 5px 12px;
}

.menu-pop2 .menu-pop-help {
    border: 0px;
    padding-top: 0px;
}

.menu-pop2 .menu-pop-help h4 {
    text-align: center;
}

.menu-pop2 .menu-pop-help .user-pro {
    width: 100%;
    position: relative;
}

.menu-pop2 .menu-pop-help .user-pro::before,
.menu-pop2 .menu-pop-help .user-pro::after {
    content: "";
    position: absolute;
    width: 70px;
    height: 70px;
    clip-path: polygon(78% 38%, 8% 100%, 100% 100%);
    z-index: -1;
    transform-origin: center center;
}

.menu-pop2 .menu-pop-help .user-pro::before {
    background: rgb(0, 255, 125);
    transform: rotate(38deg);
    left: 0px;
    top: -15px;
    animation: 20s ease 0s infinite normal both running rotat-light;
}

.menu-pop2 .menu-pop-help .user-pro::after {
    background: rgb(236, 255, 0);
    transform: rotate(353deg);
    right: 18px;
    bottom: -25px;
    animation: 20s ease 5s infinite normal both running rotat-light;
    clip-path: circle(50% at 50% 50%);
}

@keyframes rotat-light {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.menu-pop2 .menu-pop-help .user-pro img {
    width: 225px;
    border-radius: 5px;
    height: 225px;
    margin: 0px auto;
    display: table;
    float: initial;
    position: relative;
}

.menu-pop2 .menu-pop-help .user-bio {
    width: 100%;
    text-align: center;
    padding: 25px 25px 5px;
}

.menu-pop2 .menu-pop-help .user-bio h5 {
    font-size: 20px;
}

.menu-pop2 .menu-pop-soci ul {
    margin: 0px auto;
    display: table;
}

.menu-pop2 .menu-pop-soci ul li {
    margin: 0px 2px;
}

.menu-pop2 .menu-pop-soci {}

.menu-pop-soci {
    float: left;
    width: 100%;
    padding-top: 30px;
}

.menu-pop-soci ul {}

.menu-pop-soci ul li {
    display: inline-block;
}

.menu-pop-soci ul li a {
    display: inline-block;
    padding: 0 !important;
}

/*-------------------------------------------------------*/
/* MENU EXPERT 
/*-------------------------------------------------------*/
.menu-pop-soci ul li a i {
    width: 38px;
    height: 38px;
    background: rgb(247, 247, 247);
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    font-size: 18px;
    padding: 11px 8px;
    transition: all 0.5s ease;
}

.menu-pop-soci i:hover,
.menu-pop-soci i.fa:hover {
    color: #fff;
}

i.fa-facebook:hover {
    background: #005aff;
}

i.fa-twitter:hover {
    background: #00aced;
}

i.fa-linkedin:hover {
    background: #0e76a8;
}

i.fa-youtube-play:hover {
    background: #bb0000;
}

i.fa-whatsapp:hover {
    background: #34bf49;
}

i.fa-instagram:hover {
    background: #bc2a8d;
}
.late-news {
    float: left;
    width: 100%;
    border-top: 1px solid rgb(231, 231, 231);
    margin: 40px 0px;
    padding: 35px 0px;
    border-bottom: 1px solid rgb(231, 231, 231);
}

.late-news h4 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 15px;
}

.late-news ul li {
    float: left;
    width: 100%;
    padding-bottom: 15px;
    position: relative;
}

.late-news ul li:last-child {
    padding-bottom: 0px;
}

.late-news ul li .rel-pro-img {
    width: 80px;
}

.late-news ul li .rel-pro-img img {
    border-radius: 5px;
    width: 75px;
    height: 75px;
}

.late-news ul li .rel-pro-con {
    width: calc(100% - 80px);
    padding: 0px 0px 0px 10px;
}

.late-news ul li .rel-pro-con h5 {
    font-size: 15px;
    white-space: break-spaces;
    line-height: 22px;
    padding-bottom: 4px;
}

.late-news ul li .rel-pro-con span {
    position: relative;
    padding-left: 12px;
}

.late-news ul li .rel-pro-con span::before {
    content: "\f105";
    position: absolute;
    color: rgb(51, 51, 51);
    font: 200 16px / 1 FontAwesome;
    top: -1px;
    left: 0px;
}


.al {
    float: left;
    position: relative;
    width: 150px;
}

.al::after {
    font-size: 22px;
    transition: all 0.5s ease 0s;
    right: -12px;
    top: 5px;
    color: rgb(216, 200, 191);
}

.head-pro {
    padding: 6px 0px 0px;
    display: inline-block;
    width: 142px;
}

.head-pro img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
    float: left;
    margin-right: 10px;
    transition: all 0.5s ease 0s;
    animation: 5s ease 0s infinite normal both running roatepro;
}

@keyframes roatepro {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-20deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(20deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@-webkit-keyframes pulse {
    0% {
        box-shadow: rgb(178, 221, 255) 0px 0px 0px 0px;
    }

    70% {
        box-shadow: rgba(204, 169, 44, 0) 0px 0px 0px 15px;
    }

    100% {
        box-shadow: rgba(204, 169, 44, 0) 0px 0px 0px 0px;
    }
}

@keyframes pulse {
    0% {
        box-shadow: rgb(178, 221, 255) 0px 0px 0px 0px;
    }

    70% {
        box-shadow: rgba(204, 169, 44, 0) 0px 0px 0px 15px;
    }

    100% {
        box-shadow: rgba(204, 169, 44, 0) 0px 0px 0px 0px;
    }
}

/*-------------------------------------------------------*/
/* HOME BANNER TITLE
/*-------------------------------------------------------*/
.head-pro b {
    padding: 0px;
    font-size: 10px;
    font-weight: 500;
    color: #746a63;
    line-height: 15px;
    float: left;
    text-transform: uppercase;
}

.head-pro h4 {
    margin: -7px 0px 0px;
    font-size: 14px;
    line-height: 15px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}

.ban-tit {
    padding-top: 130px;
    text-align: center;
    width: 100%;
    position: relative;
}
.form-container {
    align-items: center;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    background-color: white;
    padding: 5%;
    padding-top: 18%;
    margin-bottom: 0%;
    /* margin-top: 3%; */
    border-radius: 10px;
    height: 580px; /* Adjust height to auto for responsiveness */
    max-width: 800px; /* Set maximum width for larger screens */
  }
  

  @media (max-width: 1795px) {
    /* Adjust styles for smaller screens */
    .form-container {
        margin-top: 1%;
    }
  }
  @media (max-width: 480px) {
    /* Further adjust styles for even smaller screens */
    .form-container {
      padding: 5px;
      /* margin-top: 8%; */

    }
  }
  
  
  .form {
    display: inline-block;
    text-align: left;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 250px;
  }
  
  .register-button {
    background-color: #F68C1E;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    display: inline-block;
  }
  .button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 30%;
      gap:20px
  }
  .phone-button {
    background-color: #F68C1E;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    display: inline-block;
    margin-top: 30px;
  }
.ban-tit h1 b {
    color: #EC184A;
    font-size: 72px;
    font-weight: 700;
}

.ban-tit h1 {
    font-weight: 700;
    font-size: 65px;
    line-height: 70px;
    color: #EC184A;
    margin-bottom: 20px;
}

.ban-tit span {
    font-size: 22px;
    font-weight: 400;
    padding: 0px 6px;
    text-transform: uppercase;
    font-family: var(--tit-font);
    color: #EC184A;
}

.ban-tit span i.no1 {
    font-size: 42px;
    font-weight: 600;
    font-family: var(--tit-font);
}

.ban-tit p {
    padding-bottom: 15px;
    font-weight: 400;
    font-size: 20px;
    color: #EC184A;
}


/*-------------------------------------------------------*/
/* BANNER SEARCH
/*-------------------------------------------------------*/
.ban-search {
    width: 90%;
    position: relative;
    background: rgba(0, 0, 0, 0.58);
    padding: 25px;
    border-radius: 5px;
    box-shadow: rgba(51, 51, 51, 0.31) 0px 1px 16px -8px;
    margin: 0px auto;
    display: table;
}

.ban-search ul li {
    float: left;
    width: 20%;
    padding: 0px 5px;
}

.ban-search ul li .chosen-select {
    height: 50px
}

.ban-search ul li.sr-sea {
    width: 53%;
    margin: 0px 1%;
}

.ban-search ul li input {
    width: 100%;
    display: block;
    line-height: 35px;
    font-size: 15px;
    outline: none;
    background: rgb(255, 255, 255);
    box-sizing: border-box;
    color: rgb(26, 42, 56);
    border: 1px solid rgb(213, 213, 213);
    padding: 0px 25px 0px 45px;
    font-weight: 500;
    box-shadow: rgba(150, 150, 150, 0.29) 0px 7px 20px -5px;
}

.ban-search ul li input[type="submit"] {
    background: var(--cta-pink1);
    color: rgb(255, 255, 255);
    border: 0px solid rgb(255, 167, 120);
    padding: 8px 25px;
    text-align: center;
    font-size: 18px;
    /* text-transform: uppercase; */
    letter-spacing: 1px;
    transition: all 0.5s ease 0s;
    border-radius: 3px;
    font-weight: 600;
    font-family: 'Josefin Sans', sans-serif;
}

.ban-search ul li input[type="submit"]:hover {
    transition: all 0.5s ease 0s;
    background: var(--cta-pink1h);
}

.ban-search ul li .form-group {
    margin: 0px;
}

.ban-search ul li .form-group label {
    font-size: 13px;
    font-weight: 500;
    display: none;
}

.ban-search .chosen-container {
    border: 0px;
}

.ban-search .chosen-container-single .chosen-single span {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Josefin Sans', sans-serif;
}

.ban-search .chosen-container .chosen-results li.active-result {
    font-size: 18px;
    font-weight: 600;
    color: rgb(68, 86, 103);
    border-bottom: 1px solid rgb(246, 243, 255);
    border-radius: 0px;
    padding: 10px 5px 10px 10px;
    transition: all 0.3s ease 0s;
    background-color: rgb(255, 255, 255);
    background-image: none;
    font-family: 'Josefin Sans', sans-serif;
}

.ban-search .chosen-container .chosen-results li.highlighted {
    background-color: rgb(255, 247, 233);
    background-image: none;
    color: rgb(169, 114, 31);
    padding-left: 10px;
}

.chosen-container .chosen-results li:last-child {
    border-bottom: 0;
}


.form-group select {
    height: 45px;
    font-size: 15px;
    border-radius: 5px;
}

/*-------------------------------------------------------*/
/* POPUP SEARCH
/*-------------------------------------------------------*/
.pop-search {
    position: fixed;
    background: rgba(10, 18, 36, 0.98);
    width: 100%;
    height: 100%;
    inset: 0px;
    z-index: 9;
    display: none;
    transition: all 0.5s ease-in-out 0s;
}

.pop-search .ser-clo {
    position: absolute;
    right: 30px;
    top: 25px;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-size: 65px;
    font-weight: 200;
    transform: rotate(45deg);
    line-height: 36px;
    transition: all 0.5s ease-in-out 0s;
}

.pop-search .ser-clo:hover {
    transform: rotate(135deg);
}

.pop-search .inn {
    max-width: 800px;
    width: 100%;
    margin: 60px auto 0px;
    display: table;
    position: relative;
    padding: 0 50px;
}

.pop-search .inn::before {
    content: "\f002";
    position: absolute;
    color: rgb(255, 255, 255);
    font: 200 18px / 1 FontAwesome;
    top: 14px;
    left: 50px;
}

.pop-search .inn input {
    width: 100%;
    height: 46px;
    background: none;
    border-bottom: 1px solid rgb(114, 114, 114);
    color: rgb(255, 255, 255);
    font-size: 20px;
    padding: 0px 0px 0px 35px;
}

.pop-search .rel-sear {
    padding-top: 20px;
}

.pop-search .rel-sear h4 {
    color: rgb(255, 255, 255);
    font-size: 15px;
    display: inline-block;
    font-weight: 500;
    margin: 0px 15px 0px 0px;
}

.pop-search .rel-sear a {
    color: rgb(33, 150, 243);
    font-size: 13px;
    font-weight: 400;
    display: inline-block;
    border: 1px solid rgb(33, 150, 243);
    padding: 2px 10px;
    border-radius: 20px;
    margin: 0px 5px 5px 0px;
}

/*-------------------------------------------------------*/
/* ABOUT HOME
/*-------------------------------------------------------*/
.home-about {
    float: left;
    width: 100%;
    background: rgb(255, 255, 255);
    padding: 0px 0px 80px;
    position: relative;
}

.home-tit {
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding-top: 80px;
}

.home-tit h2 {
    font-size: 46px;
    font-weight: 300;
    color: rgb(60, 89, 97);
}

.home-tit h2 span {
    font-weight: 600;
    color: #66451c;
}

.home-acces-main .home-tit h2 span {
    font-weight: 600;
    color: rgb(196, 140, 70);
}

.sub-tit-caps {
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
}

.sub-tit-caps h2 {
    font-weight: 300;
    color: #e74974;
    font-size: 34px;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.sub-tit-caps h2 span {
    font-weight: 700;
    color: #5a4550;
    position: relative;
    transition: all 0.5s ease;
}

.sub-tit-caps h2 span:before {
    content: '';
    position: absolute;
    width: 0%;
    height: 3px;
    left: 0;
    bottom: 0;
    background: #8A2387;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F27121, #E94057, #8A2387);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #e5006a, #E94057, #fbf8ed);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    transition: all 0.5s ease;
    transition-delay: 1s;
}

.sub-tit-caps h2 span.anistart:before {
    width: 100%;
}

.sub-tit-caps p {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 0px;
    color: #c48c46;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.home-tit .leaf1 {
    position: relative;
    margin: 0px auto;
    display: table;
    width: 200px;
    height: 51px;
}

.home-tit .leaf1::before,
.home-tit .leaf1::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 106px;
    background: url("../images/leaf/1.png") 0% 0% / 100% no-repeat;
    top: -5px;
    transition: all 0.4s ease-in-out 0s;
}

@keyframes leaffly {
    0% {
        transform: rotate(10deg);
    }

    100% {
        transform: rotate(-10deg);
    }
}

@keyframes leaffly1 {
    0% {
        transform: rotate(10deg) scaleX(-1);
    }

    100% {
        transform: rotate(-10deg) scaleX(-1);
    }
}

.home-tit .leaf1::before {
    right: 0px;
    animation: 5s linear 1s infinite alternate none running leaffly;
}

.home-tit .leaf1::after {
    left: 0px;
    transform: scaleX(-1);
    animation: 5s linear 1s infinite alternate none running leaffly1;
}

.home-tit h2 span .num {
    font-family: var(--tit-font);
    font-size: 90px;
    font-weight: 600;
    line-height: 70px;
}

.home-tit p {
    font-size: 20px;
    font-weight: 700;
    display: block;
    margin-bottom: 0px;
    color: rgb(196, 140, 70);
    text-transform: uppercase;
    letter-spacing: 5px;
    font-family: 'Cinzel Decorative', cursive;
}

.home-about .home-tit {
    padding-top: 90px;
}

.hom-abut {
    opacity: 0;
    transform: translateY(10px);
}

.hom-abut h2 {
    font-size: 30px;
    line-height: 42px;
    font-weight: 500;
    padding-bottom: 20px;
}

.hom-abo-im img:hover {
    transform: perspective(300px) rotateX(-4.59deg) rotateY(-3.8deg) scale3d(1, 1, 1);
}

.hom-why {
    padding: 30px 0px 0px 20px;
    color: rgb(102, 69, 28);
}

.hom-why ul li {
    padding: 0px 0px 15px 30px;
    font-size: 15px;
    font-weight: 500;
}

.hom-abo-im {
    width: 100%;
    float: left;
    position: relative;
}

.hom-abo-im img {
    width: 340px;
    height: auto;
    will-change: transform;
    transition: all 0.5s ease 0s;
    transform: perspective(300px) rotateX(0deg) rotateY(0deg);
}
.db-invoice{}
table *{
    font-size: 14px;
    font-weight: 400;
    padding: 10px !important;
    text-align: left;
    vertical-align: middle;
}
table th{
    font-weight: 500;
    font-size: 15px;
}
table td{}
.about-im-1 {
    left: 0px;
    top: 0px;
    z-index: 2;
    position: absolute;
}

.about-im-2 {
    z-index: 1;
    top: 190px;
    position: relative;
    left: 30%;
}

.hom-abo-im::before {
    content: "";
    position: absolute;
    width: 170px;
    height: 170px;
    clip-path: polygon(40% 38%, 8% 100%, 100% 100%);
    transform-origin: center center;
}

.hom-abo-im::before {
    background: rgb(100, 76, 35);
    left: -59px;
    top: -29px;
    animation: 20s ease 0s infinite normal both running rotat-light;
}

.hom-why ul li::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    margin: 4px 0px 0px -30px;
    background: rgb(223, 187, 126);
    border-radius: 50%;
    border: 4px solid rgb(255, 255, 255);
    box-shadow: rgb(240, 198, 126) 0px 0px 0px 1px;
}

.cta-line-lig-md {
    border: 1px solid rgb(102, 69, 28);
    font-family: var(--tit-font);
    margin-top: 40px;
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 13px;
    font-weight: 600;
    padding: 12px 32px;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.cta-line-lig-md:hover {
    background: #fff;
    color: #000000;
}

.hom-abu-com {
    transition: all 0.4s ease-in-out 0s;
}

.hom-abu-com h4 {
    position: relative;
    font-weight: 600;
    color: rgb(102, 69, 28);
    font-size: 20px;
    padding-bottom: 40px;
    text-transform: uppercase;
}

.hom-abu-com h4::before {
    content: "";
    position: absolute;
    width: 150px;
    height: 3px;
    background: linear-gradient(45deg, rgb(243, 200, 128), transparent);
    left: 2px;
    bottom: 22px;
}

.hom-abu-com p {
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 15px;
    line-height: 28px;
}



.longflr-lhs {
    left: 0px;
}

.longflr-rhs {
    right: 0px;
}

.ani-cir {
    width: 180px;
    height: 180px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    border: 50px solid rgb(251, 122, 146);
    z-index: 0;
    left: -80px;
    top: -36px;
    transition: all 0.4s ease-in-out 0s;
    opacity: 0;
    transform: scale(3.5);
    box-shadow: rgb(251, 122, 146) 0px 0px 418px 77px;
    display: inline-block;
    position: absolute;
}

.home-about.act .ani-cir {
    transform: scale(1);
    opacity: 1;
    box-shadow: rgb(251, 122, 146) 0px 0px 8px -2px;
}

.act .hom-abut {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: 0.4s;
}

.act .hom-why {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: 0.8s;
}

/*-------------------------------------------------------*/
/* HOME QUICK ACCESS
/*-------------------------------------------------------*/
.home-acces-main {
    position: relative;
    float: left;
    width: 100%;
    background: #000;
    padding-top: 30px;
}

.hom-cus-revi:before,
.pg-wedd-vid:before {
    content: '';
    position: absolute;
    background: url('../images/grass.png') no-repeat;
    width: 100%;
    height: 50px;
    bottom: -50px;
    left: 0;
    right: 0;
    background-size: contain;
}

.hom-cus-revi:before {
    top: 0;
    bottom: initial;
}

.home-acces {
    float: left;
    width: 100%;
    padding-bottom: 100px;
    /* overflow: hidden; */
    position: relative;
}

.home-acces ul li {
    float: left;
    width: 25%;
    padding: 0px 10px;
}

.home-acces ul li div.hacc {
    background-image: url("../images/couples/1.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    float: left;
    width: 100%;
    position: relative;
    border-radius: 8px;
    transition: all 0.5s ease-in-out 0s;
    overflow: hidden;
    max-height: 375px;
}

.act .home-acces ul li div.hacc {
    transition-delay: 0s;
}

.home-acces ul li div.hacc:hover,
.home-acces ul li div.hacc.act {
    transition-delay: 0s;
    background-size: 120%;
}

.home-acces ul li div.hacc:hover::after,
.home-acces ul li div.hacc.act::after {
    transform: scale(1);
}

.home-acces ul li div.hacc::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 200px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border-radius: 50px 50px 0px 0px;
    transition: all 0.4s ease-in-out 0s;
    background: rgb(229, 2, 107);
    transform: scale(0);
    transform-origin: right bottom;
    z-index: 0;
}

.act .home-acces ul li div.hacc1 {
    transform: translateY(0px);
    transition-delay: 0.5s;
    opacity: 1;
}

.act .home-acces ul li div.hacc2 {
    transform: translateY(0px);
    transition-delay: 1s;
    opacity: 1;
}

.act .home-acces ul li div.hacc3 {
    transform: translateY(0px);
    transition-delay: 1.5s;
    opacity: 1;
}

.act .home-acces ul li div.hacc4 {
    transform: translateY(0px);
    transition-delay: 2s;
    opacity: 1;
}

.home-acces ul li div.hacc::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0px;
    border-radius: 8px;
    transition: all 0.4s ease 0s;
    background: linear-gradient(to top, rgb(37, 24, 8), rgba(215, 154, 78, 0));
}

.home-acces ul li div.hacc1 {
    background-image: url("../images/couples/1.jpg");
}

.home-acces ul li div.hacc2 {
    background-image: url("../images/couples/2.jpg");
}

.home-acces ul li div.hacc3 {
    background-image: url("../images/couples/11.jpg");
}

.home-acces ul li div.hacc4 {
    background-image: url("../images/couples/4.jpg");
}

.home-acces ul li div.hacc .con {
    padding: 90px 20px 0px;
    text-align: center;
    color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    transition: all 0.4s ease-in-out 0s;
}

.home-acces ul li div.hacc:hover .con,
.home-acces ul li div.hacc.act .con {
    padding: 70px 20px 60px;
}

.home-acces ul li div.hacc .con img {
    border: 3px solid rgb(0, 0, 0);
    width: 140px;
    padding: 20px;
    border-radius: 25%;
    filter: invert(1);
    margin-bottom: 35px;
    transition: all 0.5s ease-in-out 0s;
    opacity: 0.8;
}

.home-acces ul li div.hacc:hover .con img,
.home-acces ul li div.hacc.act .con img {
    width: 100px;
}

.home-acces ul li div.hacc .con h4 {
    font-weight: 500;
    font-size: 26px;
    font-family: var(--tit-font);
    color: #fff;
}

.home-acces ul li div.hacc .con p {
    margin: 0px;
    color: #fff;
}

.home-acces ul li div.hacc .con a {
    border: 1px solid rgb(255, 255, 255);
    font-size: 16px;
    display: inline-block;
    padding: 5px 20px;
    margin-top: 25px;
    transition: all 0.4s ease 0s;
    opacity: 0;
    color: rgb(255, 255, 255);
}

.home-acces ul li div.hacc:hover .con a,
.home-acces ul li div.hacc.act .con a {
    opacity: 1;
    transform: translateY(0px);
    position: relative;
}

.home-acces .slick-arrow {
    z-index: 3;
    border: 0;
    background: none;
    margin-top: 63px;
    opacity: 0.5;
}

.home-acces .slick-arrow:before,
.home-acces .slick-arrow:after {
    content: '';
    position: absolute;
    display: block;
    transition: all 0.5s ease;
}

.home-acces .slick-arrow:before {
    width: 32px;
    height: 32px;
    border: 1px solid #fff;
    border-top: 0;
    border-right: 0;
    transform: rotate(45deg);
}

.home-acces .slick-arrow:after {
    left: -5px;
    width: 95px;
    height: 1px;
    border-top: 1px solid #fff;
    bottom: 24px;
}

.home-acces .slick-arrow:hover {}

.home-acces .slick-prev {
    left: 150px;
}

.home-acces .slick-next {
    right: 150px;
}

.home-acces .slick-next:before {
    right: -13px;
    left: initial;
    border: 1px solid #fff;
    border-bottom: 0;
    border-left: 0;
}

.fclick {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0px;
}

.home-brand {
    position: relative;
    float: left;
    width: 100%;
    padding: 20px 0px 100px;
}

.home-brand::before {
    content: "";
    position: absolute;
    left: 0px;
    top: -128px;
    right: 0px;
    width: 100%;
    height: 256px;
    transform: rotate(-180deg);
}

.inte {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.inte h2 {
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
}

.inte p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 40px;
}

.inte ul {
    float: left;
    width: 100%;
}

.inte ul li {
    float: left;
    width: 25%;
    padding: 0px 5px 5px;
}

.hom-oth {
    background: rgb(255, 255, 255);
    padding: 0px;
    margin: 0px 10px 5px;
    position: relative;
    overflow: hidden;
    border: 1px solid rgb(245, 237, 240);
    box-shadow: rgba(150, 150, 150, 0.78) 0px 0px 9px -7px;
    transition: all 0.5s ease 0s;
}

.hom-oth:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 7px 13px -9px;
    transition: all 0.5s ease 0s;
    transform: translateY(-1px);
    background: rgb(255, 255, 255);
}

.hom-oth div:nth-child(1) {
    float: left;
    width: 30%;
}

.hom-oth div:nth-child(1) img {
    width: 100%;
    height: 75px;
    object-fit: cover;
}

.hom-oth div:nth-child(2) {
    float: left;
    width: 70%;
    padding: 18px 15px 14px 20px;
}

.inte ul li div h4 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}

.inte ul li div span {
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}

.count {
    padding: 0px 0px 100px;
    float: left;
    width: 100%;
}

.hom-ab {
    background: rgb(249, 218, 202);
    padding: 25px 0px;
}

.hom-ab-in {
    width: 100%;
}

.hom-ab-in h2 {
    margin: 0px;
    text-align: center;
    font-size: 24px;
}

.hom-ab-in h2 a {
    color: rgb(228, 132, 81);
    padding: 5px 31px;
    border-radius: 50px;
    border: 1px solid;
}

/*-------------------------------------------------------*/
/* LOGIN, REGISTER PAGE
/*-------------------------------------------------------*/
.login,.db {
    float: left;
    width: 100%;
    /* padding: 80px 0px 85px 0px; */
    margin-top: var(--mtop);
}

.login .inn {
    background: rgb(255, 255, 255);
    position: relative;
    margin: 0px auto;
    display: table;
    box-shadow: rgb(51, 51, 51) 0px 1px 19px -17px;
    border-radius: 10px;
    width: 950px;
    padding: 0;
}

.login .lhs {
    float: left;
    width: 40%;
    background: linear-gradient(45deg, rgb(242, 209, 132), rgb(255, 238, 178) 80%);
    padding: 45px;
    position: absolute;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
    overflow: hidden;
}

.login .lhs .tit {
    padding: 0px 0px 30px;
}

.login .lhs .tit img {
    width: 200px;
    margin-bottom: 10px;
}

.login .lhs .tit h2 {
    font-size: 38px;
    font-weight: 500;
    line-height: 52px;
}

.login .lhs .tit h2 b {
    font-weight: 800;
    display: block;
    font-size: 62px;
    font-family: var(--tit-font);
    line-height: 66px;
    margin: 10px 0;
}

.login .lhs .im {
    float: left;
    width: 100%;
}

.login .lhs .im img {
    width: 90%;
    float: left;    border-radius: 5px;
}

.login .lhs .log-bg {
    width: 100%;
    height: 77px;
    background: url("../images/login-bg.png") center bottom / 300px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    transition: all 0.5s ease-in-out 0s;
    animation: 800s linear 0s infinite normal both running movehor;
    border-radius: 0px 0px 10px 10px;
    background-repeat-y: no-repeat;
}

@keyframes movehor {
    0% {
        background-position: 0px -18px;
    }

    100% {
        background-position: -10000% -18px;
    }
}

.login .lhs .log-bg img {}

.login .rhs {
    float: left;
    width: 60%;
    padding: 50px 80px;
    margin-left: 40%;
}

.login .rhs .inn {
    box-shadow: none;
}
.pro-edit-update{}
.pro-edit-update .rhs{width:100%;margin: 0;}

/*-------------------------------------------------------*/
/* COMMON FORM
/*-------------------------------------------------------*/
.form-tit {
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(223, 223, 223);
    margin-bottom: 25px;
    color: rgb(52, 64, 85);
}

.form-tit h4 {
    text-transform: uppercase;
    font-size: 14px;
    color: rgb(89 60 7);
}

.form-tit h1 {
    font-size: 26px;
    line-height: 38px;
    font-weight: 600;
    color: rgb(51, 51, 51);
}

.form-tit p {
    font-size: 14px;
    font-weight: 500;
}

.form-tit p a {
    color: rgb(11, 109, 215);
}

.form-login .form-group label.lb {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-bottom: 5px;
}

.form-login .form-group input,
.form-login .form-group select,
.form-login .form-group textarea,
.form-login .form-group .chosen-container {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    height: 42px;
}

.form-login .form-group select.chosen-select {
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    height: 42px;
}

.form-login .form-group textarea {
    height: 120px;
}

.form-login .form-group .chosen-container {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 10px);
    padding: 0;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-login .form-group .chosen-container a.chosen-single {
    height: 40px;
    padding-top: 2px;
}

.form-login .form-group .form-check-label {
    font-size: 13px;
    color: rgb(133, 136, 144);
    font-weight: 500;
}

.form-login .form-group .form-check-label input[type="checkbox"] {
    margin-top: 3px;
    height: 14px;
}

.form-login .btn {
    background: var(--cta-pink1);
    color: rgb(255, 255, 255);
    padding: 5px 50px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    line-height: 34px;
    display: block;
    border: 1px solid var(--cta-pink1);
    transition: all 0.3s ease 0s;
    width: 100%;
    margin-top: 30px;
}

.form-login .btn:hover {
    background: rgb(206, 27, 110);
    border: 1px solid rgb(206, 27, 110);
}

.error {
    color: #f00;
    font-size: 13px;
}

/*-------------------------------------------------------*/
/* FOOTER
/*-------------------------------------------------------*/
.wed-hom-footer {
    padding: 0px 0px 80px;
    background: #858585;
    float: left;
    width: 100%;
    position: relative;
    margin-top: 50px;
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}

.wed-hom-footer * {
    color: #415764;
    line-height: 28px;
    font-size: 15px;
}

.wed-hom-footer-top0 {
    margin-top: 0;
}

.wed-hom-footer:before {
    content: '';
    position: absolute;
    background: url(../images/grass.png) no-repeat;
    width: 100%;
    height: 50px;
    top: -50px;
    left: 0;
    right: 0;
    background-size: contain;
    transform: rotate(180deg);
}

.foot-supp {
    background: rgb(255, 22, 108);
    width: 70%;
    margin: -50px auto 0px;
    display: table;
    padding: 30px 20px 20px;
    border-radius: 5px;
    position: relative;
}

.wed-hom-footer h4 {
    font-size: 16px;
    padding-bottom: 25px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Josefin Sans';
}

.foot-count p {
    margin-bottom: 0px;
}

.wed-hom-footer p a.btn {
    font-size: 14px;
    color: rgb(255, 255, 255);
    background: var(--cta-pink1);
    border: 0px;
    padding: 3px 15px;
}

.wed-hom-footer p a.btn:hover {
    background: var(--cta-pink1h);
}

.wed-foot-link {
    padding: 90px 0px 50px;
}

.wed-foot-link ul li {
    width: 50%;
    float: left;
    margin-bottom: 8px;
}

.wed-foot-link ul li a {}

.wed-foot-link div {
    border-right: 1px solid #b9c6cf;
    padding-left: 40px;
}

.wed-foot-link div:last-child {
    border-right: 0px solid rgb(218, 218, 218);
}

.wed-video2 {
    padding: 50px 0px;
    background: rgb(241, 241, 241);
}

.wed-foot-link-1 {}

.wed-foot-link-1 ul {
    position: relative;
    overflow: hidden;
}

.wed-foot-link-1 ul li {
    float: left;
    padding-right: 8px;
}

.wed-foot-link-1 ul li a {}

.fot-soc ul li {
    width: auto;
}

.fot-app ul li a img {
    width: 157px;
    height: 55px;
    display: inline-block;
    object-fit: contain;
}

.fot-soc ul li a img {
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 50%;
}

.wed-rights {
    background: rgb(1, 23, 47);
}

.wed-rights p {
    text-align: center;
    margin-bottom: 0px;
    padding: 8px;
    color: rgb(173, 173, 173);
    font-size: 13px;
}

.foot-supp h2 {
    text-align: center;
    width: 100%;
    font-size: 19px;
    font-weight: 500;
    padding-bottom: 0px;
    color: rgb(255, 255, 255);
    font-family: 'Cinzel Decorative';
}

.foot-supp h2 span {
    font-weight: 300;
    padding-right: 5px;
    color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 20px;
}

.foot-count {
    border-top: 1px solid #b9c6cf;
    padding: 30px 0px 0px;
    width: 100%;
}

.foot-count p {
    text-align: center;
    width: 100%;
    line-height: 26px;
}

.cr {
    background: #76001C;
    float: left;
    width: 100%;
    position: relative;
}

.cr * {
    color: #FFF;
    font-weight: 400;
    font-size: 18px;
}

.cr p {
    width: 100%;
    text-align: center;
    margin: 0px;
    padding: 10px 0px;
}

.cr p a {
    text-decoration: underline;
}

.mt-60 {
    margin-top: 60px;
}

/*-------------------------------------------------------*/
/* ALL PROFILE
/*-------------------------------------------------------*/
.all-weddpro {
    float: left;
    width: 100%;
}

.all-weddpro .col-md-3 {
    background: rgb(255 255 255);
    border-radius: 10px;
    padding: 30px 25px;
    margin: 60px 0px 70px;
    border: 1px solid #ebddaa;
}

.all-filt {
    background: rgb(245, 245, 245);
    padding-right: 15px;
}

.short-all {
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid rgb(226, 225, 225);
    padding: 60px 0px 5px 36px;
}

.short-lhs {
    float: left;
    width: 250px;
    font-family: var(--tit-font);
    margin-top: 8px;
    font-size: 22px;
}

.short-rhs {
    float: left;
    width: calc(100% - 250px);
}

.short-rhs ul {
    float: right;
}

.short-rhs ul li {
    display: inline-block;
    font-family: var(--tit-font);
}

.short-rhs ul li .form-group {
    width: 200px;
    margin: 0 8px 0 10px;
}

.short-rhs ul li .form-group .chosen-container {
    border: 1px solid rgb(96, 30, 79);
}

.short-rhs ul li .form-group .chosen-container .chosen-results {
    float: left;
    width: 100%;
}

.sort-grid {
    width: 50px;
    height: 50px;
    border: 1px solid #000;
    border-radius: 8px;
    text-align: center;
    vertical-align: middle;
    top: -7px;
    position: relative;
    margin: 0 4px;
    cursor: pointer;
}

.sort-grid.act {
    background: #d3a25a;
    border: 1px solid #af813d;
    color: #fff;
}

.sort-grid i {
    vertical-align: middle;
    font-size: 20px;
    padding-top: 16px;
    text-align: center;
}

.all-list-sh {
    float: left;
    padding: 30px 0px 65px;
    width: 100%;
}

.all-list-sh.view-grid ul li {
    width: 50%;
}

.view-grid .pro-img {
    width: 100%;
}

.view-grid .all-pro-box div:nth-child(1) img {
    height: 250px;
}

.view-grid .pro-detail {
    width: 100%;
}

.all-list-sh.view-grid .all-pro-box {}


.all-list-sh ul li {
    padding: 0px 0px 10px 10px;
    float: left;
    width: 100%;
    transition: all 0.5s ease;
}

.all-list-sh ul li:last-child {
    padding-bottom: 0px;
}

.fil-mob {
    cursor: pointer;
    background: rgb(252, 232, 209);
    padding: 10px 5px;
    transition: all 0.5s ease-in-out 0s;
    width: 100%;
    float: left;
}

.fil-mob.fil-mob-act {
    position: relative;
    width: 100%;
    display: none;
}

.fil-mob h4 {
    margin: 0px;
    font-size: 15px;
    text-align: center;
}

.fil-mob h4 i {
    font-size: 15px;
    vertical-align: middle;
    font-weight: 600;
}

.filt-com {
    float: left;
    margin-bottom: 30px;
    width: 100%;
}

.filt-com .form-group {
    margin-bottom: 0px;
}
.responsive-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .register-button {
    background-color: #F68C1E;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  .register-button:hover {
    background-color: #e47600;
  }
  
  @media (max-width: 480px) {
    .responsive-form {
      padding: 10px;
    }
    
    .form-group input {
      font-size: 16px; /* Prevents zooming on mobile */
    }
  }
.filter-clo {
    position: absolute;
    right: 30px;
    top: 25px;
    cursor: pointer;
    color: #000;
    font-size: 65px;
    font-weight: 200;
    transform: rotate(45deg);
    line-height: 36px;
    transition: all 0.5s ease-in-out 0s;
    display: none;
}

.lhs-search ul li {
    float: left;
}

.lhs-search ul li:nth-child(1) {
    width: 75%;
}

.lhs-search ul li:nth-child(2) {
    width: 25%;
}

.lhs-search ul li:nth-child(1) input {
    background: rgb(232, 234, 237);
    border: 0px;
    font-size: 15px;
    padding: 12px 18px;
    color: rgb(10, 10, 11);
    width: 100%;
    border-radius: 5px 0px 0px 5px;
    font-weight: 500;
    float: left;
}



.filt-com h4 {
    font-size: 16px;
    color: rgb(96, 30, 79);
    padding-left: 36px;
    margin-bottom: 15px;
    font-weight: 600;
    position: relative;
    font-family: var(--tit-font);
}

.lhs-cate h4 i {
    left: 0;
    position: absolute;
    border: 1px solid rgb(199 177 151);
    padding: 4px 5px 5px 5px;
    border-radius: 8px;
    vertical-align: initial;
    width: 28px;
    height: 28px;
    top: -4px;
    text-align: center;
}

.lhs-cate .chosen-container {
    border: 1px solid rgb(96, 30, 79);
}

.filt-com .dropdown-menu {
    min-height: 200px;
    max-height: 400px;
    overflow: hidden auto;
    width: 254px;
}

.filt-send-query {
    position: sticky;
    top: 75px;
}

.send-query {
    background: rgb(43 58 58);
    padding: 24px 20px;
    float: left;
    width: 100%;
    border-radius: 10px;
    text-align: center;
}

.send-query h5 {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    color: #fff;
    font-family: var(--tit-font);
}

.send-query p {
    font-size: 12px;
    font-weight: 400;
    color: rgb(171 171 171);
}

.send-query input {
    border: 0px;
    font-size: 12px;
    width: 100%;
    padding: 10px;
    font-weight: 500;
    border-radius: 3px;
    margin-bottom: 8px;
}

.send-query a {
    background: rgb(199, 65, 88);
    color: rgb(255, 255, 255);
    font-size: 11px;
    padding: 7px 20px;
    border-radius: 4px;
    font-weight: 600;
    display: inline-block;
    transition: all 0.5s ease 0s;
    /* float: left; */
    border: 0px;
}

.job-ser-cnt {
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid rgb(226, 225, 225);
    padding: 60px 0px 5px 36px;
}

.job-ser-cnt b {
    font-weight: 700;
}

.all-pro-head {
    padding: 35px 30px 38px;
    background: url("../images/banner.jpg") 0px -105px / cover no-repeat;
    position: relative;
    width: 100%;
    float: left;
    text-align: center;
    margin-top: var(--mtop);
}

.all-pro-head::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0px;
    background: linear-gradient(90deg, rgba(71, 9, 9, 0.64) 5%, rgb(135, 15, 68) 80%);
    opacity: 0.8;
}

.all-pro-head h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    display: block;
    width: 100%;
    color: rgb(255, 255, 255);
    position: relative;
    padding-bottom: 10px;
}

.all-pro-head a {
    color: rgb(255, 255, 255);
    position: relative;
    padding: 10px 20px;
    display: table;
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    background: linear-gradient(45deg, rgb(205, 0, 152), rgb(225, 77, 97) 80%);
    margin: 0px auto;width:auto;
}

.all-pro-head a i {
    vertical-align: middle;
    font-size: 22px;
    margin-left: 5px;
    transition: all 0.4s ease 0s;
}

.all-pro-head a:hover i {
    margin-left: 7px;
}

.all-pro-box {
    background: rgb(255, 255, 255);
    overflow: hidden;
    padding: 10px;
    position: relative;
    box-shadow: rgba(51, 51, 51, 0.1) 0px 1px 8px -2px;
    border-radius: 5px;
    transition: all 0.5s ease 0s;
    border: 1px solid rgb(255, 255, 255);
}

.all-pro-box:hover div:nth-child(1) img {
    transition: all 0.5s ease 0s;
    transform: scale(1.1);
}

.all-pro-box:hover div:nth-child(2) h4 a {
    color: rgb(202, 44, 88);
}

.all-pro-box div:nth-child(1) img {
    width: 100%;
    float: left;
    object-fit: cover;
    height: 205px;
    transition: all 0.5s ease 0s;
    cursor: pointer;
    border-radius: 4px;
}

.pro-detail {
    padding: 25px;
    float: left;
    width: 60%;
}

.pro-detail h4 {
    font-size: 20px;
    padding-bottom: 10px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    position: relative;
    font-weight: 600;
    text-align: left;
    padding-left: 0px;
}

.pro-detail h4 a {
    color: rgb(33, 37, 41);
    font-weight: 600;
    font-family: var(--tit-font);
    font-size: 26px;
}

.pro-detail p {
    margin: 0px 0px 20px;
    overflow: hidden;
    font-size: 13px;
    line-height: 17px;
    height: 50px;
    color: rgb(134, 128, 124);
    display: none;
}

.all-pro-box span.addr {
    font-size: 13px;
    padding-left: 25px;
    display: block;
    padding-bottom: 10px;
    font-weight: 500;
    color: rgb(51, 51, 51);
    display: none;
}

.all-pro-box span.pho,
.all-pro-box span.mail {
    float: left;
    padding: 0px 25px 0px 30px;
}

.pro-detail span::before {
    margin: -3px 0px 0px -25px;
    color: rgb(121, 120, 127);
}

.pro-detail span.addr::before {
    content: "map";
    line-height: 24px;
}

.pro-detail span.pho::before {
    content: "phone";
}

.pro-detail span.mail::before {
    content: "mail";
}

.pro-detail span.pho {
    padding-bottom: 0px;
}

.pro-pg-intro {}

.pro-pg-intro h1 {
    margin-bottom: 15px;
    font-size: 46px;
    font-weight: 700;
}

.pro-pg-intro ul {
    display: table;
    width: 100%;
    margin-bottom: 50px;
}

.pro-pg-intro ul li {
    float: left;
    margin: 0 10px 10px 0;
}

.pro-pg-intro ul li div {
    border: 1px solid rgb(231, 231, 240);
    border-radius: 16px;
    text-align: center;
    padding: 15px;
    min-width: 140px;
}

.pro-pg-intro ul li div img {
    width: 42px;
    margin: 0 auto;
    display: table;
    margin-bottom: 10px;
}

.pro-pg-intro ul li div span {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
}

.pro-pg-intro ul li div span strong {
    font-weight: 600;
    display: block;
    font-size: 18px;
}

.pr-bio-hob ul li {
    display: inline-block;
    padding: 0px 12px 12px 0px;
}

.pr-bio-hob ul li span {
    background: rgb(239 239 239);
    font-size: 14px;
    font-weight: 400;
    padding: 4px 7px;
    border-radius: 4px;
    color: rgb(24, 28, 32);
}

.pr-bio-soc ul li {
    display: inline-block;
    padding: 0px 6px 6px 0px;
}

.pr-bio-soc ul li a {}

.pr-bio-conta ul li {
    padding-bottom: 30px;
    position: relative;
}

.pr-bio-conta ul li span {
    font-weight: 400;
    position: relative;
    padding-left: 55px;
    display: block;
}

.pr-bio-conta ul li span b {
    font-weight: 600;
    width: 110px;
    padding-right: 10px;
    position: relative;
}

.pr-bio-conta ul li span i {
    font-size: 18px;
    display: inline-block;
    position: absolute;
    top: -7px;
    border: 1px solid rgb(219, 204, 187);
    padding: 8px;
    border-radius: 10px;
    vertical-align: initial;
    width: 38px;
    height: 38px;
    text-align: center;
    margin-right: 8px;
    left: 0;
}

.pr-bio-conta ul li:nth-child(2) span b i {
    left: -4px;
}

.pr-bio-conta ul li:last-child {
    padding-bottom: 0;
}

.wedd-gall-pg-v1 .pr-bio-gal {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
}

.pr-bio-gal ul li {
    float: left;
    width: 33.3333%;
    padding: 0px 20px 20px 0px;
}

.pr-bio-gal ul li span img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    float: left;
    border-radius: 4px;
}


.hom-eve-lhs-2 {
    float: left;
    width: 50%;
    padding-left: 25px;
}

.hom-eve-lhs-2 ul li {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.all-pro-box-list {
    float: left;
    width: 100%;
    background: rgb(255, 255, 255);
    padding: 0px 10px 0px 0px;
    overflow: hidden;
    border: 1px solid rgb(249, 237, 231);
    box-shadow: rgba(150, 150, 150, 0.78) 0px 0px 9px -7px;
    position: relative;
}

.pro-avl-status {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.pro-avl-status h5 {
    background: rgb(143, 163, 181);
    color: rgb(255, 255, 255);
    font-size: 11px;
    text-align: center;
    margin: 0px auto;
    padding: 4px 8px;
    border-radius: 0px;
    font-weight: 500;
    display: none;
}

.pro-avl-status .marqu {
    background: rgb(143, 163, 181);
    color: rgb(255, 255, 255);
    font-size: 11px;
    text-align: center;
    display: block;
    margin: 0px auto;
    padding: 4px 8px;
    border-radius: 0px;
    font-weight: 500;
    height: 21px;
    width: 120%;
    line-height: 15px;
}

.pro-avl-status {
    margin: 0px auto;
    overflow: hidden;
    box-sizing: border-box;
    background: rgb(143, 163, 181);
}

.marqu {
    display: inline-block;
    width: 100%;
    padding-left: 100%;
    will-change: transform;
    animation: 15s linear 0s infinite normal none running marquee;
}

.marqu:hover {
    animation-play-state: paused;
}

@keyframes marquee {
    0% {
        transform: translate(75%, 0px);
    }

    100% {
        transform: translate(-100%, 0px);
    }
}

@media (prefers-reduced-motion: reduce) {
    .marqu {
        animation-iteration-count: 1;
        width: auto;
        padding-left: 0px;
    }
}

.user-avil-onli .pro-avl-status h5 {
    background: rgb(76, 175, 80);
    color: rgb(255, 255, 255);
    display: table;
    width: 100%;
}

.user-avil-onli .pro-avl-status .marqu {
    display: none;
}

.user-avil-onli .pro-ave-yes, .db-new-pro .pro-ave-yes {
    background: rgb(76, 175, 80);
    box-shadow: rgb(76, 175, 80) 0px 0px 0px 0px;
}
.all-pro-box-list:hover h4 {
    color: rgb(235, 144, 95);
}

.all-pro-box-list img {
    width: 65px;
    height: 65px;
    object-fit: cover;
    float: left;
    margin-right: 18px;
    transition: all 0.5s ease 0s;
}

.all-pro-box-list h4 {
    margin-top: 17px;
    margin-bottom: 2px;
    color: rgb(52, 60, 66);
    font-size: 14px;
    font-weight: 600;
    transition: all 0.5s ease 0s;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}

.all-pro-box-list p {
    color: rgb(52, 60, 66);
    font-size: 13px;
    font-weight: 400;
    transition: all 0.5s ease 0s;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    margin-bottom: 0px;
}

.all-pro-box-list span {
    position: absolute;
    background: rgba(0, 0, 0, 0.63);
    z-index: 3;
    bottom: 0px;
    left: 0px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    line-height: 15px;
    padding: 2px 12px 4px 13px;
    border-radius: 0px;
}

.all-pro-box-list span b {
    font-weight: 600;
    padding-left: 2px;
}

.all-pro-box-list a {
    position: absolute;
    cursor: pointer;
    inset: 0px;
    z-index: 5;
}

.all-list-sh .all-pro-box {
    margin: 10px 0px 10px 15px;
}

.pro-img {
    float: left;
    width: 40%;
    overflow: hidden;
    border-radius: 3px;
    position: relative;
    transition: all 0.5s ease;
}

span.mail::before {
    content: "mail";
}

.links {
    /* float: center; */
    /* padding: 0px;
    border: 0px; */
}

.links a,
.links span {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(225, 231, 236);
    color: rgb(51, 51, 51);
    font-size: 12px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 30px;
    float: left;
    margin: 0 8px 8px 0;
    cursor: pointer;
    transition: all 0.4s ease 0s;
}

.all-pro-box:hover .links span.cta-chat {
    background: rgb(76, 175, 80);
    border: 1px solid rgb(76, 175, 80);
    color: rgb(255, 255, 255);
    transition: all 0.4s ease 0s;
}

.pro-ave {
    position: absolute;
    left: 10px;
    top: 8px;
}

.pro-ave-yes {
    width: 14px;
    height: 14px;
    background: rgb(143, 163, 181);
    display: inline-block;
    border-radius: 50px;
    border: 2px solid rgb(255, 255, 255);
    position: relative;
    box-shadow: rgb(143, 163, 181) 0px 0px 0px 0px;
    animation: 1s cubic-bezier(0.4, 0, 0, 1) 0s infinite normal none running pulse;
}

@-webkit-keyframes pulse {
    100% {
        box-shadow: rgba(232, 76, 61, 0) 0px 0px 0px 15px;
    }
}

@keyframes pulse {
    100% {
        box-shadow: rgba(232, 76, 61, 0) 0px 0px 0px 15px;
    }
}

.enq-sav {
    position: absolute;
    top: 10px;
    right: 10px;
}

.enq-sav i {
    cursor: pointer;
    color: rgb(167, 184, 193);
    border-radius: 50%;
    font-size: 16px;
    padding: 4px;
    text-align: center;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    transition: all 0.5s ease 0s;
    background: rgb(255, 255, 255);
}

.enq-sav i.sav-act {
    color: rgb(244, 193, 80);
    box-shadow: rgb(255, 238, 201) 0px 0px 0px;
    animation: 1.5s ease 0s 1 normal none running pulse;
    background: rgb(255, 245, 222);
}

.pro-detail .pro-bio {
    width: 100%;
    float: left;
    padding: 12px 5px 12px 0px;
    border-bottom: 1px solid rgb(218, 218, 218);
    margin-bottom: 15px;
}

.pro-detail .pro-bio span {
    font-size: 11px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    float: left;
    line-height: 22px;
    background: rgb(113, 143, 160);
    padding: 0px 6px;
    margin: 0px 5px 5px 0px;
    border-radius: 3px;
    display: inline-block;
}

/*-------------------------------------------------------*/
/* MOBILE MENU
/*-------------------------------------------------------*/
.mob-menu {}


.mv-bus {
    float: left;
    width: 100%;
}

.mobile-ser img {
    padding: 7px;
}

.mob-me-all {
    position: fixed;
    width: 100%;
    max-width: 400px;
    right: -150%;
    top: 0px;
    bottom: 0px;
    background: rgb(255, 255, 255);
    padding: 30px;
    overflow-y: auto;
    transition: all 0.5s ease 0s;
    z-index: 5;
}

.mob-me-all.act {
    right: 0%;
    transition: all 0.5s ease 0s;
}

.mob-me-all h4 {
    font-size: 16px;
    margin-top: 40px;
    font-weight: 700;
}

.mob-me-all h4 i {
    width: 38px;
    height: 38px;
    background: #ffe6d1;
    border-radius: 10px;
    font-size: 24px;
    text-align: center;
    padding: 6px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
}

.mob-me-all .head-pro {
    width: 100%;
    margin: 20px 0;
}

.mob-me-all .head-pro img {
    width: 60px;
    height: 60px;
    margin: -8px 15px 0 0;
}

.mob-me-all .head-pro h4 {
    margin: 0;
}

.mob-me-all ul li a {
    display: block;
    width: 100%;
    color: rgb(51, 51, 51);
    border-bottom: 1px solid rgb(233 233 233);
    padding: 10px 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.mob-me-all ul li:last-child a {
    border-bottom: 0;
}

.mob-me-all .menu-pop-help {
    margin-top: 30px;
    padding-top: 0;
}

.mob-me-all .menu-pop-help h4 {
    margin-top: 35px;
}

.mob-me-all .late-news {
    margin: 40px 0px 20px 0px;
    padding: 0px 0px 50px 0px;
}

.mob-me-all .prof-rhs-help {
    margin: 25px 0 25px 0;
}

.mob-me-clo {
    position: absolute;
    right: 15px;
    top: 12px;
}

.mob-me-clo i {
    font-size: 21px;
    text-align: center;
    padding: 5px;
}

.mv-pro.ud-lhs-s1 {
    padding: 30px 0px 0px;
}

.mv-pro {
    cursor: pointer;
}

.mv-pro-menu {
    display: none;
    padding-top: 15px;
}

/*-------------------------------------------------------*/
/* CHOOSEN SELECT
/*-------------------------------------------------------*/
.chosen-select {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    height: 40px;
}

.chosen-select-deselect {
    width: 100%;
}

.chosen-container {
    display: inline-block;
    font-size: 14px;
    position: relative;
    vertical-align: middle;
    height: 52px;
    border-radius: 4px;
    width: 100% !important;
}

.chosen-container .chosen-drop {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(250, 251, 251);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 16px -6px;
    margin-top: -1px;
    position: absolute;
    top: 100%;
    left: -9000px;
    z-index: 1060;
    padding: 6px;
}

.chosen-container.chosen-with-drop .chosen-drop {
    left: 0px;
    right: 0px;
}

.chosen-container .chosen-results {
    color: rgb(85, 85, 85);
    margin: 0px 4px 4px 0px;
    max-height: 240px;
    padding: 0px 0px 0px 4px;
    position: relative;
    overflow: hidden auto;
}

.chosen-container .chosen-results li {
    display: none;
    line-height: 1.42857;
    list-style: none;
    margin: 0px;
    padding: 8px 6px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(100 44 40);
    border-radius: 0;
    width: 100%;
    border-bottom: 1px solid #f9f3f3;
}

.chosen-container .chosen-results li em {
    background: rgb(254, 255, 222);
    font-style: normal;
}

.chosen-container .chosen-results li.group-result {
    display: list-item;
    cursor: default;
    color: rgb(153, 153, 153);
    font-weight: 700;
}

.chosen-container .chosen-results li.group-option {
    padding-left: 15px;
}

.chosen-container .chosen-results li.active-result {
    cursor: pointer;
    display: list-item;
}

.chosen-container .chosen-results li.highlighted {
    background-color: rgb(255, 235, 245);
    background-image: none;
    color: rgb(239, 8, 119);
}

.chosen-container .chosen-results li.highlighted em {
    background: transparent;
}

.chosen-container .chosen-results li.disabled-result {
    display: list-item;
    color: rgb(119, 119, 119);
}

.chosen-container .chosen-results .no-results {
    background: rgb(238, 238, 238);
    display: list-item;
}

.chosen-container .chosen-results-scroll {
    background: rgb(255, 255, 255);
    margin: 0px 4px;
    position: absolute;
    text-align: center;
    width: 321px;
    z-index: 1;
}

.chosen-container .chosen-results-scroll span {
    display: inline-block;
    height: 1.42857px;
    text-indent: -5000px;
    width: 9px;
}

.chosen-container .chosen-results-scroll-down {
    bottom: 0px;
}

.chosen-container .chosen-results-scroll-down span {
    background: url("../images/icon/chosen-sprite.png") -4px -3px no-repeat;
}

.chosen-container .chosen-results-scroll-up span {
    background: url("../images/icon/chosen-sprite.png") -22px -3px no-repeat;
}

.chosen-container-single .chosen-single {
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border-radius: 4px;
    color: rgb(85, 85, 85);
    display: block;
    height: 50px;
    overflow: hidden;
    line-height: 37px;
    padding: 7px 5px 0px 12px;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
}

.chosen-container-single .chosen-single span {
    display: block;
    margin-right: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
}

.chosen-container-single .chosen-single abbr {
    background: url("../images/icon/chosen-sprite.png") right top no-repeat;
    display: block;
    font-size: 1px;
    height: 10px;
    position: absolute;
    right: 26px;
    top: 12px;
    width: 12px;
}

.chosen-container-single .chosen-single abbr:hover {
    background-position: right -11px;
}

.chosen-container-single .chosen-single.chosen-disabled .chosen-single abbr:hover {
    background-position: right 2px;
}

.chosen-container-single .chosen-single div {
    display: block;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 18px;
}

.chosen-container-single .chosen-single div b {
    display: block;
    height: 100%;
    width: 100%;
}

.chosen-container-single .chosen-default {
    color: rgb(119, 119, 119);
}

.chosen-container-single .chosen-search {
    margin: 0px;
    padding: 3px 4px;
    position: relative;
    white-space: nowrap;
    z-index: 1000;
}

.chosen-container-single .chosen-search input[type="text"] {
    border: 0px;
    border-bottom: 1px solid rgb(223 197 195);
    border-radius: 0;
    margin: 4px 0px;
    padding: 4px 20px 4px 4px;
    width: 100%;
    height: 35px;
    font-weight: 600;
    font-family: 'Josefin Sans', sans-serif;
}

.chosen-container-single .chosen-drop {
    margin-top: -1px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-clip: padding-box;
}

.chosen-container-single-nosearch .chosen-search input {
    position: absolute;
    left: -9000px;
}

.chosen-container-multi .chosen-choices {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    cursor: text;
    margin: 0px;
    overflow: hidden;
    padding: 3px 5px 0px;
    position: relative;
    height: 40px;
    font-size: 15px;
    font-weight: 500;
}

.chosen-container-multi .chosen-choices li {
    float: left;
    list-style: none;
}

.chosen-container-multi .chosen-choices .search-field {
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
}

.chosen-container-multi .chosen-choices .search-field input[type="text"] {
    box-shadow: none;
    color: rgb(85, 85, 85);
    height: 32px;
    margin: 0px;
    padding: 4px;
    outline: 0px;
    font-size: 12px;
    background: transparent !important;
    border: 0px !important;
}

.chosen-container-multi .chosen-choices .search-field .default {
    color: rgb(153, 153, 153);
}

.chosen-container-multi .chosen-choices .search-choice {
    background-clip: padding-box;
    border: 1px solid rgb(233, 239, 244);
    border-radius: 4px;
    cursor: default;
    line-height: 13px;
    margin: 6px 0px 3px 5px;
    padding: 4px 20px 3px 5px;
    position: relative;
    font-size: 11.5px;
    background-color: rgb(231, 251, 232);
    color: rgb(45, 134, 50);
}

.chosen-container-multi .chosen-choices .search-choice .search-choice-close {
    background: url("../images/icon/chosen-sprite.png") right top no-repeat;
    display: block;
    font-size: 1px;
    height: 10px;
    position: absolute;
    right: 4px;
    top: 5px;
    width: 12px;
    cursor: pointer;
}

.chosen-container-multi .chosen-choices .search-choice .search-choice-close:hover {
    background-position: right -11px;
}

.chosen-container-multi .chosen-choices .search-choice-focus {
    background: rgb(212, 212, 212);
}

.chosen-container-multi .chosen-choices .search-choice-focus .search-choice-close {
    background-position: right -11px;
}

.search-choice-close::after {
    position: absolute;
    color: rgb(69, 185, 94);
    font-size: 12px;
    font-weight: 600;
}

.chosen-container-multi .chosen-results {
    margin: 0px;
    padding: 0px;
}

.chosen-container-multi .chosen-drop .result-selected {
    display: none;
}

.chosen-container-active.chosen-with-drop .chosen-single {
    background-color: rgb(255, 255, 255);
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.chosen-container-active.chosen-with-drop .chosen-single div {
    background: transparent;
    border-left: none;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -18px 7px;
}

.chosen-container.chosen-container-single .chosen-single div b::before {
    font-family: FontAwesome;
    content: "\f107";
}

.chosen-container.chosen-container-single .chosen-single div b::before {
    top: 4px;
    position: relative;
}

.job-sear .chosen-container.chosen-container-single .chosen-single div b::before {
    margin-top: 8px;
}

.chosen-container-active .chosen-choices .search-field input[type="text"] {
    color: rgb(17, 17, 17) !important;
}

.chosen-container-active.chosen-with-drop .chosen-choices {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.chosen-disabled {
    cursor: default;
    opacity: 0.5 !important;
}

.chosen-disabled .chosen-single {
    cursor: default;
}

.chosen-disabled .chosen-choices .search-choice .search-choice-close {
    cursor: default;
}

.chosen-rtl {
    text-align: right;
}

.chosen-rtl .chosen-single {
    padding: 0px 8px 0px 0px;
    overflow: visible;
}

.chosen-rtl .chosen-single span {
    margin-left: 26px;
    margin-right: 0px;
    direction: rtl;
}

.chosen-rtl .chosen-single div {
    left: 7px;
    right: auto;
}

.chosen-rtl .chosen-single abbr {
    left: 26px;
    right: auto;
}

.chosen-rtl .chosen-choices .search-field input[type="text"] {
    direction: rtl;
}

.chosen-rtl .chosen-choices li {
    float: right;
}

.chosen-rtl .chosen-choices .search-choice {
    margin: 6px 5px 3px 0px;
    padding: 3px 5px 3px 19px;
}

.chosen-rtl .chosen-choices .search-choice .search-choice-close {
    background-position: right top;
    left: 4px;
    right: auto;
}

.chosen-rtl.chosen-container-single .chosen-results {
    margin: 0px 0px 4px 4px;
    padding: 0px 4px 0px 0px;
}

.chosen-rtl .chosen-results .group-option {
    padding-left: 0px;
    padding-right: 15px;
}

.chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
    border-right: none;
}

.chosen-rtl .chosen-search input[type="text"] {
    background: url("../images/icon/chosen-sprite.png") -28px -20px no-repeat, rgb(255, 255, 255);
    direction: rtl;
    padding: 4px 5px 4px 20px;
}


/*-------------------------------------------------------*/
/* ABOUT TESTIMONIALS
/*-------------------------------------------------------*/
.hom-partners {
    float: left;
    width: 100%;
    padding-bottom: 80px;
}

.hom-partners .carousel {
    width: 90%;
    margin: 0px auto;
}

.hom-partners ul {
    float: left;
    width: 100%;
}

.hom-partners ul li {
    float: left;
    width: 33.3333%;
    padding: 0px 15px;
}

.cta-full {
    background: var(--cta-pink1);
    color: rgb(255, 255, 255);
    padding: 5px 30px;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 40px;
    display: inline-block;
    border: 1px solid var(--cta-pink1);
}

.cta-line {
    border: 1px solid var(--cta-pink1);
    color: var(--cta-pink1);
    padding: 5px 30px;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 40px;
    display: inline-block;
}

.cta-full:hover,
.cta-line:hover {
    background: var(--cta-pink1h);
    color: rgb(255, 255, 255);
    border: 1px solid var(--cta-pink1h);
    box-shadow: rgba(51, 51, 51, 0.73) 0px 3px 18px -12px;
}

.cta-line:hover{}

.cta-3 {
    background: #530014;
    color: rgb(255, 255, 255);
    padding: 6px 30px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 30px;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    /* letter-spacing: 1px; */
}

.cta-4 {
    border: 1px solid var(--cta-dark);
    color: var(--cta-dark);
    padding: 6px 30px;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 30px;
    display: inline-block;
    text-transform: uppercase;
    /* letter-spacing: 1px; */
}

.cta-3:hover {
    color: rgb(255, 255, 255);
    background: rgb(19, 18, 32);
    border: 1px solid rgb(19, 18, 32);
}

.cta-4:hover {
    color: rgb(255, 255, 255);
    background: rgb(19, 18, 32);
    border: 1px solid rgb(19, 18, 32);
}
.cta-sml{
    padding: 6px 5px !important;
}
.cta-sml span{font-size: 12px;}
.fot-ban {
    float: left;
    width: 100%;
    padding: 80px 0px;
}

.fot-ban-inn {
    float: left;
    width: 100%;
    background: #F7C5D1;
    border-radius: 15px;
    margin: 135px 0px 0px;
}

.fot-ban-inn .lhs {
    float: left;
    width: 100%;
    padding: 50px 50px 70px 50px;
    text-align: center;
    position: relative;
}

.fot-ban-inn .lhs:before {
    content: '';
    position: absolute;
    background: url(../images/login-bg.png);
    width: 100%;
    height: 62px;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: 325px;
    animation: 4000s linear 0s infinite normal both running movehor;
}

.fot-ban-inn .lhs h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    /* color: #fff; */
}

.fot-ban-inn .lhs p {
    font-size: 15px;
    /* color: #fff; */
    line-height: 26px;
    padding: 5px 100px 15px;
    font-weight: 500;
}

.fot-ban-inn .lhs a {
    margin: 0px 10px 10px 0px;
}

/*-------------------------------------------------------*/
/* CHECK BOX & RADIO BUTTON
/*-------------------------------------------------------*/
.rbbox {
    position: relative;
}

.rbbox input[type="radio"] {
    opacity: 0;
}

.rbbox label {
    padding-left: 10px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: rgb(96, 30, 79);
    margin-bottom: 7px !important;
}

.rbbox label::before {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 1px;
    left: 0px;
    content: "";
    display: inline-block;
    border-radius: 50px;
    border: 1px solid rgb(226, 225, 225);
    background: rgb(255, 255, 255);
}

.rbbox input[type="radio"] {
    margin: 0px;
}

.rbbox input[type="radio"]:disabled+label {
    color: rgb(153, 153, 153);
}

.rbbox input[type="radio"]:disabled+label::before {
    background-color: rgb(251, 197, 45);
}

.rbbox input[type="radio"]:checked+label::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 4px;
    display: inline-block;
    font-size: 11px;
    width: 10px;
    height: 10px;
    background-color: rgb(0 0 0);
    border-radius: 50px;
}

.chbox {
    position: relative;
}

.chbox input[type="checkbox"] {
    opacity: 0;
}

.chbox label {
    padding-left: 10px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: rgb(88, 94, 107);
    line-height: 15px;
}

.chbox label::before {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 3px;
    left: 0px;
    content: "";
    display: inline-block;
    border-radius: 4px;
    border: 1px solid rgb(226, 225, 225);
    background: rgb(255, 255, 255);
}

.chbox input[type="checkbox"] {
    margin: 0px;
}

.chbox input[type="checkbox"]:disabled+label {
    color: rgb(153, 153, 153);
}

.chbox input[type="checkbox"]:disabled+label::before {
    background-color: rgb(251, 197, 45);
}

.chbox input[type="checkbox"]:checked+label::after {
    font-family: FontAwesome;
    content: "\f00c";
    position: absolute;
    top: 3px;
    left: 0px;
    display: inline-block;
    font-size: 15px;
    width: 18px;
    height: 18px;
    color: rgb(76, 175, 80);
    background-color: rgb(218, 255, 219);
    border-radius: 4px;
    padding: 2px;
    font-weight: 400;
}

/*-------------------------------------------------------*/
/* USER PROFILE
/*-------------------------------------------------------*/
.profi-pg {
    float: left;
    width: 100%;
    /* background: rgb(233, 238, 243); */
}

.profi-ban {
    margin-top: 26px;
    padding: 50px 0px 0px;
    position: relative;
}
.profile-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  
  .profile-image-container {
    margin-right: 20px;
    position: relative;
  }
  
  .edit-icon {
    position: absolute;
    top: 70px;
    right: 12px;
    background-color: rgb(107, 107, 107);
    border-radius: 50%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile-name h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
.profi-bio {
    float: left;
    width: 50%;
    margin-left: 50%;
    padding: 70px 70px 50px 70px;
    max-width: 900px;
    background: #fff;
}

.profi-ban .profile {
    width: 50%;
    float: left;
    position: fixed;
    left: 0;
    top: var(--mtop);
    bottom: 0;
    height: 100vh;
}

.profi-bio .lhs {
    float: left;
    width: 100%;
    /* background: rgb(255, 255, 255); */
}

.profi-bio .rhs {
    width: 100%;
}

.prof-rhs-help {
    background: url("../images/tell-us.jpg") 0% 0% / cover no-repeat;
    padding: 25px;
    border-radius: 10px;
    margin: 0 0 25px 0;
    position: relative;
    color: rgb(255, 255, 255);
    float: left;
    width: 100%;
}

.prof-rhs-help .inn {
    position: relative;
    text-align: center;
}

.prof-rhs-help::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.43);
    inset: 0px;
    border-radius: 10px;
}

.prof-rhs-help:hover a {
    color: rgb(255, 255, 255);
}

.prof-rhs-help h3 {
    font-size: 23px;
    color: #fff;
}

.prof-rhs-help p {
    font-size: 13px;
    color: rgb(255, 255, 255);
}

.prof-rhs-help a {
    color: rgb(255, 255, 255);
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 6px 15px;
    line-height: 20px;
    border-radius: 4px;
    letter-spacing: 0.5px;
    background: rgb(11, 41, 203);
}

.profi-bio .lhs .profile {
    position: relative;
    float: left;
    width: 100%;
}

.defa-prof-pg .lhs .profile {
    margin-top: 0px;
}

.pr-bio-info ul li {
    float: left;
    width: 50%;
    font-weight: 400;
    font-size: 14px;
    padding: 0px 8px 10px 24px;
    position: relative;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}

.pr-bio-info ul li::before {
    content: "\f105";
    font-family: "FontAwesome";
    position: absolute;
    width: 15px;
    height: 15px;
    color: #000;
    line-height: 11px;
    padding: 0px;
    border-radius: 25px;
    font-weight: 600;
    left: 0px;
    top: 4px;
    text-align: center;
    font-size: 16px;
}

.pr-bio-info ul li b {
    font-weight: 500;
    width: 145px;
    display: inline-block;
}

.jpro-ban-bg-img img {
    border-radius: 8px;
}

.pr-bio-c {
    float: left;
    width: 100%;
    padding-bottom: 40px;
    border-bottom: 1px solid rgb(233, 238, 243);
    margin-bottom: 50px;
}

.pr-bio-soc {
    padding-bottom: 10px;
    border-bottom: 0px solid rgb(233, 238, 243);
    padding-top: 0;
}

.pr-bio-c h3 {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.pr-bio-c h3+ul {
    padding-top: 7px;
}

.pr-bio-c p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}

.seninter {
    padding: 30px;
}

.seninter .lhs {
    float: left;
    width: 200px;
}

.seninter .lhs img {
    width: 100%;
    border-radius: 5px;
    height: 195px;
    object-fit: cover;
}

.seninter .rhs {
    float: left;
    width: calc(100% - 200px);
    padding-left: 35px;
}

.seninter .rhs h4 {
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 10px;
    line-height: 28px;
}

.seninter .rhs ul li {
    width: 50%;
    float: left;
}

.seninter .rhs textarea {
    height: 35px;
    font-size: 14px;
    font-weight: 500;
    float: left;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-right-style: initial;
    border-left-style: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-left-color: initial;
    border-image: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(223, 223, 223);
    outline: none;
    padding-left: 0px;
    resize: none;
    overflow: hidden;
}

.seninter .rhs textarea:focus {
    box-shadow: none;
}

.seninter-tit {
    font-size: 20px;
    font-weight: 500;
    padding: 0px 0px 0px 15px;
}

.seninter-tit span {
    font-weight: 700;
    color: rgb(219, 96, 8);
}

.pro-info-status {
    margin-bottom: 30px;
}

.pro-info-status span {
    font-size: 11px;
    color: rgb(255, 255, 255);
    background: rgb(91 188 113);
    font-weight: 400;
    border-radius: 3px;
    padding: 3px 8px;
}

.pro-info-status span b {
    font-weight: 500;
}

.pro-info-status .stat-1 {
    background: #f6af04;
}

.pro-info-status .stat-2 {}

.pg-pro-big-im .s1 {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.pg-pro-big-im .s1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pg-pro-big-im .s2 {
    float: left;
    width: 45%;
    padding: 0px 20px 0px 30px;
    color: rgb(255, 255, 255);
}

.pg-pro-big-im .s2 h1 {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 0px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pg-pro-big-im .s2 span {
    font-size: 14px;
    color: rgb(213, 212, 212);
    font-weight: 500;
    padding: 5px 0px;
    display: inline-block;
}

.pg-pro-big-im .s2 p {
    margin: 0px;
    font-size: 15px;
    font-weight: 600;
    color: rgb(213, 212, 212);
}

.pg-pro-big-im .s3 {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 74px;
}

.pg-pro-big-im .s3 .cta {
    transition: all 0.5s ease 0s;
    float: left;
    cursor: pointer;
    /* left: 30px; */
    /* bottom: 25px; */
    color: rgb(255, 255, 255);
    font-size: 16px;
    padding: 23px 15px;
    /* border-radius: 4px; */
    font-weight: 600;
    display: inline-block;
    background: rgb(225, 138, 9);
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    position: relative;
}

.pg-pro-big-im .s3 .cta:hover {
    background: rgb(85, 108, 120);
}

.pg-pro-big-im .s3 .cta.fol {
    background: rgb(42 9 225);
}

.pg-pro-big-im .s3 .cta.fol:hover {
    background: rgb(42, 137, 18);
}

.rel-pro {
    overflow: hidden;
    background: rgb(255, 255, 255);
    padding: 25px;
    margin-bottom: 5px;
    width: 100%;
    box-shadow: rgba(150, 150, 150, 0.29) 0px 7px 20px -5px;
    border-radius: 10px;
    position: sticky;
    top: 85px;
}

.rel-pro h4 {
    font-size: 15px;
    font-weight: 600;
}

.rel-pro ul {
    padding: 0px;
}

.rel-pro ul li {
    list-style-type: none;
    border-bottom: 1px solid rgb(239, 239, 239);
    position: relative;
    overflow: hidden;
    padding: 10px 0px;
    transition: all 0.5s ease 0s;
}

.rel-pro ul li:hover .rel-pro-con h5 {
    color: rgb(234, 5, 112);
}

.rel-pro ul li:last-child {
    padding-bottom: 0px;
    border-bottom: 0px;
}

.rel-pro-img {
    width: 50px;
    float: left;
}

.rel-pro-img img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.rel-pro-con {
    float: left;
    width: calc(100% - 50px);
    padding: 5px 0px 0px 8px;
}

.rel-pro-con h5 {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 500;
    transition: all 0.5s ease 0s;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    position: relative;
    margin: 0px;
}

.rel-pro-con span {
    font-weight: 500;
    font-size: 11px;
    color: rgb(89, 89, 93);
}
.edit-pro-parti{
    border-bottom: 4px solid rgb(0 0 0);
    padding-bottom: 40px;
    margin-bottom: 45px;
}
.edit-pro-parti:last-child{}

/*-------------------------------------------------------*/
/* PRICING PLAN
/*-------------------------------------------------------*/
.plans-ban {
    margin-top: var(--mtop);
    padding: 80px 0px 220px;
    text-align: center;
    color: rgb(255, 255, 255);
    background: linear-gradient(to right, rgb(137, 33, 107), rgb(218, 68, 83));
    overflow: hidden;
    float: left;
    width: 100%;
    position: relative;
}

.plans-ban::before,
.plans-ban::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    transition: all 0.5s ease;
    animation: zoomout 5s infinite linear both;
}

.plans-ban::before {
    left: -120px;
    top: -75px;
    width: 350px;
    height: 350px;
    background: rgb(158, 42, 101);
}

.plans-ban::after {
    right: -120px;
    bottom: -75px;
    width: 350px;
    height: 350px;
    background: rgb(218, 68, 83);
}

@keyframes zoomout {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(2);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
    }
}

.plans-ban .container {
    position: relative;
    z-index: 1;
}

.plans-ban span.pri {
    margin: 0px auto;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    color: #fff;
}

.plans-ban h1 {
    width: 100%;
    font-size: 52px;
    font-weight: 600;
    padding: 15px 0px;
    color: #fff;
}

.plans-ban p {
    width: 100%;
    font-weight: 300;
    font-size: 16px;
    color: #f3f3f3;
}

.plans-ban span.nocre {
    margin: 0px auto;
    background: rgb(219, 33, 76);
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 25px;
    color: #fff;
    width: auto;
}

.plans-main {
    float: left;
    width: 100%;
    padding: 0px 0px 110px;
    margin: -80px 0px 0px;
    position: relative;
}

.plans-main ul li {
    float: left;
    width: 33.3333%;
    padding: 0px 20px;
}
.plncanl-pop{}
.plncanl-pop h5{
    font-size: 16px;
    padding-bottom: 15px;
}
.plncanl-pop ul{}
.plncanl-pop .lhs-poli ul li{
    font-size: 14px;
    position: relative;
    padding: 0 0 10px 20px;
    color: #333;
}
.plncanl-pop .lhs-poli ul li:before{content: '';position: absolute;width: 6px;height: 6px;background: var(--bs-body-color);border-radius: 50px;left: 0;top: 7px;}
.plncanl-pop .lhs-poli{
    width: 40%;
    padding-right: 50px;
}
.plncanl-pop .rhs-form{
    width: 60%;
    border-left: 1px dashed #cdcdcd;
    padding-left: 50px;
}
.plncanl-pop .col-md-12{
    border-top: 1px dashed #cdcdcd;
    padding-top: 40px;
    margin-top: 40px;
    overflow: hidden;
    overflow-x: scroll;
}
.plncanl-pop table th{
    font-size: 13px;
    font-weight: 500;
}
.plncanl-pop table td{
    font-size: 13px;
}
.pri-box {
    color: rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    padding: 25px 30px;
    float: left;
    width: 100%;
    border: 1px solid rgb(237, 237, 237);
    border-radius: 35px;
    box-shadow: rgba(51, 51, 51, 0.05) 0px 1px 12px 0px;
    text-align: center;
}

.pri-box h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    padding-bottom: 5px;
}

.pri-box p {
    font-size: 14px;
    font-weight: 500;
}

.pri-box a.cta {
    display: block;
    background: rgb(255, 228, 230);
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 15px;
    font-weight: 600;
    padding: 12px;
    border-radius: 25px;
    margin: 25px 0px;
}

.pri-box .pri-cou {
    font-size: 26px;
    font-weight: 500;
    color: rgb(114, 114, 114);
}

.pri-box .pri-cou b {
    color: rgb(0, 0, 0);
    font-size: 32px;
}

.pri-box ol {
    float: left;
    width: 100%;
    padding-top: 20px;
}

.pri-box ol li {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 0px 12px 30px;
    color: rgb(0, 0, 0);
    position: relative;
    text-align: left;
}

.pri-box ol li:last-child {
    padding-bottom: 5px;
}

.pri-box ol li i {
    font-size: 12px;
    position: absolute;
    left: 0px;
    top: 1px;
    background: rgb(76, 175, 80);
    color: rgb(255, 255, 255);
    border-radius: 50px;
    padding: 6px 2px 5px 0px;
    width: 20px;
    height: 20px;
    text-align: center;
    font-weight: 200;
}

.pri-box ol li i.close {
    background: rgb(249, 120, 135);
    opacity: 1;
    padding: 5px 0px;
}

.pri-box-pop {
    margin-top: -70px;
}

.pri-box-pop .pop-pln {
    /* display: block; */
    background: rgb(255, 244, 187);
    text-align: center;
    color: rgb(156 132 9);
    font-size: 12px;
    font-weight: 600;
    padding: 7px 20px;
    border-radius: 25px;
    margin: 0 auto;
    margin-bottom: 39px;
    display: table;
}

.pri-box-pop a.cta {
    background: rgb(215, 67, 84);
    text-align: center;
    color: rgb(255, 255, 255);
}

.pri-box:hover a.cta {
    background: rgb(229, 135, 19);
    color: rgb(255, 255, 255);
    box-shadow: rgb(187, 110, 15) 0px 10px 14px -9px;
}

.ban-inn {
    background: #D3D3D3;
    padding: 40px 20px 110px 20px;
    float: left;
    width: 100%;
    margin-top: var(--mtop);
}

.ban-inn h2,
.ab-ban h1 {
    font-size: 80px;
    line-height: 100px;
    color: #76001C;
    margin-bottom: 20px;
    background: #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 800;
}

.ban-inn .ban-tit span {
    color: #000000;
}

.ban-inn .ban-tit p {
    color: #000000;
}
.founders-info {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .founders-title {
    color: #CD6900;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .founders-grid {
    display: flex;
    justify-content: space-around;
  }
  
  .founder, .co-founder {
    text-align: center;
  }
  
  .founder-name {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
  
  .founder-role {
    font-size: 16px;
    color: #666;
    font-style: italic;
  }
.about-us-section {
    padding: 80px 0;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .about-us-image {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;
  }
  
  .about-us-content h2 {
    color: #CD6900;
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .about-us-content h3 {
    font-size: 24px;
    font-style: italic;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-us-content p {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 30px;
  }
  
  .learn-more-btn {
    display: inline-block;
    padding: 12px 24px;
    background-color: #CD6900;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .learn-more-btn:hover {
    background-color: #A85500;
  }
  
  @media (max-width: 768px) {
    .about-us-content {
      padding-left: 0;
      padding-top: 40px;
    }
  }
  .ban-home {
      padding: 20px;
      position: relative;
  }
  
  .ban-home:before {
      content: none; /* This removes the pseudo-element on mobile */
  }
  @media (min-width: 758px) {
    .ban-home {
        padding: 110px 20px 115px 20px;
        position: relative;
        margin-top: 380px;
    }
    
    .ban-home:before {
        content:
            '';
        position: absolute;
        background: url('../images/icon/cp.png');
        width: 600px;
        height: 344px;
        background-size: cover;
        left: calc(45% - 220px);
        top: -344px;
    }
}


/*-------------------------------------------------------*/
/* ABOUT
/*-------------------------------------------------------*/
.ab-sec2 {
    z-index: 1;
    position: relative;
    float: left;
    width: 100%;
    padding-bottom: 120px;
}

.ab-sec2 ul {
    margin: -75px auto 0px;
    display: table;
    width: 80%;
}

.ab-sec2 ul li {
    width: 33.3333%;
    float: left;
    padding: 0px 20px;
}

.ab-sec2 ul li div {
    background: rgb(255, 255, 255);
    padding: 30px;
    box-shadow: rgba(51, 51, 51, 0.13) 0px 1px 14px -3px;
    border-radius: 5px;
    text-align: center;
    transition: all 0.5s ease;
}

.ab-sec2 ul li div:hover {
    box-shadow: 0px -2px 72px 0px rgb(0 0 0 / 27%);
    transform: scale(1.1);
}

.ab-sec2 ul li div img {
    width: 50px;
}

.ab-sec2 ul li div h4 {
    font-size: 16px;
    font-weight: 600;
    padding: 20px 0px 5px;
}

.ab-sec2 ul li div p {
    font-size: 13px;
    margin: 0px;
    font-weight: 500;
    line-height: 22px;
    /* color: rgb(51, 51, 51); */
}

.ab-wel {
    padding: 100px 0px;
}

.ab-wel-lhs{position: relative;}

.ab-wel-lhs span {
    position: absolute;
}
.ab-wel-1 {
    position: absolute;
    width: 75%;
    height: 550px;
    object-fit: cover;
    left: 0px;
    top: 0px;
    border-radius: 15px;
}

.ab-wel-2 {
    width: 80%;
    height: 300px;
    object-fit: cover;
    z-index: 1;
    position: relative;
    margin: 47% 10% 5% 15%;
    border-width: 15px 0px 0px 15px;
    border-top-style: solid;
    border-left-style: solid;
    border-top-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    border-image: initial;
    border-right-style: initial;
    border-right-color: initial;
    border-bottom-style: initial;
    border-bottom-color: initial;
    border-radius: 0px 100px 15px;
}

.ab-wel-3 {
    width: 100px;
    height: 100px;
    border: 7px solid rgb(240, 168, 5);
    border-radius: 50%;
    left: -39px;
    top: -32px;
    z-index: 0;
}

.ab-wel-4 {
    width: 200px;
    height: 200px;
    border: 7px solid rgb(255, 226, 240);
    border-radius: 20px;
    right: 9px;
    bottom: -4px;
}

.ab-wel-rhs {
    float: left;
    width: 100%;
    padding-left: 25px;
}

.ab-wel-rhs p {
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
}

.ab-wel-tit h2 {
    font-size: 58px;
    font-weight: 700;
    padding-bottom: 10px;
    color: #66451c;
    line-height: 44px;
    font-family: 'Cinzel Decorative', cursive;
}

.ab-wel-tit h2 em {
    display: block;
    color: #e5026b;
    font-weight: 700;
    font-size: 35px;
    font-family: var(--tit-font);
    font-family: 'Cinzel Decorative', cursive;
    line-height: 78px;
}

.ab-wel-tit p a {
    color: #ff0e88;
    font-weight: 600;
    font-size: 16px;
}

.ab-wel-tit-1 {
    border-top: 1px solid rgb(217, 217, 217);
    margin-top: 25px;
    padding-top: 25px;
}

.ab-wel-tit-2 {
    float: left;
    width: 100%;
    padding-top: 30px;
}

.ab-wel-tit-2 ul li {
    float: left;
    width: 50%;
}

.ab-wel-tit-2 ul li div {
    position: relative;
}

.ab-wel-tit-2 ul li div i {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 42px;
    height: 42px;
    background: #000;
    border-radius: 50%;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 9px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 8px;
    font-size: 18px;
    line-height: 25px;
}

.ab-wel-tit-2 ul li div h4 {
    padding: 0px 0px 0px 60px;
    font-size: 15px;
    color: rgb(122, 122, 122);
}

.ab-wel-tit-2 ul li div h4 em {
    display: block;
    font-size: 18px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    padding-top: 5px;
}

.ab-test-rat {
    background: rgb(255, 255, 255);
    padding: 30px;
    border-radius: 5px;
    float: left;
    width: 100%;
    margin-bottom: 30px;
    box-shadow: 0 2px 7px -1px #625c472b;
    margin-top: 5px;
}

.ab-test-star {
    float: left;
    width: 100%;
    padding-bottom: 20px;
}

.ab-test-star i {
    color: #ff961b;
    font-size: 18px;
    float: left;
    margin-right: 3px;
}

.ab-test-star span {
    font-size: 13px;
    font-weight: 500;
    color: rgb(155, 149, 149);
    float: left;
    padding-left: 8px;
}

.ab-test-conte {
    float: left;
    width: 100%;
}

.ab-test-conte p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: rgb(116, 121, 129);
}

.ab-rat-user {
    position: relative;
    float: left;
    width: 100%;
    padding-left: 30px;
}

.ab-rat-user img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    position: absolute;
    left: 30px;
    top: 0px;
    object-fit: cover;
}

.ab-rat-user div {
    padding-left: 55px;
}

.ab-rat-user div h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
}

.ab-rat-user div span {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
}

.abo-partners {
    position: relative;
    padding-top: 140px;
}

.abo-partners .home-tit h2 span {
    font-size: 38px;
    text-transform: uppercase;
}

.abo-partners .carousel {
    width: 100%;
}

.wedd-shap span {
    position: absolute;
}

.abo-shap-1 {
    width: 30px;
    height: 30px;
    background: rgb(205, 222, 252);
    border-radius: 50%;
    left: 6%;
    top: 29%;
}

.abo-shap-2 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    right: 12%;
    top: 80%;
    border: 5px solid rgb(218, 221, 223);
}

.abo-shap-3 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    right: 4%;
    top: 9%;
    border: 5px solid rgb(252, 205, 205);
    z-index: -1;
}

.abo-shap-4 {
    width: 37px;
    height: 87px;
    background: url("../images/icon/dot1.png");
    right: 5%;
    top: 5%;
    animation: 5s linear 0s infinite normal both running updown1;
}

.abo-shap-5 {
    width: 37px;
    height: 87px;
    background: url("../images/icon/dot1.png");
    left: 5%;
    bottom: 5%;
    transform: rotate(90deg);
    filter: hue-rotate(45deg);
    animation: 10s linear 0s infinite normal both running ltor;
}

.abo-shap-6 {
    width: 37px;
    height: 87px;
    background: url("../images/icon/dot1.png");
    left: 5%;
    bottom: 5%;
    filter: hue-rotate(445deg);
    animation: 10s linear 0s infinite normal both running updown;
}

@keyframes updown {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-40px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes updown1 {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-30px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(30px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes ltor {
    0% {
        transform: translateX(0px) rotate(90deg);
    }

    25% {
        transform: translateX(-30px) rotate(90deg);
    }

    50% {
        transform: translateX(0px) rotate(90deg);
    }

    75% {
        transform: translateX(30px) rotate(90deg);
    }

    100% {
        transform: translateX(0px) rotate(90deg);
    }
}

/*-------------------------------------------------------*/
/* TEAM PROFILES
/*-------------------------------------------------------*/
.ab-team {
    float: left;
    width: 100%;
    padding: 80px 0px;
}

.pg-abo-ab-team {
    padding: 0 0 100px 0;
}

.ab-team ul li {
    float: left;
    width: 25%;
    cursor: pointer;
}

.ab-team ul li div {
    /* padding: 10px 10px 15px; */
    text-align: center;
    background: #fffcf0;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid #f3eedc;
    transition: all 0.5s ease;
    position: relative;
    display: table;
    padding-bottom: 15px;
    overflow: hidden;
}

.ab-team ul li div * {
    transition: all 0.5s ease;
}

.ab-team ul li div:hover {
    box-shadow: 0 12px 41px 0px #3333338c;
    /* transform: scale(1.1); */
    z-index: 1;
    background: #125243;
}

.ab-team ul li div:hover h4 {
    top: -50px;
    color: #f3c200;
}

.ab-team ul li div:hover p {
    top: -50px;
    color: #cbcac6;
}

.ab-team ul li div:hover img {
    transform: scale(1.1);
}

.ab-team ul li div:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 160px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border-radius: 50px 50px 5px 5px;
    transition: all 0.4s ease-in-out 0s;
    background: linear-gradient(to top, rgb(38 23 3), rgb(52 20 45 / 37%));
    transform: scale(0);
    transform-origin: right bottom;
    z-index: 1;
}

.ab-team ul li div:hover:before {
    transform: scale(1);
}

.ab-team ul li div img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}

.ab-team ul li div h4 {
    font-size: 20px;
    font-weight: 600;
    color: #584d22;
    line-height: 32px;
    padding-top: 20px;
    margin-bottom: 5px;
    position: relative;
    top: 0;
    z-index: 1;
}

.ab-team ul li div p {
    font-size: 13px;
    color: #584d22;
    margin: 0px;
    font-weight: 400;
    position: relative;
    top: 0;
    z-index: 1;
}

.ab-team .social-light {
    position: absolute;
    left: 0;
    z-index: 2;
    right: 0;
}

.ab-team .social-light li {
    opacity: 0;
    transform: translateY(10px);
}

.ab-team ul li div:hover .social-light {
    margin-top: -47px;
}

.ab-team ul li div:hover .social-light li {
    opacity: 1;
    transform: translateY(0px);
}

.ab-team ul li div:hover .social-light li:nth-child(1) {
    transition-delay: 0.1s;
}

.ab-team ul li div:hover .social-light li:nth-child(2) {
    transition-delay: 0.2s;
}

.ab-team ul li div:hover .social-light li:nth-child(3) {
    transition-delay: 0.3s;
}

.ab-team ul li div:hover .social-light li:nth-child(4) {
    transition-delay: 0.4s;
}

.ab-team ul li div:hover .social-light li:nth-child(5) {
    transition-delay: 0.5s;
}

.ab-team ul li div:hover .social-light li:nth-child(6) {
    transition-delay: 0.6s;
}

.ab-team ul li div:hover .social-light li a i {
    color: #cdc5a8;
}

.ab-team ul li div .social-light li a i:hover {
    color: #fff;
}

.social-light {
    margin: 0 auto;
    display: table;
    padding-top: 10px;
}

.social-light li {
    width: auto !important;
    margin: 0 2px;
}

.social-light li a {
    display: inline-block;
}

.social-light li a i {
    width: 38px;
    height: 38px;
    /* border: 1px solid #ddd6d6; */
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    font-size: 18px;
    /* padding: 11px 8px; */
    transition: all 0.5s ease;
    line-height: 36px;
}

/*-------------------------------------------------------*/
/* FAQ
/*-------------------------------------------------------*/
.ab-faq {
    float: left;
    width: 100%;
    padding: 40px 0px 120px 0px;
    position: relative;
}

.ab-faq .abo-shap-1 {
    width: 70px;
    height: 70px;
    background: rgb(205, 252, 239);
}

.ab-faq .abo-shap-3 {
    right: 95%;
    top: 90%;
    border: 5px solid rgb(223, 205, 252);
}

.ab-faq-lhs .card {
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-right-style: initial;
    border-left-style: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-left-color: initial;
    border-image: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(234, 238, 241);
    border-radius: 0px;
    padding: 20px;
    margin-bottom: 10px;
}

.ab-faq-lhs .card-header {
    background: rgb(255, 255, 255);
    border: 0px;
}

.ab-faq-lhs .card-header a {
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: 20px;
    padding-right: 20px;
    display: block;
}

.ab-faq-lhs .card-header a::after {
    content: "-";
    position: absolute;
    font-size: 40px;
    color: rgb(30, 31, 48);
    font-weight: 200;
    right: 30px;
    top: 31px;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    line-height: 31px;
    text-align: center;
    transition: all 0.4s ease 0s;
}

.ab-faq-lhs .card-header a.collapsed::after {
    content: "+";
}

.ab-faq-lhs .card-body p {
    margin: 0px;
    font-size: 15px;
    line-height: 26px;
}

.ab-faq-rhs {
    margin-left: 25px;
    position: relative;
}

.ab-faq-rhs::before {
    position: absolute;
    left: 3%;
    top: 3%;
    width: 94%;
    height: 94%;
    content: "";
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 5px;
}

.ab-faq-rhs img {
    width: 100%;
    border-radius: 5px;
}

.sub-tit {
    float: left;
    width: 100%;
    text-align: center;
    padding-bottom: 45px;
}

.sub-tit p {
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.sub-tit h2 span {
    font-weight: 600;
    color: rgb(255, 128, 60);
    font-family: Playball, cursive;
    font-size: 46px;
    text-transform: capitalize;
}

.sub-tit p {
    font-size: 15px;
    font-weight: 500;
    display: block;
    margin-bottom: 0px;
    color: rgb(103, 110, 111);
}

/*-------------------------------------------------------*/
/* CHAT BOX
/*-------------------------------------------------------*/
.chatbox {
    transition: all 0.5s ease-in-out 0s;
    background: rgb(255, 255, 255);
    padding: 15px;
    box-shadow: rgba(115, 115, 115, 0.37) 0px 1px 11px -2px;
    position: fixed;
    bottom: 10px;
    right: -500px;
    border-radius: 10px;
    width: 90%;
    max-width: 380px;
    height: 80%;
    max-height: 450px;
    z-index: 99;
    overflow: hidden;
}

.chatbox.open {
    right: 20px;
}

.chatbox.msg-sho-act {
    right: 5px;
}

.chatbox .s1,
.chatbox .s2,
.chatbox .s3 {
    float: left;
    width: 100%;
}

.chatbox .s1 {
    padding-bottom: 15px;
}

.chatbox .s1 img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: cover;
    float: left;
}

.chatbox .s1 h4 {
    font-size: 14px;
    float: left;
    padding: 1px 0px 0px 12px;
    color: rgb(150, 150, 150);
    margin: 0px;
}

.chatbox .s1 h4 b {
    display: block;
    color: rgb(0, 0, 0);
    font-size: 16px;
    padding-bottom: 4px;
    font-weight: 500;
}

.chatbox .s1 .avlsta {
    position: absolute;
    left: 70px;
    top: 37px;
    font-size: 9px;
    background: rgb(143, 163, 181);
    border-radius: 3px;
    color: rgb(255, 255, 255);
    padding: 1px 3px;
    font-weight: 500;
}

.chatbox .s1 .avilyes {
    background: rgb(76, 175, 80);
}

.chatbox .s1 .avilno {
    background: rgb(143, 163, 181);
}

.chatbox .s2 {
    background: rgb(243, 246, 250);
    padding: 32px 15px 140px;
    position: absolute;
    left: 0px;
    top: 70px;
    height: 78%;
    bottom: 0px;
    overflow-y: auto;
    border-top: 1px solid rgb(234, 236, 240);
}

.chatbox .s2 .chat-con {
    float: left;
    width: 100%;
    display: block;
    margin-bottom: 12px;
}

.chatbox .s2 .chat-lhs,
.chatbox .s2 .chat-rhs {
    font-size: 13px;
    font-weight: 400;
    background: rgb(255, 255, 255);
    padding: 6px 10px;
    border-radius: 5px;
    line-height: 19px;
    box-shadow: rgba(25, 27, 56, 0.12) 0px 1px 4px -1px;
    position: relative;
}

.chatbox .s2 .chat-lhs::before,
.chatbox .s2 .chat-rhs::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(25, 27, 56, 0.12) 0px 1px 2px -1px;
    top: 7px;
    transform: rotateZ(45deg);
}

.chatbox .s2 .chat-lhs::before {
    left: -2px;
}

.chatbox .s2 .chat-rhs::before {
    right: -2px;
    background: rgb(23, 149, 249);
}

.chatbox .s2 .chat-lhs {
    float: left;
}

.chatbox .s2 .chat-rhs {
    float: right;
    background: rgb(23, 149, 249);
    color: rgb(255, 255, 255);
}

.chatbox .s2 .chat-wel {
    background: rgb(220, 231, 255);
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    font-size: 11px;
    padding: 2px;
    color: rgb(51, 51, 51);
    font-weight: 500;
}

.chatbox .s3 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: rgb(217, 223, 231);
    padding: 15px;
}

.chatbox .s3 input {
    resize: none;
    height: 52px;
    padding: 10px;
    font-size: 13px;
    line-height: 20px;
    color: rgb(51, 51, 51);
    border-radius: 5px 0px 0px 5px;
    border: 0px;
    width: 270px;
    float: left;
    font-weight: 500;
}

.chatbox .s3 button {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 600;
    padding: 6px 14px;
    border-radius: 0px 5px 5px 0px;
    text-transform: uppercase;
    background: rgb(33, 150, 243);
    border: 0px;
    width: 80px;
    float: left;
    height: 44px;
}

.chatbox .s3 button i {
    font-size: 13px;
    font-weight: 500;
    vertical-align: bottom;
    line-height: 18px;
}

.chatbox .s3 input {
    height: 45px;
    position: relative;
    padding: 15px 24px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid rgb(232, 232, 232);
    text-indent: 0px;
    line-height: 12px;
    transition: border-color 0.4s ease 0s, color 0.4s ease 0s;
    width: calc(100% - 80px);
    font-size: 14px;
    background: rgb(255, 255, 255);
    float: left;
    font-weight: 500;
}

.comm-msg-pop-clo {
    position: absolute;
    right: 10px;
    top: 10px;
}

.comm-msg-pop-clo i {
    color: rgb(155, 158, 163);
    font-size: 20px;
    cursor: pointer;
}


/*-------------------------------------------------------*/
/* COMMON TITLE
/*-------------------------------------------------------*/
.home-tit-1 {
    width: 100%;
}

.home-tit-1 h2 {
    color: rgb(255, 243, 224);
    font-size: 75px;
    font-weight: 600;
    margin-bottom: 40px;
}

.home-tit-1 h2 span {
    display: block;
    font-size: 60px;
    color: #ffbe5c;
}

.home-tit-1 a {
    border: 1px solid rgb(243, 200, 128);
    color: rgb(243, 200, 128);
    display: inline-block;
    font-size: 24px;
    padding: 15px 50px;
    font-weight: 500;
    font-family: var(--tit-font);
}

.hom-couples-all {
    float: left;
    width: 100%;
    position: relative;
    padding-top: 30px;
}

.hom-coup-test {}

.hom-coup-test ul {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.hom-coup-test ul li {
    float: left;
    width: 25%;
}

.hom-coup-box {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.hom-coup-box::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.48);
    inset: 0px;
    transition: all 0.4s ease-in-out 0s;
    transform: scale(0);
    z-index: 1;
}

.hom-coup-box:hover::before {
    transform: scale(1);
}

.hom-coup-box:hover .bx {
    transform: translateY(0px);
    opacity: 1;
}

.hom-coup-box img {
    float: left;
    width: 100%;
    height: 600px;
    object-fit: cover;
    transition: all 0.4s ease-in-out 0s;
    z-index: 0;
}

.hom-coup-box:hover img {
    transform: scale(1.2);
}

.hom-coup-box .bx {
    position: absolute;
    top: 42%;
    transition: all 0.4s ease-in-out 0s;
    transform: translateY(30px);
    opacity: 0;
    z-index: 1;
    width: 100%;
    text-align: center;
}

.hom-coup-box h4 {
    color: rgb(255, 255, 255);
    text-align: center;
    width: 100%;
    font-size: 34px;
    font-family: var(--tit-font);
    margin-bottom: 35px;
}

.hom-coup-box h4 span {
    font-family: var(--tit-font);
    font-size: 15px;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-top: 10px;
    color: #fff;
}

.hom-coup-box .leaf {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 42%;
    transform: translateY(15px) scale(0);
    transition: all 0.4s ease-in-out 0s;
    opacity: 0.6;
    z-index: 2;
}

.hom-coup-box:hover .leaf {
    transform: translateY(0px) scale(1);
}

.hom-coup-box .leaf::before,
.hom-coup-box .leaf::after {
    content: "";
    position: absolute;
    width: 150px;
    height: 184px;
    background: url("../images/leaf/4.png") 0% 0% / 100% no-repeat;
    top: -71px;
    transition: all 0.4s ease-in-out 0s;
}

.hom-coup-box .leaf::after {
    margin-right: -6px;
    transform: scaleX(-1);
}

.hom-coup-box .leaf::before {
    margin-left: -161px;
}

.hom-coup-box .sml-cta {
    transition: all 0.4s ease-in-out 0s;
    background: #b57f3e;
}

.hom-coup-box:hover .sml-cta {}

.hom-cus-revi {
    padding-top: 50px;
    float: left;
    width: 100%;
    margin-bottom: 120px;
    position: relative;
}

/*-------------------------------------------------------*/
/* CUSTOMER REVIEWS
/*-------------------------------------------------------*/
.cus-revi {
    float: left;
    width: 100%;
    margin: 10px 0px 50px;
    position: relative;
    overflow: hidden;
    padding: 90px 10px 5px;
}

.cus-revi ul {}

.cus-revi ul li {
    float: left;
    width: 33.333%;
}

.cus-revi-box {
    border: 1px solid rgb(219, 204, 187);
    padding: 90px 35px 30px;
    border-radius: 10px;
    position: relative;
    margin: 0px 15px;
}

.cus-revi-box .revi-im {
    position: absolute;
    top: -65px;
}

.cus-revi-box .revi-im img {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 10px;
    clip-path: polygon(50% 0px, 100% 50%, 50% 100%, 0px 50%);
}

.cus-revi-box .revi-im i {
    position: absolute;
    width: 12px;
    height: 12px;
    transition: all 0.5s ease 0s;
}

.cus-revi-box .revi-im i.cir-1 {
    border: 3px solid rgb(253, 134, 134);
    left: -5px;
    top: 1px;
}

.cus-revi-box .revi-im i.cir-2 {
    border: 3px solid rgb(189, 204, 255);
    left: 114px;
    top: 12px;
}

.cus-revi-box .revi-im i.cir-3 {
    border: 3px solid rgb(255, 198, 0);
    left: -17px;
    top: 86px;
}

.cus-revi-box p {
    font-size: 15px;
    line-height: 23px;
}

.cus-revi-box h5 {
    font-weight: 600;
    font-size: 18px;
    padding: 15px 0px 0px;
    font-family: 'Cinzel Decorative', cursive;
}

.cus-revi-box span {
    font-size: 16px;
}

.slick-arrow {
    width: 72px;
    height: 72px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.58);
    border: 1px solid rgba(255, 255, 255, 0.55);
    color: rgba(255, 255, 255, 0.01);
    position: absolute;
    z-index: 2;
    top: 62%;
    text-align: center;
    outline: none !important;
}

.slick-prev {
    left: 14px;
}

.slick-next {
    right: 14px;
}

.slick-arrow::before,
.slick-arrow::after {
    font-size: 40px;
    font-family: FontAwesome;
    text-align: center;
    display: inline-block;
    padding: 4px;
    color: rgb(255, 255, 255);
    position: relative;
}

.slick-arrow::before {
    content: "\f104";
}

.slick-next::before {
    content: "\f105";
    left: 21px;
}


/*-------------------------------------------------------*/
/* CTA & BUTTONS
/*-------------------------------------------------------*/
.cta-full-wid {
    width: 100%;
    text-align: center;
}

.cta-dark {
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 13px;
    font-weight: 600;
    padding: 13px 30px;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
}

.cta-dark-sml {
    font-size: 10px;
    padding: 6px 14px;
}

.cta-dark:hover {
    color: #fff;
    box-shadow: 0 15px 19px -8px #00000087;
}

.cta-line-ani {
    position: relative;
    display: inline-block;
    transition: all .4s ease;
    margin-left: -30px;
    margin-top: 10px;
}

.cta-line-ani:hover {
    margin-left: 0px;
}

.cta-line-ani:before,
.cta-line-ani:after,
.cta-line-ani a:before,
.cta-line-ani a:after {
    content: '';
    position: absolute;
    background-color: #66451c;
    transition: transform .3s
}

.cta-line-ani:before {
    top: -4px;
    left: 0;
    width: 1px;
    height: calc(100% + 11px);
    transform: scaleY(0);
    transform-origin: top left
}

.cta-line-ani:after {
    bottom: -7px;
    right: 0;
    width: 1px;
    height: calc(100% + 11px);
    transform: scaleY(0);
    transform-origin: bottom right
}

.cta-line-ani a:before {
    top: -4px;
    left: 0;
    width: 100%;
    height: 1px;
    transform: scaleX(0);
    transform-origin: top left
}

.cta-line-ani a:after {
    bottom: -7px;
    right: 0;
    width: 100%;
    height: 1px;
    transform: scaleX(0);
    transform-origin: bottom right
}

.cta-line-ani:hover:before,
.cta-line-ani:hover:after,
.cta-line-ani:hover a:before,
.cta-line-ani:hover a:after {
    transform: scaleY(1);
}

.hom-cus-revi .slick-arrow {
    width: 50px;
    height: 50px;
    top: 54%;
    background: rgba(255, 153, 0, 0.52);
    border: 1px solid rgba(255, 255, 255, 0.55);
    color: rgba(255, 255, 255, 0.01);
}

.hom-cus-revi .slick-prev {
    left: 0px;
}

.hom-cus-revi .slick-next {
    right: 0px;
}

.hom-cus-revi .slick-arrow::before,
.hom-cus-revi .slick-arrow::after {
    font-size: 26px;
}

.hom-cus-revi .slick-next::before {
    left: 3px;
}

/*-------------------------------------------------------*/
/* ABOUT
/*-------------------------------------------------------*/
.ab-cont {
    padding-bottom: 0;
}

.ab-cont ul {
    float: left;
    width: 100%;
}

.ab-cont ul li {
    float: left;
    width: 25%;
}

.ab-cont ul li:last-child .ab-cont-po {
    border-right: 0;
}

.ab-cont ul li .ab-cont-po {
    position: relative;
    border: 1px solid #d7d1be;
    border-left: 0;
    padding: 20px;
}

.ab-cont ul li .ab-cont-po i {
    font-size: 20px;
    position: absolute;
    left: 25px;
    top: 25px;
    color: #66451c;
    border: 1px solid #b79b79;
    padding: 8px 0px;
    border-radius: 10px;
    vertical-align: middle;
    width: 38px;
    height: 38px;
    text-align: center;
    transition: all 0.4s ease;
}

.ab-cont ul li .ab-cont-po:hover i {
    color: #e5026b;
    border: 1px solid #e5026b;
}

.ab-cont ul li .ab-cont-po:hover i:before {
    animation: shake 150ms 3 linear;
    -moz-animation: shake 150ms 3 linear;
    -webkit-animation: shake 150ms 3 linear;
    -o-animation: shake 150ms 3 linear;
    display: inline-block;
}

@keyframes shake {
    0% {
        transform: translate(3px, 0);
    }

    50% {
        transform: translate(-3px, 0);
    }

    100% {
        transform: translate(0, 0);
    }
}

@-moz-keyframes shake {
    0% {
        -moz-transform: translate(3px, 0);
    }

    50% {
        -moz-transform: translate(-3px, 0);
    }

    100% {
        -moz-transform: translate(0, 0);
    }
}

@-webkit-keyframes shake {
    0% {
        -webkit-transform: translate(3px, 0);
    }

    50% {
        -webkit-transform: translate(-3px, 0);
    }

    100% {
        -webkit-transform: translate(0, 0);
    }
}

@-ms-keyframes shake {
    0% {
        -ms-transform: translate(3px, 0);
    }

    50% {
        -ms-transform: translate(-3px, 0);
    }

    100% {
        -ms-transform: translate(0, 0);
    }
}

@-o-keyframes shake {
    0% {
        -o-transform: translate(3px, 0);
    }

    50% {
        -o-transform: translate(-3px, 0);
    }

    100% {
        -o-transform: translate(0, 0);
    }
}

.ab-cont ul li .ab-cont-po div {
    padding: 0 0 0 55px;
}

.ab-cont ul li .ab-cont-po div h4 {
    font-weight: 700;
    font-size: 40px;
    /* color: #00306e; */
    font-family: 'Cinzel Decorative', cursive;
}

.ab-cont ul li .ab-cont-po div span {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
}

/*WEDDING BANNER*/
.m-tp {
    margin-top: var(--mtop);
}

.wedd {
    padding: 60px 0 90px 0;
    position: relative;
}

.hom-top:before,
.pg-soon:before {
    content: '';
    position: fixed;
    top: 125px;
    left: 0;
    width: 188px;
    height: 188px;
    background: url('../images/leaf/5.png') no-repeat;
    background-size: 300px;
    background-position: -78px -70px;
}

.hom-top:after,
.pg-soon:after {
    content: '';
    position: fixed;
    bottom: 0;
    right: 0;
    width: 123px;
    height: 300px;
    background: url('../images/leaf/8.png') no-repeat;
    background-size: 121px;
    background-position: center right;
}

.ban-wedd {
    width: 100%;
    background: #ffe6df;
    text-align: center;
    padding: 70px 50px 150px 50px;
    position: relative;
    border: 15px solid #ffd9d9;
    border-radius: 30px;
    z-index: 1;
}

.ban-wedd h2 {
    font-size: 48px;
    font-weight: 700;
}

.ban-wedd p {
    padding-bottom: 15px;
}

/*-------------------------------------------------------*/
/* WEDDING VIDEO
/*-------------------------------------------------------*/
.wedd-ban-leaf span {
    position: absolute;
}

.wedd-leaf-1 {
    background: url(../images/leaf/1.png) no-repeat;
    width: 150px;
    height: 75px;
    background-size: 100%;
    left: -22px;
    top: -15px;
    transform: rotate(-41deg);
}

.wedd-leaf-2 {
    background: url(../images/leaf/2.png) no-repeat;
    width: 55px;
    height: 175px;
    background-size: 100%;
    right: 5px;
    top: -60px;
    transform: scaleX(-1) rotate(30deg);
}

.wedd-frame {
    width: 460px;
    height: 644px;
    padding: 32px;
    margin: 0 auto;
    display: table;
    position: relative;
    left: 33px;
    margin-bottom: -427px;
}

.wedd-frame:before {
    content:
        '';
    position: absolute;
    width: 460px;
    height: 644px;
    background: url(../images/leaf/9.png) no-repeat;
    background-size: cover;
    background-position: 4px 0px;
    left: 0;
    top: 0;
    opacity: 0.9;
}

.wedd-frame img {
    width: 290px;
    height: 462px;
    object-fit: cover;
    margin-left: -68px;
    margin-top: 40px;
    border-radius: 20px;
}

.wedd-vid {
    margin-bottom: -250px;
    margin-top: 50px;
    position: relative;
}

.wedd-vid iframe {
    display: none
}

.wedd-vid img,
.wedd-vid iframe {
    width: 85%;
    border: 14px solid #ffe6df;
    border-radius: 25px;
    object-fit: cover;
    height: 500px;
    box-shadow: 0 1px 30px -10px #3333336b;
}

.pg-wedd-vid {
    background: #ffe6df;
}

.pg-wedd-vid:before {
    top: initial;
    bottom: 0px;
    transform: rotate(180deg);
}

.pg-wedd-vid .ban-wedd {
    border: 0;
    padding: 20px 50px 0 50px;
}

.wedd-vid-dat.wedd-dat {
    padding: 300px 0 50px 0;
}

.wedd-vid-foot.foot-box {
    margin: 110px 0 110px 0;
}

.vid-play {
    position: absolute;
    width: 100px;
    height: 100px;
    background: #f30a76;
    border-radius: 50px;
    cursor: pointer;
    left: calc(50% - 26px);
    top: 40%;
    box-shadow: 0 1px 30px 4px #333333;
    border: 5px solid #ffffff;
    transition: all .5s ease;
    text-align: center;
}

.vid-play i {
    color: #fff;
    font-size: 32px;
    padding: 28px 36px;
}

.vid-play:hover {
    background: #f6af04;
    transform: scale(1.2);
}
.wedd-vid-tree span {
    position: absolute;
    transition: all 0.5s ease-in-out;
}

.wedd-vid-tre-1 {
    left: -103px;
    bottom: -93px;
    background: url(../images/leaf/10.png) no-repeat;
    background-size: cover;
    width: 231px;
    height: 345px;
    transform: translateX(0px);
}

.wedd-vid-tre-2 {
    right: -35px;
    bottom: -104px;
    background: url(../images/leaf/11.png) no-repeat;
    background-size: cover;
    width: 175px;
    height: 175px;
    transform: translateX(0px);
}

.wedd-deco .pho-frame {
    width: 235px;
    position: absolute;
    border: 8px solid #fff;
    border-bottom: 3px solid #fff;
    transform: rotate(0deg) scale(3);
    bottom: 0;
    left: 50px;
    background: #fff;
    overflow: hidden;
    transition: all 0.4s ease;
}

.wedd-deco .pho-frame img {
    width: 100%;
    height: 165px;
    object-fit: cover;
    margin-bottom: 3px;
    transform: scale(1);
    transition: all 0.5s ease;
    animation: 10s linear 0s infinite normal both running imgzoom;
}

@keyframes imgzoom {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(2);
    }

    100% {
        transform: scale(1);
    }
}

.wedd-deco .pho-frame span {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    font-family: var(--tit-font);
    position: absolute;
    z-index: 5;
    left: -2px;
    bottom: -2px;
    background: #fff;
    padding: 3px 8px;
}

.wedd-deco .pho-frame1 {
    bottom: -13px;
    left: initial;
    right: 0;
    transform: rotate(0deg) scale(3);
    width: 210px;
}

.wedd-deco .pho-frame1 img {
    height: 150px;
    animation: 10s linear 2s infinite normal both running imgzoom;
}

.anistart .pho-frame {}

.anistart .pho-frame1 {
    transform: rotate(-6deg) scale(1);
    transition-delay: 0.6s;
}

.anistart .pho-frame2 {
    transform: rotate(7deg) scale(1);
    transition-delay: 0.3s;
}

.wedd-info {
    padding-top: 30px;
}

.wedd-info ul li {
    display: inline-block;
    margin: 0 12px;
}

.wedd-info ul li i {
    margin-right: 12px;
    border: 1px solid rgb(219, 204, 187);
    padding: 10px;
    border-radius: 10px;
    vertical-align: initial;
    width: 38px;
    height: 38px;
}

.wedd-info ul li span {
    font-weight: 500;
}

.wedd-info ul li a {
    font-weight: 500;
    text-decoration: underline;
}

.wedd-info ul li a,
.wedd-info ul li span {
    font-size: 20px;
}

.wedd-dat {
    padding: 130px 0 70px 0;
}

.save-txt h4 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-family: var(--tit-font);
}

.save-txt h2 {
    font-family: var(--tit-font);
    font-size: 110px;
    font-weight: 500;
}

.save-im .inn {
    width: 33.333%;
    float: left;
    padding: 0 10px;
}

.save-im img {
    width: 100%;
    height: 450px;
    border-radius: 100px;
    object-fit: cover;
}

.save-im .desc {
    text-align: center;
    position: relative;
    padding-top: 75px;
}

.save-im .desc::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 65px;
    background: #c7ae8e;
    top: 10px;
    left: 49%;
}

.save-im .desc span {
    font-family: var(--tit-font);
}

.save-im .desc h4 {
    font-family: var(--tit-font);
}

/*-------------------------------------------------------*/
/* WEDDING GALLERY
/*-------------------------------------------------------*/
.wedd-gall {
    margin-bottom: 90px;
    float: left;
    width: 100%;
}

.wedd-gall-pg {
    margin: 100px 0 80px 0;
}

.wedd-gall-pg-v1 {
    width: 100%;
    float: left;
    margin: 100px 0 90px 0;
}

.wedd-gall-pg-v1 .img-wrapper img {
    height: 350px;
}

.gal-im {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
}

.gall-inn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.gal-im:hover {}

.gal-im:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #633214cc, transparent);
    transition: all 0.5s ease;
    opacity: 0;
    z-index: 1;
}

.gal-im img {
    width: 100%;
    transition: all 0.5s ease;
    object-fit: cover;
}

.gal-im img.gal-siz-1 {
    height: 300px;
}

.gal-im img.gal-siz-2 {
    height: 45vh;
}

.gal-im:hover img {
    filter: grayscale(0);
    transform: scale(1.2);
}

.gal-im .txt {
    padding: 30px 30px 35px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    /* transition: all 0.5s ease; */
    z-index: 1;
}

.gal-im .txt:before {
    content: '';
    position: absolute;
    bottom: 22px;
    left: 0;
    right: 0;
    width: 0%;
    height: 1px;
    background: #ffffff6b;
    transition: all 0.5s ease;
}

.gal-im .txt span {
    text-transform: uppercase;
    font-family: var(--tit-font);
    font-size: 12px;
    letter-spacing: 2px;
    transition: all 0.5s ease;
    position: relative;
    left: -30px;
    opacity: 0;
}

.gal-im .txt h4 {
    font-family: var(--tit-font);
    font-size: 24px;
    transition: all 0.5s ease;
    position: relative;
    left: -30px;
    opacity: 0;
}

.gal-im:hover span,
.gal-im:hover h4 {
    color: #fff;
}

.gal-im:hover .txt:before {
    width: 100%;
    transition-delay: 0.4s;
}

.gal-im:hover:before {
    opacity: 1;
}

.gal-im:hover .txt span {
    left: 0px;
    opacity: 1;
    transition-delay: 0.2s;
}

.gal-im:hover .txt h4 {
    left: 0px;
    opacity: 1;
    transition-delay: 0.3s;
}

.gal-im:hover {}


/*-------------------------------------------------------*/
/* FOOTER ADDRESS
/*-------------------------------------------------------*/
.foot-box {
    background: #ffe2e2;
    margin: 250px 0 0 0;
}

.foot-box .inn {
    width: 100%;
}

.foot-box .inn ul li {
    float: left;
    width: 33.333%;
}

.foot-box .inn ul li:nth-child(1) .foot-inn {
    background: #ffeeee;
}

.foot-box .inn ul li:nth-child(2) .foot-inn {
    background: #ffffec;
    transform: scale(1.2);
    position: relative;
    z-index: 1;
    left: -28px;
    box-shadow: 0 0px 40px -11px #3333;
}

.foot-box .inn ul li:nth-child(3) .foot-inn {
    background: #dcffe3;
}

.foot-inn {
    float: left;
    width: 100%;
    padding: 50px 20px 50px 30px;
    position: relative;
}

.foot-inn div {
    padding-left: 100px;
}

.foot-inn i.fa {
    position: absolute;
    left: 35px;
    top: 50px;
    font-size: 52px;
    border: 1px solid #282828;
    padding: 10px;
    border-radius: 10px;
    vertical-align: initial;
    width: 72px;
    height: 72px;
    text-align: center;
}

.foot-inn div span {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 5px;
    display: block;
    font-family: var(--tit-font);
}

.foot-inn div h5 {
    margin: 0;
    font-size: 28px;
    font-family: var(--tit-font);
}


/*-------------------------------------------------------*/
/* WEDDING TIMELINE
/*-------------------------------------------------------*/
.wedd-tline {}

.wedd-tline .inn {
    margin: 0 auto;
    width: 70%;
}

.wedd-tline .inn ul {
    position: relative;
    float: left;
    width: 100%;
}

.wedd-tline .inn ul:before {
    content: '';
    background: #ddcebc;
    position: absolute;
    width: 1px;
    top: 5px;
    bottom: 0;
    height: 98%;
    left: 50%;
}

.wedd-tline .inn ul li {
    position: relative;
    float: left;
    width: 100%;
    padding-bottom: 50px;
}

.wedd-tline .inn ul li:before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background: #66451c;
    z-index: 1;
    border-radius: 50px;
    border: 5px solid #fff;
    box-sizing: border-box;
    margin-top: 2px;
    box-shadow: 0 0px 10px 0.6px rgb(40 30 20 / 8%);
    left: calc(50% + -12px);
}

.tline-inn {
    /* padding-left: 60px; */
    float: left;
    width: 100%;
}

.tline-inn div {}

.tline-inn img {
    width: 128px
}

.tline-inn div span {
    /* font-style: italic; */
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    color: #eb0359;
}

.tline-inn div h5 {
    font-family: var(--tit-font);
    font-size: 30px;
}

.tline-inn div p {
    padding: 20px 0 15px 0;
    margin: 0;
    font-size: 16px;
    line-height: 27px;
}

.tline-im {
    float: left;
    width: 50%;
    padding-right: 70px;
}

.tline-im img {
    float: right;
}

.tline-con {
    float: left;
    width: 50%;
    padding: 0 0 0 70px;
}

.tline-inn-reve .tline-im {
    padding: 0 0 0 70px;
}

.tline-inn-reve .tline-im img {
    float: left;
}

.tline-inn-reve .tline-con {
    padding: 0 70px 0 0;
    text-align: right;
}

.animate {
    opacity: 0;
}

.animate.anistart {
    opacity: 1;
}

.hom-blog {}


/*-------------------------------------------------------*/
/* BLOG BOX
/*-------------------------------------------------------*/
.blog {
    display: block;
    width: 100%;
}

.blog ul {}

.blog ul li {
    width: 33.333%;
    float: left;
}

.blog-box {
    padding: 20px;
    float: left;
    width: 100%;
}

.blog-box img {
    width: 100%;
    height: 410px;
    object-fit: cover;
    margin-bottom: 30px;
}

.blog-box span {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    color: #eb0359;
}

.blog-box h2 {
    font-size: 24px;
    margin: 5px 0 15px 0;
}

.blog-box p {
    font-size: 15px;
}

.blog-box a {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px 8px 25px;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    float: left;
    color: #000;
}

.blog-rel {
    padding-bottom: 20px;
}

.blog-rel .home-tit {
    padding-top: 40px;
    margin-bottom: 30px;
}

.blog-rel .cus-revi {
    padding: 0;
}

.blog-rel ul li {
    margin: 5px;
}

.blog-rel .blog-home-box .im img {
    height: 350px;
    filter: grayscale(80%);
}

.blog-rel .blog-home-box:hover .im img {
    filter: grayscale(0%);
}

.blog-rel .blog-home-box .txt {
    padding: 20px;
}

.blog-detail .blog-rel .blog-home-box .txt h2 {
    font-size: 16px;
    line-height: 28px;
}

.blog-rel .slick-arrow {
    top: 40%;
}

.blog-rel .blog-pro-info h5 {
    font-size: 12px;
    line-height: 16px;
}

.blog-nav {
    float: left;
    width: 100%;
    padding-top: 40px;
    border-top: 2px dashed #d3c1ab;
    margin-top: 30px;
    /* border-bottom: 2px dashed #d3c1ab; */
    /* padding-bottom: 35px; */
}

.blog-detail .blog-nav .com {
    float: left;
    width: 50%;
    position: relative;
    padding: 0;
}

.blog-nav .com span {
    text-transform: uppercase;
    font-size: 15px;
}

.blog-nav .lhs:hover i {
    margin-right: 20px;
}

.blog-nav .rhs:hover i {
    margin-left: 20px;
}

.blog-nav .com i {
    color: #66451c;
    margin-right: 10px;
    transition: all 0.5s ease;
}

.blog-nav .rhs i {
    margin: 0 0 0 10px;
}

.blog-nav .com h4 {
    margin: 5px 0 0 0;
    font-weight: 600;
    font-size: 22px;
    font-family: var(--tit-font);
}

.blog-nav .rhs {
    text-align: right;
}

.slick-dots {
    margin: 0 auto;
    display: table;
}

.slick-dots li {
    width: auto !important;
}

.slick-dots li button {
    color: #0000;
    width: 12px;
    height: 12px;
    background: #858585;
    border-radius: 50px;
    border: 2px solid #fbf8ed;
    box-shadow: 0 0px 0px 3px #e7e7e7;
    outline: none;
    transition: all 0.5s ease-in-out;
}

.home-acces-main .slick-dots li button {
    background: #ff000c;
    border: 2px solid #e5026b;
    box-shadow: 0 0px 0px 5px #ffffff2e;
}

.home-acces-main .slick-dots li.slick-active button {
    background: #ef0146;
    border: 2px solid #000000;
    box-shadow: 0 0px 0px 5px #ef0146;
}

.home-acces-main .slick-dots {
    padding-top: 75px;
}

.slick-dots li.slick-active button {
    background: #f6af04;
    border: 2px solid #fbf8ed;
    box-shadow: 0 0px 0px 3px #f6d587;
}

/*-------------------------------------------------------*/
/* BLOG SHARE
/*-------------------------------------------------------*/
.shar-1 {
    position: absolute;
    right: 15px;
    top: 15px;
}

.shar-1 .fa-share-alt {
    border-radius: 50px;
    width: 40px;
    height: 40px;
    background: #ffffffba;
    text-align: center;
    font-size: 20px;
    line-height: 38px;
    color: #6f37ec;
    cursor: pointer;
    z-index: 2;
    font-weight: 300;
    transition: all 0.5s ease;
}

.shar-1 .fa-share-alt:hover {
    background: #5a00ffba;
    color: #ffffff;
}

.shar-1 .fa-share-alt.act~ul li {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
}

.shar-1 .fa-share-alt.act~ul li:nth-child(1) {
    transition-delay: 0.3s;
}

.shar-1 .fa-share-alt.act~ul li:nth-child(2) {
    transition-delay: 0.4s;
}

.shar-1 .fa-share-alt.act~ul li:nth-child(3) {
    transition-delay: 0.5s;
}

.shar-1 .fa-share-alt.act~ul li:nth-child(4) {
    transition-delay: 0.6s;
}

.shar-1 ul {
    transition: all 0.5s ease;
}

.shar-1 ul li {
    margin: 5px 0;
    transition: all 0.5s ease;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-45px);
}

.shar-1 ul li i {
    border-radius: 50px;
    width: 40px;
    height: 40px;
    background: #ffffffba;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    color: #373948;
    cursor: pointer;
    transition: all 0.5s ease;
}

.shar-1 ul li i:hover {
    color: #fff;
}

.shar-1 ul li i.fa-link:hover {
    background: #373948;
}

.sharepop .modal-body {
    padding: 30px 40px 40px 40px;
}

.sharepop .modal-title {
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    text-transform: uppercase;
}

.sharepop .modal-body input {
    background: #eef5ff;
    border: 1px solid #30415a;
    font-size: 13px;
    font-weight: 600;
    padding: 8px 12px;
    float: left;
    width: calc(100% - 150px);
    color: #30415a;
    border-radius: 20px 0 0 20px;
    height: 39px;
}

.sharepop .modal-body .shareurltip {
    padding: 0;
    width: 150px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    /* margin-top: 10px; */
}

.sharepop .modal-body .shareurltip button {
    display: block;
    width: 100%;
    background: #30415a;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    border: 0px;
    padding: 10px;
    border-radius: 0 20px 20px 0;
}

.shareurltip {
    position: relative;
    display: inline-block;
}

.shareurltip .shareurltxt {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 115%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 11px;
}

.shareurltip .shareurltxt::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.shareurltip:hover .shareurltxt {
    visibility: visible;
    opacity: 1;
}

.btn-ani {
    color: #000;
    cursor: pointer;
    font-weight: 400;
    line-height: 28px;
    margin: 10px 0;
    max-width: 125px;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    padding: 4px 20px;
}

.cta-dark {
    overflow: hidden;
    position: relative;
}

.cta-dark span {
    color: #fff;
    font-weight: 500;
}

.cta-dark:after {
    background: #ffffff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 0.3;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    /* z-index: 1; */
}

.cta-dark:hover:after {
    left: 110%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

/*-------------------------------------------------------*/
/* BLOG 
/*-------------------------------------------------------*/
.blog-main {}

.blog-main .inn {
    float: left;
    width: 100%;
    padding: 80px 0;
}

.blog-main .lhs {
    float: left;
    width: 70%;
}

.blog-main .rhs {
    float: left;
    width: 30%;
    padding-left: 50px;
}

.blog-home-box {
    float: left;
    width: 100%;
    position: relative;
    margin: 10px 0px 30px 0px;
    box-shadow: 0px 1px 9px -5px #33333330;
    border-radius: 5px;
}

.blog-home-box .im {
    float: left;
    width: 100%;
    position: relative;
}

.blog-home-box .im img {
    float: left;
    width: 100%;
    height: 400px;
    object-fit: cover;
    transition: all 0.4s ease;
    border-radius: 5px 5px 0 0;
}

.blog-home-box .txt {
    float: left;
    width: 100%;
    padding: 40px 40px 30px 40px;
    position: relative;
    background: #fff;
}

.blog-home-box .txt:hover h2 {
    color: #ca6300;
}

.blog-home-box .txt h2 {
    font-size: 22px;
    font-weight: 600;
    color: #66451c;
    padding: 20px 0 0px 0px;
    transition: all 0.5s ease;
    font-family: Poppins, sans-serif;
}

.blog-rel .blog-home-box .txt h2 {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.blog-com-tit h2 {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 15px;
}

.blog-home-box .txt p {}

.blog-home-box .txt span.cta-line-ani {
    margin-left: 0;
}

.blog-home-box .txt span.cta-line-ani a {
    padding: 9px 21px;
    font-size: 13px;
    font-weight: 600;
    color: #66451c;
}

.blog-home-box-big .im img {
    height: 394px;
}

.blog-home-box-big .txt h2 {
    font-size: 26px;
    font-weight: 700;
    max-height: 65px;
    overflow: hidden;
}

.blog-home-box-big .txt {
    padding: 15px 30px;
}

.blog-detail {}

.blog-detail *+h2,
.blog-detail *+h3,
.blog-detail *+h4,
.blog-detail *+h5 {
    margin-top: 25px;
}

.blog-detail h2,
.blog-detail h3,
.blog-detail h4,
.blog-detail h5 {
    font-weight: 600;
}

.blog-detail .blog-home-box .txt h2 {
    font-size: 22px;
    color: #66451c;
    padding-top: 0px;
    line-height: 30px;
}

.blog-detail p {
    font-size: 16px;
    line-height: 28px;
}

.blog-detail h3 {
    font-size: 20px;
}

.blog-detail *+h3 {
    margin: 40px 0 10px 0;
}

.blog-detail h4 {
    font-size: 20px;
}

.blog-hom-ban-ads {
    float: left;
    width: 100%;
    padding-bottom: 50px;
}

.blog-hom-ban-ads a {
    margin: 0 auto;
    display: table;
}

.blog-hom-ban-ads img {
    width: 100%;
}

.blog-com-rhs {
    position: relative;
    height: 100%;
    /* padding-left: 35px; */
}

.blog-com-rhs h4 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 15px;
}

.blog-soci {
    float: left;
    width: 100%;
    padding-bottom: 45px;
}

.blog-soci ul {}

.blog-soci ul li {
    float: left;
    width: 50%;
    text-align: center;
    padding: 0 10px 10px 0;
}

.blog-soci ul li a {
    color: #fff;
    display: block;
    padding: 15px 10px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 400;
}

.blog-soci ul li a b {
    /* display: block;
    */
    font-size: 24px;
    font-weight: 600;
}

.blog-rhs-cate {
    float: left;
    width: 100%;
    margin: 0 0 45px 0;
}

.blog-rhs-cate ul li {
    margin-bottom: 8px;
}

.hot-page2-hom-pre ul li {
    list-style-type: none;
    border-bottom: 1px solid #efefef;
    position: relative;
    overflow: hidden;
    padding: 10px 0px;
    transition: all 0.5s ease;
}

.fclick {
    position: absolute;
    cursor: pointer;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 5;
}

.hot-page2-hom-pre ul li:hover {
    transition: all 0.5s ease;
    background: #f2eddc;
    border-radius: 10px;
    padding-left: 10px;
}

.hot-page2-hom-pre ul li:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
}

.hot-page2-hom-pre div {
    float: left;
}

.blog-info {
    position: relative;
    margin-top: 25px;
}

.blog-home-box .blog-pro-info img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
}

.blog-pro-info h5 {
    padding: 5px 0 0 50px;
    font-size: 14px;
    font-weight: 500;
}

.blog-pro-info h5 span {
    display: block;
    font-size: 10px;
    text-transform: uppercase;
    padding-top: 3px;
    font-weight: 500;
}

.blog-rhs-cate ul li a {
    background: url(../images/ban-bg.jpg);
    display: block;
    padding: 15px 50px 15px 25px;
    border-radius: 5px;
    position: relative;
    color: #fff;
    font-size: 18px;
    z-index: 0;
    background-position: -41px -464px;
    text-align: left;
}

.blog-rhs-cate ul li:nth-child(1) a {
    background-position: -41px -464px;
}

.blog-rhs-cate ul li:nth-child(2) a {
    background-position: -41px -669px;
}

.blog-rhs-cate ul li:nth-child(3) a {
    background-position: -41px -923px;
}

.blog-rhs-cate ul li:nth-child(4) a {
    background-position: -41px -991px;
}

.blog-rhs-cate ul li:nth-child(5) a {
    background-position: -41px -962px;
}

.blog-rhs-cate ul li:nth-child(6) a {
    background-position: -284px -878px;
}

.blog-rhs-cate ul li:nth-child(7) a {
    background-position: 0px 0px;
}

.blog-rhs-cate ul li:nth-child(8) a {
    background-position: 0px 0px;
}

.blog-rhs-cate ul li:nth-child(9) a {
    background-position: 0px 0px;
}

.blog-rhs-cate ul li:nth-child(10) a {
    background-position: 0px 0px;
}

.blog-rhs-cate ul li:nth-child(11) a {
    background-position: 0px 0px;
}

.blog-rhs-cate ul li:nth-child(12) a {
    background-position: 0px 0px;
}

.blog-rhs-cate ul li:nth-child(13) a {
    background-position: 0px 0px;
}

.blog-rhs-cate ul li:nth-child(14) a {
    background-position: 0px 0px;
}

.blog-rhs-cate ul li:nth-child(15) a {
    background-position: 0px 0px;
}

.blog-rhs-cate ul li:nth-child(16) a {
    background-position: 0px 0px;
}

.blog-rhs-cate ul li:nth-child(17) a {
    background-position: 0px 0px;
}

.blog-rhs-cate ul li:nth-child(18) a {
    background-position: 0px 0px;
}

.blog-rhs-cate ul li a:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: #0000006b;
    border-radius: 5px;
}

.blog-rhs-cate ul li a:after {
    font-family: FontAwesome;
    content: "\f178";
    color: #fff;
    font-size: 24px;
    right: 25px;
    top: 13px;
    transition: all 0.5s ease;
    z-index: 1;
    position: absolute;
}

.blog-rhs-cate ul li a:hover:after {
    right: 25px;
}

.blog-rhs-cate ul li a span {
    width: 28px;
    height: 28px;
    background: #e5840c;
    display: inline-block;
    text-align: center;
    padding: 2px;
    line-height: 26px;
    border-radius: 10px;
    margin-right: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    position: absolute;
    left: 26px;
    top: 15px;
}

.blog-rhs-cate ul li a b {
    position: relative;
    font-weight: 500;
    padding-left: 40px;
    font-family: 'Kalam', cursive;
    display: block;
    line-height: 26px;
}

.blog-rhs-trends,
.blog-rhs-trends li {
    float: left;
    width: 100%;
}

.blog-rhs-trends .hot-page2-hom-pre-1 {
    width: 100px;
}

.blog-rhs-trends .hot-page2-hom-pre-1 img {
    width: 100px;
    height: 80px;
    border-radius: 5px;
    object-fit: cover;
}

.blog-rhs-trends .hot-page2-hom-pre-2 {
    width: calc(100% - 100px);
    padding: 0 20px;
    display: table;
}

.blog-rhs-trends .hot-page2-hom-pre-2 h5 {
    font-size: 15px;
    height: 80px;
    font-weight: 500;
    display: table-cell;
    vertical-align: middle;
    line-height: 22px;
}

.blog-rhs-trends .hot-page2-hom-pre-2 span {
    background: none;
    line-height: 16px;
    padding-left: 18px;
    padding-top: 3px;
}

.blog-rhs-trends .hot-page2-hom-pre-2 span:before {
    left: 0px;
    top: 2px;
}

.sm-fb-big {
    background: #3b5998;
}

.sm-tw-big {
    background: #1da1f2;
}

.sm-li-big {
    background: #0a66c2;
}

.sm-yt-big {
    background: #ff0000;
}

.blog-rhs-ads-ban {
    float: left;
    width: 100%;
    position: relative;
    margin: 30px 0 0 0;
}

.blog-rhs-ads-ban .ban-ati-com span {
    right: 10px;
    top: 10px;
}

.ads-box span,
.ban-ati-com span {
    position: absolute;
    color: #fff;
    font-size: 10px;
    padding: 1px 3px;
    border-radius: 2px;
}

.ban-ati-com a img {
    width: 100%;
}

.blog-subsc {
    float: left;
    width: 100%;
    margin: 55px 0 0 0;
    background: #fff;
    box-shadow: 0px 2px 15px -4px rgb(224 224 224 / 80%);
    border-radius: 10px;
    position: sticky;
    top: 80px;
    text-align: center;
    padding: 35px;
}

.blog-subsc h5 {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 22px;
    font-weight: 500;
}

.blog-subsc h5 b {
    display: block;
    font-size: 30px;
    font-weight: 600;
}

.blog-subsc img {
    width: 100px;
    /* padding-top: 25px; */
}

.blog-subsc p {
    font-size: 14px;
    line-height: 24px;
    margin: 0px;
}

.blog-subsc ul {
    /* padding: 0 30px 30px; */
    float: left;
    width: 100%;
    /* background: #2f4960;
    */
    border-radius: 0 0 10px 10px;
}

.blog-subsc ul li {}

.blog-subsc ul li input {
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.blog-subsc ul li input[type='submit'] {
    color: #fff;
    padding: 0px 25px 0px 25px;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.5s ease;
    background: #000;
    margin-top: 10px;
    font-weight: 500;
}

.blog-cate {
    font-size: 10px;
    border-radius: 2px;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;
    background: #ff8d3c;
    padding: 3px 8px;
    margin: 0 5px 5px 0;
    color: #fff;
}

.blog-date {
    background: #ffebd6;
    display: inline-block;
    padding: 4px 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    position: absolute;
    left: 10px;
    top: 10px;
    font-family: 'Kalam', cursive;
    border-radius: 3px;
}

.page-nation {
    float: left;
    width: 100%;
    margin: 50px 0;
}

.page-nation ul {}

.page-nation ul li {}

.page-nation ul li.active a {
    background: #66451c !important;
    border-color: #66451c !important;
}

.page-nation ul li a {
    color: #66451c;
}

.page-link:hover {
    color: #66451c;
    background-color: #f0eeeb;
    border-color: #f0eeeb;
}

/*-------------------------------------------------------*/
/* PROFILE - RELATED PROFILE
/*-------------------------------------------------------*/
.wedd-rel-pro {
    position: relative;
    overflow: hidden;
    margin-top: 50px;
    border: 0;
    padding-bottom: 30px;
}

.wedd-rel-pro ul {}

.wedd-rel-pro ul li {
    float: left;
    width: 33.333%;
    padding: 0px 10px;
}

.wedd-rel-box {
    position: relative;
}

.wedd-rel-img img {
    width: 100%;
    border-radius: 5px;
    height: 250px;
    object-fit: cover;
}

.wedd-rel-con {
    background: #fff;
    text-align: center;
    margin-left: 10%;
    width: 80%;
    margin-top: -32px;
    z-index: 1;
    position: relative;
    border-radius: 5px;
    padding: 12px 5px 10px 5px;
    box-shadow: 0 3px 6px 1px #0000001a;
}

.wedd-rel-con h5 {
    font-size: 15px;
    margin-bottom: 0;
    color: #b94b18;
}

.wedd-rel-con span {
    font-size: 11px;
    text-transform: uppercase;
}

.wedd-rel-box .badge {
    position: absolute;
    left: 10px;
    top: 10px;
    font-weight: 400;
    font-size: 12px;
}

.wedd-rel-pro h3 {
    text-align: center;
}

.wedd-rel-pro .slick-arrow {
    width: 50px;
    height: 50px;
    top: 40%;
}

.wedd-rel-pro .slick-arrow:before {
    font-size: 27px;
    left: 0;
}

.wedd-rel-pro .slick-prev {
    left: 0px;
}

.wedd-rel-pro .slick-next {
    right: 0;
}

/*-------------------------------------------------------*/
/* CONTACT
/*-------------------------------------------------------*/
/* .pg-cont..ban-inn {} */

.pg-cont.ab-sec2 ul li div h4 {
    font-size: 16px;
    text-transform: uppercase;
    color: #000;
}
.nav-content {
    display: flex;
  }
  
  .left-nav {
    width: 50%;
  }
  
  .right-nav {
    width: 50%;
  }
  
  .left-nav-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .right-nav-content {
    padding: 10px;
  }
  
.pg-cont.ab-sec2 ul li div p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
}

.ban-inn.pg-cont {
    padding: 100px 20px 110px 20px;
}

.pg-cont {}

.pg-cont ul {
    width: 100%;
}

.pg-cont ul li div.we-here {
    text-align: left;
}

.pg-cont ul li div:hover {
    transform: scale(1);
}

.we-here h3 {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    color: #0873e1;
    padding-bottom: 15px;
}

.we-here span {
    display: block;
    position: relative;
    padding-left: 55px;
    margin-bottom: 25px;
    font-size: 16px;
}

.we-here span:last-child {
    margin-bottom: 0;
}

.we-here span i {
    font-size: 18px;
    display: inline-block;
    position: absolute;
    top: -7px;
    border: 1px solid rgb(219, 204, 187);
    padding: 8px;
    border-radius: 10px;
    vertical-align: initial;
    width: 38px;
    height: 38px;
    text-align: center;
    left: 0;
}

.pg-cont .we-cont img {
    width: 122px;
}

.cta-rou-line {
    border: 1px solid #66451c;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: 500;
}

.cta-rou-line:hover {
    color: #fff;
    background: #000;
}

.pg-cont.login {
    background: none;
    padding: 0 0 10px 0;
    margin: 0;
}

.pg-cont.login .form-login .btn {
    width: 100%;
    text-transform: uppercase;
}

/*-------------------------------------------------------*/
/* COMMING SOON
/*-------------------------------------------------------*/
.pg-soon {
    padding: 80px 0 50px 0;
    height: 100vh;
    position: relative;
    background: url(../images/grass.png) no-repeat;
    background-position: center top;
    margin-top: 0;
}

.pg-soon .inn {
    width: 100%;
    text-align: center;
    position: relative;
    padding-top: 50px;
    background: none;
    box-shadow: none;
}

.pg-soon .tit {}

.pg-soon .tit h4 {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 300;
    /* color: #846565; */
    margin-bottom: 0;
}

.pg-soon .tit h1 {
    font-size: 90px;
    font-weight: 700;
    line-height: 120px;
    color: #ffa620;
    background: -webkit-linear-gradient(#c37c24, #66451c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pg-soon .timer {
    padding: 50px 0;
    display: inline-block;
}

.pg-soon .timer div {
    display: inline-block;
    font-size: 100px;
    padding: 0 25px 15px 25px;
    border: 1px solid #e0ddd1;
    border-radius: 8px;
    margin: 0 5px;
    /* background: #fff; */
}

.pg-soon .timer div i{
    display: block;
    font-size: 20px;
    text-transform: uppercase;
}

.pg-soon .days {
    color: #b70d4c;
}

.pg-soon .hours {
    color: #036ac3;
}

.pg-soon .minutes {
    color: #c79c00;
}

.pg-soon .seconds {
    color: #00bf70;
}

.pg-soon .menu-pop-soci ul li a i {
    border-radius: 50px;
    background: none;
    color: #80551f;
    font-size: 24px;
}

.pg-soon .menu-pop-soci ul li a i:hover {
    color: #80551f;
}

/*-------------------------------------------------------*/
/* SERVICES PAGE
/*-------------------------------------------------------*/
.pg-ser {
    margin-top: var(--mtop);
}

.pg-ser .inn {}

.pg-ser .inn ul {}

.pg-ser .inn ul li {
    float: left;
    width: 25%;
}

.pg-ser .gal-im {
    margin: 0;
}

.gal-im .txt p {
    font-size: 15px;
    transition: all 0.5s ease;
    position: relative;
    left: -30px;
    opacity: 0;
    color: #fff;
    font-weight: 100;
    margin-top: 20px;
}

.gal-im:hover .txt p {
    left: 0px;
    opacity: 1;
    transition-delay: 0.4s;
}

.pg-ser .gal-im:before {
    opacity: 1;
    background: linear-gradient(5deg, #03080cb5, transparent);
}

.pg-faq {}

.pg-faq .form-tit {
    padding: 35px 30px 30px;
    text-align: center;
}

.pg-faq .ab-faq-lhs .card {
    margin-bottom: 0;
}

.pg-faq .ab-faq-lhs .card-header a {
    line-height: 24px;
    font-size: 18px;
}


/*-------------------------------------------------------*/
/* IMAGE GALLERY
/*-------------------------------------------------------*/
.img-wrapper {
    position: relative;
    /* margin-top: 15px;
    */
}

.pro-gal-imag {
    float: left;
    width: 33.3333%;
    padding: 0px 10px 20px 10px;
}

.img-wrapper img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
}

.img-overlay {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.img-overlay i {
    color: #fff;
    font-size: 38px;
    font-weight: 300;
}

#gallery {
    float: left;
    width: 100%;
}

#overlay {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#overlay img {
    margin: 0;
    width: 75%;
    height: auto;
    -o-object-fit: contain;
    object-fit: contain;
    padding: 5%;
}

#nextButton i,
#prevButton i {
    color: #fff;
    font-size: 44px;
    line-height: 30px;
    margin: 13px 6px;
    text-align: center;
}

#nextButton,
#prevButton {
    transition: opacity 0.8s;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    border: 1px solid #ededed;
    color: #ffffff03;
    text-align: center;
    cursor: pointer;
}

#nextButton:hover {
    opacity: 0.7;
}

#prevButton:hover {
    opacity: 0.7;
}

#exitButton {
    color: #fff;
    font-size: 2em;
    transition: opacity 0.8s;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

#exitButton:hover {
    opacity: 0.7;
}

/*-------------------------------------------------------*/
/* 404 PAGE
/*-------------------------------------------------------*/
.pg-404 .inn {}

.pg-404 .lhs {
    width: 100%;
    position: relative;
    text-align: center;
    padding: 80px 50px 100px;
    background: linear-gradient(1deg, rgb(242, 209, 132), rgb(255, 238, 178) 80%);
}

.pg-404 .tit {}

.pg-404 .lhs .tit h2 {
    line-height: 27px;
    text-transform: uppercase;
    font-size: 28px;
}

.pg-404 .lhs .tit h2 b {
    font-size: 180px;
    line-height: 140px;
    margin: 0;
}

.pg-404 .lhs .tit h5 {
    line-height: 32px;
    font-size: 22px;
    margin: 60px 0 30px 0;
    font-weight: 500;
}

.pg-404 .lhs .tit a {
    letter-spacing: 2px;
}

.db-nav{
    background: #fff;
    padding: 20px;
    box-shadow: 0 1px 12px -2px #00000024;
    border-radius: 10px;
    margin: 0 20px 50px 0;
}
.db-nav-pro{
    margin-bottom: 30px;
}
.db-nav-pro img{
    border-radius: 10px;
}
.db-sec-com{margin-bottom: 40px;position: relative;}
.db-nav-list{}
.db-nav-list ul{}
.db-nav-list ul li{
    margin-bottom: 5px;
}
.db-nav-list ul li a{
    display: block;
    font-weight: 500;
    padding: 7px 5px;
    font-size: 15px;
    position: relative;
    color: #000;
}
.db-nav-list ul li a i{
    margin-right: 18px;
    width: 20px;
    text-align: center;
}
.db-nav-list ul li a.act{
    color: #03A9F4;
    background: #e4f1fc;
    background: linear-gradient(to left,#ffffff,#e4f1fc 70%);
    }
.db-nav-list ul li a.act i{}
.db-nav-list ul li a.act:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 22px;
    background: #03A9F4;
    margin: 0px 0px 0px -14px;
    transition: all 0.5s ease;
}
.db-new-pro-main{
    overflow: hidden;
}
.db-new-pro-main ul li{float: left;width: 16.5%;position: relative;display: flex;}
.db-new-pro{
    background: #fff;
    text-align: center;
    width: 100%;
    position: relative;
    border-radius: 15px;
    box-shadow: 0 3px 6px 1px #0000001a;
    margin-right: 10px;
    overflow: hidden;
}
.db-new-pro:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0px;
    border-radius: 15px;
    transition: all 0.4s ease 0s;
    background: linear-gradient(to top, rgb(37, 24, 8), rgba(215, 154, 78, 0));
}
.db-new-pro .profile{
    width: 100%;
    border-radius: 15px;
    height: 200px;
    object-fit: cover;
    transition: all 0.4s ease;
}
.db-new-pro div{
    position: absolute;
    left: 0;
    bottom: 18px;
    right: 0;
    color: #fff;
    z-index: 1;
}
.db-new-pro div *{
    color: #fff;
}
.db-new-pro div h5{
    font-size: 16px;
    margin: 0;
}
.db-new-pro div span{
    font-size: 11px;
    color: #c2c2c2;
}
.db-new-pro div .city{}
.db-new-pro div .age{}
h2.db-tit{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 25px;
}
.db-new-pro:hover .profile{transform: scale(1.2);}

.db-pro-stat, .db-profile, .db-invoice{
    background: #fff;
    padding: 20px;
    box-shadow: 0 1px 12px -2px #00000024;
    border-radius: 10px;
    text-align: center;
    position: relative;
}
.db .dropdown{
    position: absolute;
    right: 15px;
    top: 15px;
}
.db .dropdown button{
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 3px;
    text-align: center;
    line-height: 26px;
}
.db .dropdown button i{
    font-size: 20px;
}
.db .dropdown .dropdown-item{font-size: 13px;}
.db-pro-stat h6{
    font-size: 14px;
    margin-bottom: 30px;
}
.db-pro-pgog{
    margin: 0 auto;
    display: table;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 20px solid #ffffff;
    vertical-align: middle;
    position: relative;
    box-shadow: 0px 0px 32px -7px #b8b8b8;
    background: linear-gradient(45deg, #0027ff, #ff0074);
}
.db-pro-pgog span{
    font-size: 22px;
    color: #fff;
}
.db-pro-pgog span b.count{
    font-size: 50px;
    line-height: 109px;
    padding: 0;
    float: initial;
}
.db-pro-pgog span:before{}
.db-pro-stat ul{
    margin-top: 30px;
}
.db-pro-stat ul.pro-stat-ic li{
    width: 49%;
    display: inline-block;
    margin: 0 0 15px 0;
}
.db-pro-stat ul.pro-stat-ic li span{
    vertical-align: middle;
    font-size: 15px;
}
.db-pro-stat ul.pro-stat-ic li span i{
    width: 32px;
    height: 32px;
    border-radius: 50px;
    vertical-align: middle;
    margin-right: 5px;
    color: #fff;
    font-size: 14px;
    line-height: 32px;
}
.db-pro-stat i.like{
    background: #f8896b;
}
.db-pro-stat i.view{
    background: #84e8f5;
}
.db-pro-stat i.inte{
    background: #ffbd66;
}
.db-pro-stat i.clic{
    background: #52459d;
}
.db-pro-stat ul li span b{
    margin-right: 5px;
}
.db-plan-detil{}
.db-plan-detil ul{}
.db-plan-detil li{
    font-size: 14px;
    padding-bottom: 3px;
}
.db-plan-detil li strong{}
.db-plan-detil li a{
    margin-top: 15px;
}
.db-plan-card{}
.db-plan-card img{
    max-width: 110px;
}
.db-plan-canc{
}
.db-plan-canc p{
    margin: 0;
    text-align: center;
}
.db-plan-canc p strong{    font-weight: 500;}
.db-plan-canc a{
    text-decoration: underline;
    color: #00b0ff;
}
.db-plan-canc-app{display: none;}
.db-inte-prof-list{}
.db-inte-prof-list ul{}
.db-inte-prof-list ul li{
    list-style-type: none;
    border-bottom: 1px solid rgb(231, 231, 231);
    position: relative;
    overflow: hidden;
    padding: 30px 0px;
}
.db-inte-prof-list ul li:first-child{padding-top: 0;}
.db-inte-prof-chat ul{    margin-top: 13px;}
.db-inte-prof-chat ul li{padding: 15px 0;}
.db-inte-prof-chat ul li .db-int-pro-1{
    width: 50px;
}
.db-inte-prof-chat ul li .db-int-pro-1 img{
    width: 50px;
    height: 50px;
}
.db-chat{
    text-align: left;
}
.db-chat ul{
    margin: 0;
}
.db-chat li{
    list-style-type: none;
    border-bottom: 1px solid rgb(231, 231, 231);
    position: relative;
    padding: 15px 0 15px 15px;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    /* margin-bottom: 15px; */
    transition: all 0.5s ease;
    border-radius: 10px;
}
.db-chat li:hover{
    background: #fff6f6;
}
.db-chat li:last-child{border-bottom: 0;margin-bottom: 0;}
.db-chat-pro{width: 80px;float: left;}
.db-chat-pro img{
    width: 80px;
    height: 80px;
    border-radius: 50px;
    object-fit: cover;
}
.db-chat-bio{
    float: left;
    width: calc(100% - 150px);
    padding: 20px 20px 0px 25px;
    }
.db-chat-bio h5{
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 600;
}
.db-chat-bio span{font-size: 13px;color: #333;}
.db-chat-info{float: left;width: 70px;padding-top: 20px;}
.db-chat-info .time{}
.db-chat-info .time .timer{
    font-size: 14px;
    display: block;
}
.db-chat-info .new{}
.db-chat-info .new .cont{
    font-size: 14px;
    width: 20px;
    height: 20px;
    display: inline-block;
    background: #4CAF50;
    color: #fff;
    font-weight: 500;
    border-radius: 50px;
    text-align: center;
}
.db-chat-trig{cursor: pointer;}
.db-int-pro-1 .badge{
    position: absolute;
    left: 5px;
    bottom: 5px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}
.user-pla-pat{
    background-image: linear-gradient(120deg, #9e00af 0%, #fda085 100%);
}
.user-pla-gold{
    background-image: linear-gradient(120deg, #af8300 0%, #d88f00 100%);
}
.user-pla-free{
    background-image: linear-gradient(120deg, #8f8f8f 0%, #64646c 100%);
}
.db-inte-prof-chat ul li .db-int-pro-2{}
.db-inte-prof-list ul li:last-child{padding-bottom: 15px;border-bottom: 0;}
.db-inte-prof-list div{float:left;position: relative;}
.db-inte-prof-list .db-int-pro-2 h5{    font-size: 16px;}
.db-inte-prof-list div ol.poi{
    padding: 10px 0 0 0;
}
.db-inte-prof-list div ol.poi-date{padding-top: 0;}
.db-inte-prof-list div ol.poi li{
    border: 0;
    padding: 0 15px 0 15px;
    display: inline-block;
    font-size: 14px;
    position: relative;
}
.db-pro-stat .nav-tabs .nav-link{
    font-weight: 500;
    font-size: 15px;
    color: #66451c;
}
.db-pro-stat .nav-tabs .nav-link.active{
    color: #328916;
}
.db-inte-prof-list div ol.poi li:before{content:'';position: absolute;width: 7px;height: 7px;background: #68686a;border-radius: 20px;margin: 7px 0 0 -12px;}
.db-inte-prof-list div span.db-inte-pro-bio{}
.db-inte-prof-list div span.db-inte-pro-bio strong{}
.db-int-pro-1{width: 150px;}
.db-int-pro-1 img{
    width: 150px;
    height: 120px;
    border-radius: 10px;
    object-fit: cover;
    }
.db-int-pro-2{
    width: calc(100% - 270px);
    padding: 2px 25px 0px 25px;
    text-align: left;
    }
.db-int-pro-2 h5{
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 600;
    }
.db-int-pro-2 span{
    font-size: 13px;
}
.db-int-pro-3{width: 120px;}
.db-int-pro-3 button{
    padding: 0px 6px;
    border-radius: 15px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.28) 0px -1px 0px;
}
.db-inte-prof-chat .db-int-pro-2{
    width: calc(100% - 50px);
    padding: 5px 0px 0px 25px;
}
.chartin{
    position: relative;
    word-wrap: break-word;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgb(126 142 177 / 12%);
    text-align: center;
    padding: 30px;
    background: #dfffe3;
    background: -webkit-linear-gradient(to left, #ffffff, #dfffe3);
    background: linear-gradient(to left, #ffffff, #ebf5ec);
}
.db-profile{}
.db-profile .img{}
.db-profile .img img{
    max-height: 268px;
    border-radius: 5px 5px 0 0;
    width: 100%;
}
.db-profile .edit{}
.db-profile .edit a{
    width: 100%;
    border-radius: 0 0 5px 5px;
}
.cho-round-cor .chosen-container, .cho-round-cor .chosen-single{border-radius: 5px;}
.db-pro-stat-pg{
    border-top: 1px solid #dbccbb;
    padding-top: 60px;
    margin-top: 20px;
}
.db-pro-stat-pg .chartin{margin-top: 40px;}
.db-pro-stat-view-filter{
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid rgb(96, 30, 79);
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
    margin-top: 38px;
    margin-right: 15px;
}

/*-- SETTINGS --*/
.foll-sett{
    margin: 0 auto;
    display: table;
    float: initial;	
}
.foll-set-tit{
    position: relative;
}
.foll-set-tit h4{
    margin-bottom: 15px;
    font-size: 15px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
}
.sett-lef{
    width: 75%;
    float: left;
}
.sett-rig{
    width: 25%;
    float: left;
}
.sett-pro-wid{}
.set-sig-out{
    float: right;
    color: #fff;
    padding: 0px 12px;
    line-height: 24px;
    border-radius: 50px;
    background: #058be8;
    font-size: 12px;
    margin-top: 5px;
}
.fol-sett-box{
    display: inline-block;
    width: 100%;
}
.fol-sett-box ul{}
.fol-sett-box ul li{
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 10px 10px 1px;
}
.fol-set-main{
    margin: 0 auto;
    display: table;
    width: 70%;
}
.whi-bg{background:#fff;}
.fol-set-lhs{
    padding-right: 25px;
}
.fol-set-rhs{
    border-left: 1px solid #f0f0f0;
    padding-left: 25px;
}
.fol-set-lhs{}
.fol-set-lhs ul{}
.fol-set-lhs ul li{}
.fol-set-lhs ul li a{
    border-bottom: 1px solid #f0f0f0;
    display: block;
    padding: 14px 0px 9px 30px;
    color: #1a2a38;
    font-size: 13px;
    font-weight: 500;
}
.fol-set-lhs ul li:last-child a{border-bottom:0px;}
.fol-sett-sec{
    position: relative;
    margin: 8px 0px 50px 0px;
    padding: 20px;
}
.sett-two-tab{}
.sett-two-tab ul{}
.sett-two-tab ul li{
}
.sett-two-tab ul li div{
    font-size: 12px;
    float: left;
}
.sett-two-tab ul li div:nth-child(1){
    width: 35%;
}
.sett-two-tab ul li div:nth-child(2){
    width: 65%;
    padding-left: 10px;
}
.sett-edit-btn {
    font-size: 12px;
    font-weight: 600;
    /* box-shadow: 0px 6px 13px -4px rgba(0, 0, 0, 0.13); */
    cursor: pointer;
    color: #058be8;
    padding: 2px 15px 1px 15px;
    border-radius: 50px;
    border: 1px solid #058be8;
    background: #fff;
    position: absolute;
    right: 0px;
    top: 0px;
}
.sett-pro-form{}
.sett-pro-form ul li{
    margin-bottom: 15px;
    float: left;
    width: 50%;
    box-sizing: border-box;
}
.sett-pro-form ul li:nth-child(1){padding-right:15px;}
.sett-pro-form ul li:nth-child(3){padding-right:15px;}
.sett-pro-form ul li:nth-child(5){width:100%;}
.sett-pro-form ul li div{font-size:12px;}
.sett-pro-form ul li input, textarea, select{font-size:12px;}
.sett-pro-form ul li:last-child{width:100%;}
.sett-pro-form ul li input[type="submit"]{
    width: 150px;
    float: right;
    padding: 0px 12px;
    line-height: 24px;
    font-size: 12px;
    height: 35px;
}
.sett-pro-form ul li input[type="reset"]{
    width: 150px;
    border-radius: 50px;
    float: right;
    padding: 0px 12px;
    line-height: 24px;
    font-size: 12px;
    height: 35px;
    margin-right: 15px;
}
.sett-acc-edit{display:none;}
.sett-rad-left{}
.sett-rad-left h5{
    font-size: 16px;
    font-weight: 500;
}
.auth-pro-sm div {
    float: left;
}
.auth-pro-sm-img img {
    border-radius: 50px;
    width: 50px;
    height: 50px;
    -webkit-object-fit: cover;
    -moz-object-fit: cover;
    -o-object-fit: cover;
    -ms-object-fit: cover;
    object-fit: cover;
    float: left;
    margin-right: 10px;
}
.sett-rad-left p{
    margin: 0px;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}
.auth-pro-sm-desc p {
    margin: 0px;
    font-size: 10px;
    color: #818990;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}
.auth-pro-sm-desc h5 {
    font-size: 16px;
    font-weight: 600;
    color: #1a2a38;
    margin-bottom: 0;
    padding-top: 10px;
}
.sett-rig label{
    margin-top: 14px;
    float: right;	
}
.sett-select-drop{}
.sett-select-drop select{
    border: 1px solid #d3d3d3;
    height: 25px;
    padding: 2px 7px;
    margin-top: 5px;
    border-radius: 2px;
    width: 100%;
}
.fol-set-lhs ul li a:before {
	    content: "chevron_right";
    font-size: 18px;
    margin-top: 1px;
    margin-left: -26px;
    color: #1a2a38;
    font-weight: 500;
}
.fol-set-lhs ul li a:hover{color:#058be8;/* border-bottom: 1px solid #5dbdff; */}
.fol-set-lhs ul li a:hover:before{color:#058be8;}

/*--RADIO AND CHECK BOX--*/
.checkboxes-and-radios {}
.checkboxes-and-radios input {
  display: none;
}
.checkboxes-and-radios label {
  cursor: pointer;
  position: relative;
}
.checkboxes-and-radios input[type="checkbox"],
.checkboxes-and-radios input[type="radio"] {
  position: absolute;
  visibility: hidden !important;
}
.checkboxes-and-radios input[type="checkbox"] + label:before,
.checkboxes-and-radios input[type="radio"] + label:before,
.checkboxes-and-radios input[type="checkbox"] + label:after,
.checkboxes-and-radios input[type="radio"] + label:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -7.5px;
  box-sizing: border-box;
}
.checkboxes-and-radios input[type="checkbox"] + label:before,
.checkboxes-and-radios input[type="radio"] + label:before {
  width: 30px;
  height: 15px;
  right: 0px;
  background: #fff;
  border: 1px solid #e4e3e1;
  border-radius: 15px;
}
.checkboxes-and-radios input[type="checkbox"] + label:after,
.checkboxes-and-radios input[type="radio"] + label:after {
  width: 15px;
  height: 15px;
  right: 15px;
  background: #bdbdbd;
  border-radius: 50%;
  transition: all 200ms ease-out;
}
.checkboxes-and-radios input[type="checkbox"]:checked + label:after,
.checkboxes-and-radios input[type="radio"]:checked + label:after {
  right: 0px;
  background: #1fb54e;
}
/*-------------------------------------------------------*/
/* When the browser is between 0px and 1500px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width: 1500px) {
    .pro-pg-intro ul li div {
        min-width: 120px;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 1450px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width: 1450px) {

    .hom-top:after,
    .pg-soon:after {
        width: 82px;
        height: 244px;
        background-size: 90px;
        background-position: -1px 6px;
    }

    .hom-top:before,
    .pg-soon:before {
        width: 140px;
        height: 123px;
        background-size: 190px;
        background-position: -33px -43px;
    }

    .wedd-vid-tre-1 {
        width: 131px;
        height: 194px;
    }

    .wedd-vid-tre-2 {
        width: 120px;
        height: 120px;
        bottom: -99px;
    }
}


/*-------------------------------------------------------*/
/* When the browser is between 0px and 1400px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:1400px) {
    .ud-cen-s1 ul li div p {}

    .pg-ser .gal-im img {
        height: 350px;
    }

    .pro-detail h4 {
        font-size: 16px;
    }

    .links a,
    .allpro-conct .links span {
        font-size: 11px;
    }

    .filt-com h4 {
        font-size: 14px;
    }

    .bb-text h4 {
        font-size: 22px;
    }

    .foot-supp h2 {
        font-size: 18px;
    }

    .plans-ban {
        padding: 60px 0 200px 0;
    }

    .plans-ban h1 {
        font-size: 40px;
        line-height: 46px;
    }

    .pri-box h2 {
        font-size: 24px;
        line-height: 26px;
    }

    .pri-box a.cta {
        font-size: 14px;
    }

    .pri-box p {
        font-size: 13px;
    }

    .pri-box .pri-cou b {
        font-size: 26px;
    }

    .pri-box .pri-cou {
        font-size: 22px;
    }

    .pri-box ol li,
    .pri-box-pop .pop-pln {
        font-size: 12px;
    }

    .plans-main ul li {
        padding: 0 10px;
    }

    .plans-main ul li {
        padding: 0 10px;
    }

    .pri-box ol li {
        padding: 0 0 12px 30px;
    }

    .carousel-control-prev,
    .carousel-control-next {
        top: -50px;
    }

    .carousel-control-next {
        right: -70px;
    }

    .carousel-control-prev {
        left: -70px;
    }

    .hom-coup-box img {
        height: 400px;
    }

    .hom-coup-box .bx {
        top: 40%;
    }

    .hom-couples-all .slick-arrow {
        top: 38%;
    }

    .profi-bio {
        padding: 70px 70px 50px 70px;
    }

    .profi-ban .profile {
        width: 45%;
    }

    .profi-bio {
        width: 55%;
        margin-left: 45%;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 1350px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:1360px) {
    .hom-coup-box h4 {
        font-size: 24px;
    }

    .hom-coup-box .bx {
        top: 36%;
    }

    .hom-nav .bl li {
        margin-left: 15px;
    }

    .ban-tit span {
        font-size: 20px;
    }

    .ban-tit h1 {
        font-size: 52px;
        line-height: 64px;
    }

    .ban-tit h1 b {
        font-size: 54px;
    }

    .hom-head {
        padding: 15px 0 50px 0;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 1250px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:1250px) {
    .hom-ban-sli {
        display: none;
    }

    .ab-team {
        padding: 30px 0px;
    }

    .pg-abo-ab-team {
        padding: 0 0 80px 0;
    }



    .hom-head::before {
        height: 100%;
    }

    .list-bann img {
        height: 275px;
    }



    .home-acces-main.inn::before {
        top: 0
    }

    .list-ban-btn {
        border-left: 1px solid #e3e6ea;
        padding: 25px 0px 25px 10px;
    }

    .hom-partners .carousel {
        width: 80%;
    }

    .ab-wel-tit-2 ul li div h4 em {
        font-size: 14px;
    }

    .foot-inn i.fa {
        width: 60px;
        height: 55px;
        font-size: 32px;
    }

    .foot-inn div span {
        font-size: 14px;
    }

    .foot-inn div h5 {
        font-size: 22px;
    }

    .save-txt h2 {
        font-size: 70px;
    }

    .save-im .inn {}

    .save-im img {
        height: 300px;
    }

    .save-im .desc {}

    .save-im .desc h4 {
        font-size: 22px;
    }

    .foot-box .inn ul li:nth-child(2) .foot-inn {
        transform: scale(1);
        left: 0;
    }

    .blog-soci ul li a b {
        font-size: 18px;
    }

    .profi-bio {
        padding: 70px 70px 50px 70px;
    }

    .profi-ban .profile {
        width: 40%;
    }

    .profi-bio {
        width: 60%;
        margin-left:     40%;
        padding: 50px 50px 40px 50px;
    }
}


/*-------------------------------------------------------*/
/* When the browser is between 0px and 1150px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:1150px) {
    .how-wrks-inn:before {
        display: none;
    }

    .links a,
    .links span {
        padding: 4px 7px;
        margin: 0 5px 5px 0;
    }

    .blog-soci ul li a b {
        font-size: 16px;
        display: block;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 1100px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:1100px) {
    .al {
        display: none;
    }

    .hom-nav .bl ul {
        float: right;
    }

    .hom-nav .bl {
        width: calc(100% - 300px);
        padding: 0px 0 0px 40px;
    }

    .pg-list-1-left p {
        padding-left: 205px;
    }

    .pg-list-1-left img {
        width: 175px;
        height: 175px;
    }

    .pag-p1-phone {
        padding-left: 210px;
    }

    .pg-list-1-left .stat {
        top: 44px;
        left: 49px;
    }

    .list-ban-btn ul li span:before {
        margin-top: -4px;
    }

    .list-ban-btn ul li span {
        font-size: 12px;
        padding: 8px 5px 8px 44px;
    }

    .list-ban-btn {
        padding: 33px 0px 33px 10px;
    }

    .home-acces ul li div.hacc .con h4 {
        font-size: 22px;
    }

    .pr-bio-info ul li {
        width: 100%;
    }

    .blog-box img {
        height: 250px
    }

    .we-here span i {
        width: 34px;
        height: 34px;
        font-size: 16px;
    }

    .we-here span {
        font-size: 15px;
        margin-bottom: 20px;
        padding-left: 50px;
    }

    .short-rhs {
        display: none;
    }

    .short-lhs {
        width: 100%;
    }

    .pro-pg-intro ul li div {
        min-width: 110px;
    }

    .hom-top:before {
        width: 77px;
        height: 87px;
        background-size: 112px;
        background-position: -25px -24px;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 1024px and 1350px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (min-width:1024px) and (max-width:1350px) {
    .pro-gal-imag {
        /* width: 50%; */
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 1024px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:1024px) {

    .links a,
    .allpro-conct .links span {
        font-size: 12px;
        padding: 4px 8px;
    }

    .gal-im .txt {
        padding: 30px 30px 35px;
    }

    .profi-ban .profile {
        width: 100%;
        position: relative;
        top: 0;
        height: auto;
    }

    .pg-pro-big-im .s1 {
        position: relative;
    }

    .pg-pro-big-im .s1 img {
        width: 100%;
        max-width: 500px;
        height: 390px;
        margin: 0 auto;
        display: table;
    }

    .pg-pro-big-im .s3 {
        position: fixed;
        bottom: 0;
        z-index: 3;
    }

    .profi-bio {
        width: 100%;
        margin: 0;
        max-width: 100%;
    }

    .pro-pg-intro h1 {
        text-align: center;
        font-size: 36px;
        padding: 20px 0 0px 0;
    }

    .pro-info-status {
        margin: 0 auto;
        display: table;
        margin-bottom: 30px;
    }

    .pro-info-status span {
        margin: 0 3px;
    }

    .pro-pg-intro ul li {}

    .pro-pg-intro ul {
        margin: 0 auto;
        width: auto;
        margin-bottom: 50px;
    }

    .profi-ban {
        margin-top: 0;
    }

    .pg-pro-big-im .s3 .cta {
        font-size: 14px;
        padding: 18px 15px;
    }

    .pro-pg-intro {
        margin-top: -130px;
        z-index: 0;
        position: relative;
        background: #fff;
        border-radius: 20px;
        padding-top: 15px;
    }
}


/*-------------------------------------------------------*/
/* When the browser is between 0px and 992px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:992px) {
    .pg-soon .tit h1 {
        font-size: 60px;
        line-height: 80px;
    }

    .pg-soon .timer div {
        font-size: 50px;
    }

    .pg-soon .timer div i {
        font-size: 14px;
    }

    .home-tit,
    .wedd-gall {
        margin-bottom: 30px;
    }

    .wedd-gall,
    .wedd-vid-gall {
        padding-bottom: 50px;
    }

    .pg-ser .inn ul li {
        width: 33.333%
    }

    .gal-im img.gal-siz-2 {
        height: 400px;
    }

    .foot-inn i.fa {
        width: 45px;
        height: 45px;
        font-size: 22px;
    }

    .foot-inn div {
        padding-left: 65px;
    }

    .hom-top {
        padding: 10px 0;
    }

    .head-top,
    .bl,
    .desk-menu {
        display: none;
    }

    .logo-brand {
        margin-left: 0;
    }

    .mob-sec {
        display: block;
        position: fixed;
        right: 17px;
        top: 17px;
    }

    .mob-me-ic,
    .fil-mob,
    .pro-detail .pro-bio {
        display: block;
        /* top: 2px; */
    }

    .ab-cont ul li .ab-cont-po i {
        position: relative;
        left: initial;
        top: initial;
        margin: 0 auto;
        display: table;
    }

    .ab-cont ul li .ab-cont-po div {
        padding: 25px 0 0 0;
        text-align: center;
    }

    .ab-cont ul li .ab-cont-po div h4 {
        font-size: 26px;
    }

    .ab-cont ul li .ab-cont-po div span {}

    .hom-head {
        padding: 26px 0px;
    }

    .ab-ban {
        /* padding: 120px 0px 40px 0px; */
    }

    .hot-page2-hom-pre {
        margin-bottom: 30px;
    }

    .ho-popu-bod .col-md-4:last-child .hot-page2-hom-pre {
        margin-bottom: 0px;
    }

    .wed-foot-link ul li {
        /* width: 100%; */
        text-align: left;
    }

    .wed-foot-link div {
        padding-left: 30px;
    }

    .list-pg-lt {
        width: 100%;
    }

    .list-pg-rt {
        width: 100%;
        padding: 0;
    }

    .pg-list-1 .col-md-10,
    .pg-list-1 .col-md-2 {
        max-width: 100%;
        flex: 100%;
    }

    .list-ban-btn {
        border-left: 0px;
        margin: 0 auto;
        display: table;
    }

    .list-ban-btn ul li span {
        font-size: 14px;
        padding: 8px 15px 8px 44px;
    }

    .list-ban-btn ul li span:before {
        margin-top: -2px;
    }

    .list-ban-btn ul li {
        float: left;
        margin: 0px 5px 10px 5px;
    }

    .pg-list-1 .col-md-2 {}

    .pg-list-1-left {
        text-align: center;
    }

    .pg-list-1-left img {
        width: 175px;
        height: 175px;
        float: initial;
        margin: initial;
        margin-top: -109px;
    }

    .pg-list-1-left .stat {
        top: initial;
        left: initial;
        position: relative;
        display: table;
        margin: 0 auto;
    }

    .pg-list-1-left h3 {
        float: left;
        width: 100%;
        padding: 20px 0px;
        margin-bottom: 0px;
        margin-top: 15px;
    }

    .pg-list-1-left p,
    .pag-p1-phone {
        padding-left: 0px;
        width: 100%;
    }

    .pag-p1-phone ul {
        margin: 0 auto;
        display: table;
    }

    .wed-hom-footer {
        /* padding: 60px 0px 45px 0px; */
    }

    .land-pack-grid-text h4 {
        padding: 15px 12px;
        font-size: 14px;
    }

    .pg-list-1-left .rat i {
        margin: 0px 2px;
    }

    .all-list-sh .pro-detail span:nth-child(3) {
        text-overflow: ellipsis;
        white-space: pre;
        overflow: hidden;
    }

    .carousel-control-prev,
    .carousel-control-next {
        display: none;
    }

    .hom-partners .carousel {
        width: 100%;
    }

    .home-acces ul li {
        width: 50%;
        padding: 10px;
    }

    .ban-tit {
        text-align: center;
    }

    .ban-search {
        width: 100%;
    }

    .hom-abut h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .act .hom-abut a {
        text-align: center;
        display: block;
        margin-bottom: 10px;
    }

    .fot-ban-inn {
        margin: 80px 0px 10px 0px;
        padding-right: 0px;
    }

    .fot-ban-inn .lhs {
        width: 100%;
        /* padding: 40px; */
        text-align: center;
    }

    .login .lhs .tit {
        padding: 0;
    }

    .login .lhs .tit h2 {
        font-size: 20px;
        line-height: 28px;
        margin: 0;
    }

    .login .lhs .tit h2 b {
        font-size: 28px;
        line-height: 30px;
    }

    .login .lhs .tit h2 b br {
        display: none;
    }

    .pg-404 .lhs .tit h2 b {
        font-size: 120px;
        line-height: 80px;
    }

    .pg-404 .lhs .tit a {
        padding: 6px 15px;
    }

    .pg-404 .lhs .tit h5 {
        line-height: 28px;
        font-size: 18px;
    }

    .login .lhs {
        /* display: none; */
        width: 100%;
        text-align: center;
        position: relative;
        padding: 35px 30px 90px;
        border-radius: 10px 10px 0 0;
    }

    .login .lhs .im {
        display: none;
    }

    .login .rhs {
        width: 100%;
        margin-left: 0;
        padding: 50px;
    }

    .pro-detail .pro-bio {
        display: none;
    }

    .links {
        border-top: 1px solid #dadada;
        padding-top: 15px;
    }

    .pg-pro-big-im .s1 img {
        /* height: 175px; */
    }

    .profi-bio .lhs {
        width: 100%;
    }

    .profi-bio .rhs {
        width: 100%;
        padding: 50px 0px 0px 0px;
    }

    .prof-rhs-help {
        text-align: center;
    }

    .pr-bio-conta ul li span {
        /* padding-left: 110px; */
        /* display: block; */
        /* font-size: 14px; */
    }

    .pr-bio-conta ul li span b {
        /* position: absolute; */
        /* left: 0; */
    }

    .ab-sec2 ul {
        /* margin-top: 0; */
        /* padding-top: 80px; */
        width: 100%;
    }

    .hom-partners ul li {
        width: 100%;
        padding: 0px 15px 40px 15px;
    }

    .ab-sec2 ul li {
        padding: 0 15px;
    }

    .hom-coup-test ul li {
        width: auto;
    }

    .ab-sec2 ul li div {
        padding: 25px;
    }

    .ab-sec2 {
        padding-bottom: 100px;
    }

    .ban-home {
        /* padding: 115px 20px 60px 20px; */
    }

    .ban-tit h1 {
        font-size: 48px;
        line-height: 58px;
    }

    .ban-tit p {
        font-size: 16px;
    }

    .ab-team ul li {
        width: 50%;
    }

    .ab-team ul li div {
        width: 95%;
        margin: 12px 5%;
    }

    .ab-faq-rhs {
        margin: 30px 0 0 0
    }

    .foot-inn div h5 {
        font-size: 18px;
    }

    .hom-top:before {
        width: 65px;
        height: 94px;
        background-size: 154px;
        background-position: -33px -43px;
    }

    .hom-top:after {
        width: 49px;
        height: 135px;
        background-size: 50px;
        background-position: -1px 6px;
    }

    .login {
        /* padding: 65px 0px 60px 0px; */
        padding-bottom: 30px;
    }

    .wedd-vid img,
    .wedd-vid iframe {
        height: 400px;
    }

    .wedd-gall-pg-v1 .img-wrapper img {
        height: 200px;
    }

    .ban-inn.pg-cont {
        padding: 75px 20px 90px 20px;
    }

    .ab-sec2 ul li {
        width: 33.333%;
        padding: 15px;
    }

    .abo-partners {
        padding: 100px 0 0 0;
    }

    .blog-soci ul li a b {}

    .blog-main .lhs {
        width: 100%;
    }

    .blog-main .rhs {
        width: 100%;
        padding: 30px 0 0 0;
    }

    .blog-main .page-nation ul {
        margin: 0 auto;
        display: table;
    }

    .blog-main .page-nation ul li {
        display: inline-block;
    }

    .blog-nav {
        padding-bottom: 40px;
        border-bottom: 2px dashed #d3c1ab;
        margin-bottom: 40px;
    }

    .inn-ban h1 {
        font-size: 30px;
        padding: 0 0 10px 0;
        line-height: 40px;
    }

    .wedd-rel-pro {
        padding-bottom: 10px;
        margin-bottom: 25px;
    }

    .ab-wel-lhs {
        margin-bottom: 50px;
    }
    .db-int-pro-2{
        width: 100%;
        padding: 25px 0px 25px 0px;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 769px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:769px) {

    .bl,
    .carousel-indicators,
    .fil-mob-view {
        display: none;
    }

    .home-tit h2 span .num {
        font-size: 60px;
    }

    .ab-cont {
        padding-bottom: 20px;
    }

    .pg-ser .inn ul li {
        width: 50%;
    }

    .ban-home:before {
        width: 320px;
        height: 219px;
        left: calc(50% - 140px);
        top: -140px;
    }

    .hom-nav .ic-logo {
        padding: 2px 0px 4px 0px;
    }

    .fil-mob.fil-mob-act {
        display: block;
    }

    .ban-home {
        /* padding: 90px 20px 40px 20px; */
    }

    .ban-search ul li.sr-sea {
        width: 69%;
        margin: 0px 0px 0px 1%;
    }

    .ban-search ul li.sr-btn {
        width: 100%;
        margin-top: 5px;
    }

    .land-pack ul li {
        width: 50%;
    }

    .land-pack-grid {
        width: 90%;
        margin: 0px 5% 20px 5%;
        box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.14);
    }

    .land-pack-grid-text {
        margin-top: 0px;
    }

    .hom-head {
        padding: 60px 0px 0px 0px;
    }

    .ab-ban {
        padding: 55px 0px 105px 0px;
    }

    .foot-inn {
        text-align: center;
        padding: 50px 10px 50px;
    }

    .foot-inn i.fa {
        position: relative;
        left: initial;
        top: initial;
    }

    .foot-inn div {
        padding: 15px 0 0 0;
    }

    .ban-tit h1 b {
        /* font-size:40px */
    }

    .ban-tit h1 {
        font-size: 38px;
        line-height: 48px;
    }

    .hom-top {
        /* height:50px; */
        /* padding: 3px 0px; */
    }

    .home-city ul li {
        width: 50%;
        margin-bottom: 15px;
        margin-bottom: 15px;
    }

    .hom-eve-lhs-2 {
        width: 100%;
        padding-left: 15px;
        margin-top: 25px;
    }

    .all-pro-box div:nth-child(1) img {
        height: 150px;
    }

    .home-tit {
        padding-top: 70px;
        margin-bottom: 20px;
    }

    .home-tit h2 {
        font-size: 32px;
    }

    .home-acces {
        padding: 15px 0 30px 0;
    }

    .home-about {
        padding: 0px 0px 55px 0px;
    }

    .login-main.add-list {
        width: 90%;
    }

    .bot-book {
        text-align: center;
    }

    .bot-book .bb-img {
        float: initial;
        max-width: 100%;
    }

    .bb-text {
        max-width: 100%;
        margin-top: 30px;
    }

    .hom-partners {
        padding-bottom: 60px;
    }

    .bb-link {
        padding: 20px 0px 0px 0px;
    }

    .foot-supp h2,
    .foot-supp h2 span {
        font-size: 15px;
        line-height: 26px;
        padding-bottom: 0;
    }

    .foot-supp h2 span {
        font-size: 14px;
    }

    .wed-hom-footer h4 {
        padding-bottom: 15px;
        padding-top: 0px;
    }

    .wed-foot-link-1 {
        text-align: center;
        padding-top: 15px;
    }

    .fot-app {
        border-top: 1px solid #e9e9e9;
        padding: 30px 0px;
        margin-top: 15px;
    }

    .fot-app ul,
    .wed-foot-link-1 ul {
        margin: 0 auto;
        display: table;
    }

    .foot-count {
        /* margin-top: 30px; */
        padding: 30px 20px 0;
        width: auto;
    }

    .all-list-sh ul li {
        float: left;
        width: 50%;
        padding: 0px 0 10px 10px;
    }

    .pro-img {
        width: 100%;
    }

    .all-list-sh .all-pro-box {
        margin: 10px;
    }

    .pro-detail {
        padding: 20px 20px 20px 20px;
        width: 100%;
    }

    .hom-event .pro-detail {
        padding: 20px;
    }

    .pro-detail h4 {
        font-size: 17px;
    }

    .all-weddpro .col-md-9 {
        padding: 0px;
        background: none;
    }

    .all-weddpro .col-md-3 {
        padding: 30px 0px 0px 15px;
    }

    .all-weddpro .col-md-3.fil-mob-view {
        margin-top: 45px;
        position: fixed;
        z-index: 1;
        /* background: #fff; */
        left: 0px;
        top: 0px;
        overflow-y: auto;
        height: 100%;
        padding: 70px 50px 50px 50px;
        margin-top: var(--mtop);
        border-radius: 0;
    }

    .all-list-sh {
        background: none;
        /* padding-top: 70px; */
        padding-bottom: 50px;
        width: 100%;
    }
    .fol-set-rhs {
        border-left: 0px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        padding: 25px 0 0 0;
    }
    .fol-sett-sec{    padding: 20px 0px;}
    .lhs-ads {
        margin-bottom: 0px;
        padding-bottom: 35px;
    }

    .lhs-ads ul li:last-child {
        margin-bottom: 0px;
    }

    .list-bann img {
        height: 175px;
    }

    .pag-p1-phone ul li {
        width: 100%;
        margin-bottom: 10px;
    }

    .pag-p1-phone ul li:last-child {
        margin-bottom: 0px;
    }

    .login-reg {
        padding: 120px 0 75px 0;
    }

    .list-bann {
        margin-top: 48px;
    }

    .home-list-pop img {
        margin-bottom: 20px;
    }

    .list-rom-pric {
        right: 25px;
        top: -158px;
        font-size: 16px;
    }

    .list-room-deta p {
        padding-right: 0px;
    }

    .lr-user-wr-con {
        width: 85%;
    }

    .lr-user-wr-con p {
        font-size: 13px;
        line-height: 22px;
    }

    .ban-search ul li {
        width: 50%;
        padding: 5px 5px 5px 5px;
    }


    .hom-abo-im {
        padding: 0px 0px 0px 0px;
    }

    .hom-why {
        padding-left: 0px;
        padding-top: 30px;
    }

    .act .hom-abut {
        padding-top: 30px;
    }

    .how-wrks-inn ul li {
        width: 50%;
    }

    .how-wrks-inn ul li div {
        margin: 0px 10px 40px 10px;
    }

    .count {
        padding: 0px 0px 70px 0px;
    }

    .plans-main ul li {
        width: 100%;
        margin: 0;
    }

    .pri-box {
        width: 90%;
        display: table;
        margin: 0 auto;
        margin-bottom: 30px;
        float: initial;
        max-width: 400px;
    }

    .pri-box-pop {
        margin-top: 0;
    }

    .plans-main {
        margin: -150px 0 0 0;
    }

    .plans-ban {
        padding: 70px 0 200px 0;
    }

    .plans-main {
        padding: 0 0 40px 0;
    }

    .pg-pro-big-im {
        width: 100%;
        /* padding: 40px; */
        text-align: center;
    }

    .pg-pro-big-im .s1 {
        width: 100%;
    }

    .pg-pro-big-im .s1 img {
        height: 350px;
        /* width: 200px; */
        /* margin: 0 auto; */
        /* display: table; */
    }

    .pg-pro-big-im .s2 {
        width: 100%;
        color: #000;
        padding: 25px 0;
    }

    .pg-pro-big-im .s3 {
        /* width: 225px; */
        /* float: initial; */
        /* margin: 0 auto; */
        /* padding: 0; */
        /* position: relative; */
    }

    .pg-pro-big-im .s2 p,
    .pg-pro-big-im .s2 span {
        color: #4e4e4e;
    }

    .profi-ban {
        /* margin-top: 30px; */
    }

    .pr-bio-gal ul li span img {
        height: 145px;
    }

    .all-pro-head {
        text-align: center;
        margin-top: 60px;
        padding: 40px 15px 35px;
    }

    .all-pro-head h1 {
        font-size: 22px;
        line-height: 30px;
    }

    .all-pro-head a {
        font-size: 13px;
        display: table;
        margin: 0 auto;
    }

    .pro-detail .pro-bio {
        border: 0px;
        padding-bottom: 0px;
    }

    .ab-wel-tit-2 ul li {
        width: 100%;
        padding-bottom: 30px
    }

    .ab-wel-tit-2 ul li:last-child {
        padding-bottom: 0;
    }

    .ab-wel {
        padding: 70px 0;
    }

    .ab-wel-lhs {
        display: none;
    }

    .ab-wel-rhs {
        padding-left: 0;
    }



    .ab-team ul li div img {
        height: 200px;
    }

    .wed-foot-link {
        padding-bottom: 30px;
    }

    .wed-foot-link div {
        border: 0;
        padding: 30px;
        border-bottom: 1px solid #b9c6cf;
    }

    .wed-foot-link div:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }

    .home-acces ul li {
        width: 100%;
    }

    .wedd-tline .inn {
        width: 100%;
        padding-top: 20px;
    }

    .ab-cont ul li {
        width: 50%;
    }

    .ab-cont ul li .ab-cont-po,
    .ab-cont ul li:last-child .ab-cont-po {
        border: 1px solid #d7d1be;
        margin: 10px;
        padding: 30px 20px 25px 20px;
    }

    .blog ul li {
        width: 100%;
    }

    .fot-ban-inn .lhs p {
        padding: 20px 0;
    }

    .hom-cus-revi {
        margin-bottom: 80px;
    }

    .pro-pg-intro ul li {
        width: 47%;
        margin: 1.5%;
    }

    .pro-pg-intro ul li div {
        min-width: 100%;
        width: 100%;
    }

    .save-txt {
        text-align: center;
        padding-bottom: 40px;
    }

    .save-txt h4 {
        font-size: 20px;
    }

    .save-txt h2 {
        font-size: 50px;
    }

    .pg-wedd-vid .ban-wedd {
        padding: 20px 0px 0;
    }

    .wedd-vid-dat.wedd-dat {
        padding: 250px 0 0px 0;
    }

    .wedd-vid-foot.foot-box {
        margin: 120px 0 60px 0;
    }

    .tline-inn div h5 {
        font-size: 22px;
    }

    .ban-wedd h2 {
        font-size: 32px;
    }

    .wedd-gall-pg-v1 {
        margin: 60px 0 40px 0;
    }

    .abo-partners {
        padding-bottom: 20px;
    }

    .blog-nav .com h4 {
        font-size: 16px;
    }

    .blog-nav .com span {
        font-size: 12px;
    }

    .enq-sav {
        top: 15px;
        right: 15px;
    }

    .ban-inn h2 {
        font-size: 55px;
        line-height: 55px;
    }

    .home-acces .slick-arrow {
        display: none;
    }

    .short-all {
        padding: 35px 0px 5px 30px;
        text-align: center;
    }

    .home-wedd-gall {
        padding-bottom: 0;
    }

    .filter-clo {
        display: block;
        font-size: 50px;
        top: 35px;
        right: 40px;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 769px and 992px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (min-width:769px) and (max-width:992px) {
    .all-weddpro .col-md-3 {
        padding: 30px 15px;
    }

    .filt-com h4 {
        font-size: 13px;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 650px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:650px) {
    .hom-partners ul li {
        padding: 0px 5px 20px 5px;
        width: 100%;
    }

    .hom-coup-test ul li {
        width: auto;
    }

    .hom-coup-test ul li a {
        /* padding: 0px 10px; */
    }

    .fot-ban-inn .lhs h2 {
        font-size: 26px;
        line-height: 32px;
    }

    .tline-im {
        width: 74px;
        padding: 0;
    }

    .tline-im img {
        width: 74px;
    }

    .tline-con {
        width: calc(100% - 74px);
        padding: 0 0 0 60px;
    }

    .tline-inn-reve .tline-con {
        float: right;
        text-align: left;
        padding: 0 0 0 60px;
    }

    .tline-inn-reve .tline-im {
        float: left;
        padding: 0;
    }

    .tline-inn-reve .tline-im img {}

    .wedd-tline .inn ul:before {
        left: 100px;
    }

    .wedd-tline .inn ul li:before {
        left: 88px;
    }

    .blog-home-box .im img {
        height: 300px;
    }

    .blog-home-box .txt h2 {
        font-size: 18px;
        line-height: 26px;
    }

    .pg-pro-big-im .s3 .cta {
        padding: 10px 10px;
    }

}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 600px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:600px) {
    .ab-sec2 ul li {
        width: 100%;
        padding: 15px;
    }

    .pro-pg-intro ul li {}

    .profi-bio {
        padding: 70px 30px 50px 30px;
    }

    .pro-pg-intro {}

    .pro-pg-intro h1 {
        font-size: 28px;
        padding: 10px 0 0px 0px;
    }

    .seninter-tit {
        font-size: 14px;
        text-align: center;
    }

    .ani-cir {
        display: none;
    }

    .ban-home {
        padding: 90px 20px 130px 20px;
    }

    .ab-sec2 ul {
        /* margin: -120px auto 0px; */
    }

    .foot-supp {
        width: 90%;
    }

    .sub-tit-caps h2 {
        font-size: 26px;
    }

    .inn-ban .breadcrumb li {
        font-size: 13px;
    }

    .pg-soon:before,
    .pg-soon:after {
        display: none;
    }

    .pg-soon .tit h1 {
        font-size: 44px;
        line-height: 60px;
    }

    .pg-soon .tit h4 {
        font-size: 18px;
    }

    .menu-pop-soci {
        padding-top: 10px;
    }

    .pg-soon .timer div {
        font-size: 32px;
        padding: 5px 8px 12px 8px;
    }

    .wedd-frame {
        left: 0;
        width: 100%;
        padding: 0;
        height: 200px;
        margin: 40px 0 0 0;
    }

    .wedd-frame:before,
    .wedd-deco {
        display: none;
    }

    .wedd-frame img {
        width: 100%;
        height: 300px;
        margin: 0;
    }

    .ban-wedd {
        padding: 50px;
    }

    .foot-box {
        margin: 30px 0 0 0;
    }

    .sharepop .modal-body input {
        width: 100%;
        border-radius: 20px;
        margin-bottom: 10px;
    }

    .sharepop .modal-body .shareurltip {
        width: 100%;
    }

    .sharepop .modal-body .shareurltip button {
        border-radius: 20px;
    }

    .ban-inn .ban-tit p {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 576px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:576px) {
    .container {
        max-width: 90%;
    }

    .inte ul li {
        width: 100%;
    }

    .wed-foot-link div {}

    .list-room-deta .list-enqu-btn ul li {
        width: 50%;
    }

    .lr-user-wr-con {
        display: contents;
    }

    .lr-user-wr-img {
        margin: 0px 10px 20px 0px;
    }

    .pri ul li {
        width: 100%;
    }

    .hom-brand-tru {
        padding: 40px 0px;
    }

    .hom-brand-tru .hom-br-in {
        padding: 20px 25px;
        background: #fff;
        width: 100%;
        margin-bottom: 15px;
        border-radius: 2px;
    }

    .hom-brand-tru ul li {
        width: 100%;
    }

    .hom-brand-tru ul li:last-child .hom-br-in {
        margin-bottom: 0px;
    }

    .how-wrks-inn ul li div h4 {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .how-wrks-inn ul li div {
        padding: 30px 10px;
    }

    .how-wrks-inn ul li div img {
        width: 55px;
        margin-top: 25px;
        padding-bottom: 10px;
    }

    .login .rhs .inn {
        width: 100%;
    }

    .plans-ban h1 {
        font-size: 32px;
        line-height: 38px;
    }

    .plans-ban {
        padding: 70px 0 200px 0;
    }

    .plans-ban p,
    .plans-ban span.pri {
        font-size: 13px;
    }

    .pr-bio-gal ul li span img {
        height: 95px;
    }

    .pr-bio-gal ul li {
        padding: 0 5px 5px 0;
    }

    .pg-pro-big-im .s2 h1 {
        font-size: 24px;
    }

    .profi-bio .lhs {
        padding: 0;
    }

    .pr-bio-info ul li b {
        width: 125px;
    }

    .seninter .lhs {
        width: 100%;
    }

    .seninter .rhs {
        width: 100%;
        padding: 25px 0 0 0;
    }

    .all-list-sh ul li {
        width: 100%;
        padding: 0px 0 20px 0;
    }

    .all-pro-head {
        background-position: center;
    }

    .all-pro-box div:nth-child(1) img {
        height: 225px;
    }

    .ab-team ul li div:hover .social-light li,
    .wedd-gall {
        margin: 0
    }

    .pg-pro-big-im .s1 img {
        height: 300px;
    }

    .wedd-gall-pg {
        margin: 80px 0 80px 0;
    }

    #nextButton,
    #prevButton {
        width: 40px;
        height: 40px;
    }

    #nextButton i,
    #prevButton i {
        font-size: 26px;
        line-height: 10px;
        margin: 13px 2px;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 550px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:550px) {
    .ab-wel-tit h2 {
        font-size: 34px;
        line-height: 40px;
    }

    .ab-wel-tit h2 em {
        line-height: 32px;
        font-size: 26px;
        padding-top: 10px;
    }

    .home-acces ul li div.hacc .con img {
        width: 90px
    }

    .home-acces-main .slick-dots {
        padding-top: 35px;
    }

    .home-acces ul li div.home-acces-main:before,
    .home-acces ul li div.hacc::after,
    .home-acces ul li div.hacc::after {
        transform: scale(1);
        opacity: 0.5;
    }

    .home-acces ul li div.hacc .con {
        max-height: initial;
        padding: 60px 20px 40px;
    }

    .home-acces ul li div.hacc {
        max-height: initial;
    }

    .home-acces ul li div.hacc .con a {
        opacity: 1;
        padding: 8px 25px;
        text-transform: uppercase;
    }

    .hom-coup-box::before {
        transform: scale(1);
    }

    .hom-coup-box .bx {
        transform: translateY(0px);
        opacity: 1;
    }

    .hom-coup-box img {
        transform: scale(1.2);
    }

    .save-im .inn {
        width: 100%;
        margin-bottom: 40px;
    }

    .save-im .inn:last-child {
        margin-bottom: 0;
    }

    .home-acces-main:after,
    .home-acces-main:before {
        width: 10%
    }

    .save-im .desc span {
        font-size: 26px;
    }

    .save-im .desc h4 {
        font-size: 28px;
        padding-top: 10px;
    }

    .home-tit h2 {
        font-size: 36px;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 526px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:526px) {
    .home-acces ul li div.hacc .con img {
        border: 3px solid #000;
        width: 85px;
        padding: 10px;
        margin-bottom: 15px;
    }

    .home-acces ul li div.hacc .con {
        padding: 70px 10px 50px 10px;
    }

    .home-acces ul li div.hacc .con h4 {
        font-size: 24px;
        text-overflow: ellipsis;
        white-space: pre;
        overflow: hidden;
    }

    .home-acces ul li div.hacc .con p {
        font-size: 16px;
        text-overflow: ellipsis;
        white-space: pre;
        overflow: hidden;
    }

    .ab-sec2 ul {
        /* padding-top: 50px; */
    }

    .ab-sec2 {
        padding-bottom: 75px;
    }

    .ab-team ul li div .social-light li a i {
        width: 30px;
        height: 30px;
        font-size: 16px
    }

    .pro-gal-imag {
        padding: 0px 10px 20px 10px;
    }

    .img-wrapper img {
        height: 125px;
    }

    .form-tit h1 {
        font-size: 22px;
        line-height: 30px;
    }

    .all-list-sh.view-grid ul li {
        width: 100%;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 480px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:480px) {
    .land-pack ul li {
        width: 100%;
    }

    .wedd-info ul li a,
    .wedd-info ul li span {
        font-size: 16px;
    }

    .wedd-info ul li {
        margin: 0 10px;
    }

    .hom-eve-lhs-1 {
        width: 100%;
        margin-bottom: 20px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .hom-eve-lhs-2 {
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .ban-search ul li {
        width: 100%;
        padding-bottom: 10px;
    }

    .ban-search ul li.sr-sea {
        width: 100%;
        margin: 10px 0px 0px 0px;
    }

    .ban-search {
        /* width:90%; */
    }

    .ban-tit h1 b {
        font-size: 42px;
    }

    .home-tit h2 {
        font-size: 28px;
    }

    .wed-foot-link div:nth-child(2) {
        border-right: 0px solid #dadada;
    }

    .all-list-sh ul li {
        width: 100%;
    }

    .list-bann img {
        height: 150px;
    }

    .pg-list-1-left h3 {
        font-size: 20px;
        padding: 12px 0px;
    }

    .pg-list-1-left img {
        width: 125px;
        height: 125px;
        margin-top: -79px;
    }

    .fot-app ul li a img {
        width: 125px;
        height: 50px;
    }

    .hom-eve-com {
        float: initial;
    }

    .home-list-pop-desc h3 {
        font-size: 16px;
    }

    .list-pg-write-rev form .row {
        margin: 0px;
    }

    .list-pg-write-rev form .col {
        display: contents;
    }

    .list-pg-write-rev form .rating {
        margin: 0px 0px 10px 0px;
    }

    .log-bot ul li span {
        font-size: 12px;
    }

    .plans-ban h1 {
        font-size: 26px;
        line-height: 30px;
    }

    .pr-bio-soc ul li {
        padding: 0 2px 7px 0;
    }

    .pr-bio-soc ul li a {
        font-size: 12px;
        padding: 7px 20px;
    }

    .pr-bio-c h3 {
        font-size: 20px;
    }

    .fot-ban-inn {
        margin: 60px 0px 30px 0px;
    }

    .hom-coup-test ul li a {
        font-size: 11px;
    }

    .tline-inn div h5 {
        font-size: 24px;
    }

    .ab-team ul li {
        width: 100%;
    }

    .ab-team ul li div {
        margin: 0 0 20px 0;
        width: 100%;
    }

    .ab-team ul li:last-child div {
        margin-bottom: 0;
    }

    .fot-ban-inn .lhs {
        padding: 50px 30px;
    }

    .mob-me-ic i,
    .mob-me-ic img,
    .mob-me-clo img,
    .mobile-ser img {
        width: 30px;
        height: 30px;
        padding: 4px;
        margin-left: 2px;
    }

    .mob-me-ic {
        top: 7px;
    }

    .pro-gal-imag {
        width: 50%;
    }

    .foot-box .inn ul li {
        width: 100%;
    }

    .wedd-vid img,
    .wedd-vid iframe {
        height: 300px;
    }

    .wedd-vid-tre-2 {
        display: none;
    }

    .inn-ban h1 {
        font-size: 32px;
        padding: 0 0 5px;
    }

    .ab-faq-lhs .card-header a {
        font-size: 16px;
    }

    .ab-faq-lhs .card-header a::after {
        top: 28px;
    }

    .inn-ban .breadcrumb {
        display: none;
    }

    .inn-ban h1 {
        font-size: 25px;
        padding: 0;
    }

    .pg-soon .tit h4 {
        font-size: 16px;
    }

    .pg-soon .tit h1 {
        font-size: 38px;
        line-height: 50px;
    }

    .pg-soon .inn {
        padding-top: 15px;
    }

    .ban-inn h2 {
        font-size: 36px;
        line-height: 45px;
    }

    .foot-inn div span {
        font-size: 20px;
    }

    .foot-inn div h5 {
        font-size: 28px;
    }
}


/*-------------------------------------------------------*/
/* When the browser is between 0px and 400px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:400px) {

    .ab-cont ul li .ab-cont-po,
    .ab-cont ul li:last-child .ab-cont-po {
        margin: 3px;
        padding: 30px 10px 25px 10px;
    }

    .pg-ser .inn ul li {
        width: 100%;
    }

    .pg-ser .gal-im img.gal-siz-2 {
        height: 60vh;
    }

    .save-txt h2 {
        font-size: 30px;
    }

    .save-im img {
        height: 250px;
        border-radius: 30px;
    }

    .wedd-info ul li a,
    .wedd-info ul li span {
        font-size: 15px;
    }

    .wedd-info ul li {
        margin: 0 5px;
    }

    .wedd-info ul li i {
        padding: 6px;
        width: 32px;
        height: 32px;
        font-size: 16px;
    }

    .pg-soon .timer div {
        margin: 0 1px;
    }

    .pg-soon .timer div i {
        font-size: 12px;
    }

    .hom-nav .ic-logo {
        width: 200px;
    }
}

/*-------------------------------------------------------*/
/* When the browser is between 0px and 350px wide,below css will be applied.
/*-------------------------------------------------------*/
@media screen and (max-width:350px) {
    .pr-bio-info ul li {
        font-size: 12px;
    }

    .wedd-info ul li {
        margin: 0 2px;
    }

    .wedd-info ul li i {
        margin-right: 7px;
    }
}
.chatbox {
    transition: all 0.5s ease-in-out 0s;
    background: rgb(255, 255, 255);
    padding: 15px;
    box-shadow: rgba(115, 115, 115, 0.37) 0px 1px 11px -2px;
    position: fixed;
    bottom: 10px;
    right: -500px;
    border-radius: 10px;
    width: 90%;
    max-width: 380px;
    height: 80%;
    max-height: 450px;
    z-index: 99;
    overflow: hidden;
}

.chatbox.open {
    right: 20px;
}

.chatbox.msg-sho-act {
    right: 5px;
}

.chatbox .s1,
.chatbox .s2,
.chatbox .s3 {
    float: left;
    width: 100%;
}

.chatbox .s1 {
    padding-bottom: 15px;
}

.chatbox .s1 img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: cover;
    float: left;
}

.chatbox .s1 h4 {
    font-size: 14px;
    float: left;
    padding: 1px 0px 0px 12px;
    color: rgb(150, 150, 150);
    margin: 0px;
}

.chatbox .s1 h4 b {
    display: block;
    color: rgb(0, 0, 0);
    font-size: 16px;
    padding-bottom: 4px;
    font-weight: 500;
}

.chatbox .s1 .avlsta {
    position: absolute;
    left: 70px;
    top: 37px;
    font-size: 9px;
    background: rgb(143, 163, 181);
    border-radius: 3px;
    color: rgb(255, 255, 255);
    padding: 1px 3px;
    font-weight: 500;
}

.chatbox .s1 .avilyes {
    background: rgb(76, 175, 80);
}

.chatbox .s1 .avilno {
    background: rgb(143, 163, 181);
}

.chatbox .s2 {
    background: rgb(243, 246, 250);
    padding: 32px 15px 140px;
    position: absolute;
    left: 0px;
    top: 70px;
    height: 78%;
    bottom: 0px;
    overflow-y: auto;
    border-top: 1px solid rgb(234, 236, 240);
}

.chatbox .s2 .chat-con {
    float: left;
    width: 100%;
    display: block;
    margin-bottom: 12px;
}

.chatbox .s2 .chat-lhs,
.chatbox .s2 .chat-rhs {
    font-size: 13px;
    font-weight: 400;
    background: rgb(255, 255, 255);
    padding: 6px 10px;
    border-radius: 5px;
    line-height: 19px;
    box-shadow: rgba(25, 27, 56, 0.12) 0px 1px 4px -1px;
    position: relative;
}

.chatbox .s2 .chat-lhs::before,
.chatbox .s2 .chat-rhs::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(25, 27, 56, 0.12) 0px 1px 2px -1px;
    top: 7px;
    transform: rotateZ(45deg);
}

.chatbox .s2 .chat-lhs::before {
    left: -2px;
}

.chatbox .s2 .chat-rhs::before {
    right: -2px;
    background: rgb(23, 149, 249);
}

.chatbox .s2 .chat-lhs {
    float: left;
}

.chatbox .s2 .chat-rhs {
    float: right;
    background: rgb(23, 149, 249);
    color: rgb(255, 255, 255);
}

.chatbox .s2 .chat-wel {
    background: rgb(220, 231, 255);
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    font-size: 11px;
    padding: 2px;
    color: rgb(51, 51, 51);
    font-weight: 500;
}

.chatbox .s3 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: rgb(217, 223, 231);
    padding: 15px;
}

.chatbox .s3 input {
    resize: none;
    height: 52px;
    padding: 10px;
    font-size: 13px;
    line-height: 20px;
    color: rgb(51, 51, 51);
    border-radius: 5px 0px 0px 5px;
    border: 0px;
    width: 270px;
    float: left;
    font-weight: 500;
}

.chatbox .s3 button {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 600;
    padding: 6px 14px;
    border-radius: 0px 5px 5px 0px;
    text-transform: uppercase;
    background: rgb(33, 150, 243);
    border: 0px;
    width: 80px;
    float: left;
    height: 44px;
}

.chatbox .s3 button i {
    font-size: 13px;
    font-weight: 500;
    vertical-align: bottom;
    line-height: 18px;
}

.chatbox .s3 input {
    height: 45px;
    position: relative;
    padding: 15px 24px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid rgb(232, 232, 232);
    text-indent: 0px;
    line-height: 12px;
    transition: border-color 0.4s ease 0s, color 0.4s ease 0s;
    width: calc(100% - 80px);
    font-size: 14px;
    background: rgb(255, 255, 255);
    float: left;
    font-weight: 500;
}

.comm-msg-pop-clo {
    position: absolute;
    right: 10px;
    top: 10px;
}

.comm-msg-pop-clo i {
    color: rgb(155, 158, 163);
    font-size: 20px;
    cursor: pointer;
}


/*-------------------------------------------------------*/
/* COMMON TITLE
/*-------------------------------------------------------*/
.home-tit-1 {
    width: 100%;
}

.home-tit-1 h2 {
    color: rgb(255, 243, 224);
    font-size: 75px;
    font-weight: 600;
    margin-bottom: 40px;
}

.home-tit-1 h2 span {
    display: block;
    font-size: 60px;
    color: #ffbe5c;
}

.home-tit-1 a {
    border: 1px solid rgb(243, 200, 128);
    color: rgb(243, 200, 128);
    display: inline-block;
    font-size: 24px;
    padding: 15px 50px;
    font-weight: 500;
    font-family: var(--tit-font);
}

.hom-couples-all {
    float: left;
    width: 100%;
    position: relative;
    padding-top: 30px;
}

.hom-coup-test {}

.hom-coup-test ul {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.hom-coup-test ul li {
    float: left;
    width: 25%;
}

.hom-coup-box {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.hom-coup-box::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.48);
    inset: 0px;
    transition: all 0.4s ease-in-out 0s;
    transform: scale(0);
    z-index: 1;
}

.hom-coup-box:hover::before {
    transform: scale(1);
}

.hom-coup-box:hover .bx {
    transform: translateY(0px);
    opacity: 1;
}

.hom-coup-box img {
    float: left;
    width: 100%;
    height: 600px;
    object-fit: cover;
    transition: all 0.4s ease-in-out 0s;
    z-index: 0;
}

.hom-coup-box:hover img {
    transform: scale(1.2);
}

.hom-coup-box .bx {
    position: absolute;
    top: 42%;
    transition: all 0.4s ease-in-out 0s;
    transform: translateY(30px);
    opacity: 0;
    z-index: 1;
    width: 100%;
    text-align: center;
}

.hom-coup-box h4 {
    color: rgb(255, 255, 255);
    text-align: center;
    width: 100%;
    font-size: 34px;
    font-family: var(--tit-font);
    margin-bottom: 35px;
}

.hom-coup-box h4 span {
    font-family: var(--tit-font);
    font-size: 15px;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-top: 10px;
    color: #fff;
}

.hom-coup-box .leaf {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 42%;
    transform: translateY(15px) scale(0);
    transition: all 0.4s ease-in-out 0s;
    opacity: 0.6;
    z-index: 2;
}

.hom-coup-box:hover .leaf {
    transform: translateY(0px) scale(1);
}

.hom-coup-box .leaf::before,
.hom-coup-box .leaf::after {
    content: "";
    position: absolute;
    width: 150px;
    height: 184px;
    background: url("../images/leaf/4.png") 0% 0% / 100% no-repeat;
    top: -71px;
    transition: all 0.4s ease-in-out 0s;
}

.hom-coup-box .leaf::after {
    margin-right: -6px;
    transform: scaleX(-1);
}

.hom-coup-box .leaf::before {
    margin-left: -161px;
}

.hom-coup-box .sml-cta {
    transition: all 0.4s ease-in-out 0s;
    background: #b57f3e;
}

.hom-coup-box:hover .sml-cta {}

.hom-cus-revi {
    padding-top: 50px;
    float: left;
    width: 100%;
    margin-bottom: 120px;
    position: relative;
}
/* Add this CSS to make the nav bar fixed */
.nav-bar-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* Ensure it stays on top of other elements */
  }
  
  /* Add top padding to the main content to prevent overlap */
  .main-content {
    padding-top: 80px; /* Adjust based on the height of your nav bar */
  }
  .pr-bio-gallery {
    margin-top: 20px;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
  }
  
  .gallery-item {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .gallery-item:hover {
    transform: scale(1.05);
  }
  
  .gallery-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  .pr-bio-gallery {
    margin-top: 20px;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
  }
  
  .gallery-item {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .gallery-item:hover {
    transform: scale(1.05);
  }
  
  .gallery-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  .nav-actions {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .contact-info {
    color: white;
    margin: 0;
  }
  
  .contact-info strong {
    color: #FFF; /* Yellow color for "Contact Us:" */
  }
  
  .login-button {
    background-color: #ff9d00; /* Orange color as shown in the image */
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  .hom-top { /* Dark red color as shown in the image */
    padding: 10px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .hom-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo-brand {
    cursor: pointer;
  }

  .leaf-soon {
    content: '';
    position: fixed;
    bottom: 0;
    right: 0;
    width: 123px;
    height: 300px;
    background: url('../images/leaf/7.png') no-repeat;
    background-size: 121px;
}
  

  .ic-logo {
    height: 40px; /* Adjust as needed */
  }
  @media (max-width: 768px) {
    .contact-info {
      display: none;
    }
    .leaf-soon {
    display: none;
}
  }
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }

  
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
  
    .footer-content p {
      margin: 5px 0;
      text-align: center;
    }
  }
  