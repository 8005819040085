@media (min-width: 768px) {
    .fil-mob-view {
      display: block;
    }
    .container .row {
      display: flex;
    }
    .col-md-3 {
      width: 25%;
    }
    .col-md-9 {
      width: 75%;
    }
    .filt-com {
        padding-top: 40px;
      margin-bottom: 20px;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 5px;
    }
    .short-all {
      margin-bottom: 20px;
    }
  }
  .filters {
  position: sticky;
  top: 20px;
}

.filter-item {
  margin-bottom: 15px;
}

.filter-item label {
  display: block;
  margin-bottom: 5px;
}

.filter-item select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.profiles-container {
  height: calc(100vh - 100px); /* Adjust based on your layout */
  overflow-y: auto;
}

.profiles-count {
  margin-bottom: 15px;
}

.profiles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.profile-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.profile-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.profile-image img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
}

.profile-info {
  padding: 10px;
}

.profile-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.profile-details {
  font-size: 0.8rem;
  color: #666;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.profile-details span {
  background-color: #f0f0f0;
  padding: 2px 5px;
  border-radius: 3px;
}

.send-request-btn {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-request-btn:hover {
  background-color: #45a049;
}

.send-request-btn.sent {
  background-color: #999;
}

.profile-plan {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ffd700;
  color: #000;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.8rem;
  font-weight: bold;
}